import { useEffect } from 'react';
import styles from './styled.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveBuffs,
  setDamageDealt,
  setNodes,
  setPassiveIncome,
} from '../../store/slices/game.slice';
import { activeBuffsData } from '../../site-data/activeBuffs';
import { nodesData, passiveIncomeData } from '../../site-data/nodes';
import BigNumber from 'bignumber.js';
import Tooltip from '../common/Tooltip';
import TooltipContent from './ToolTipContent';
import { usePlaySFX } from '../../hooks';

export default function ActiveBuffs() {
  const dispatch = useDispatch();
  const playSFX = usePlaySFX();

  const {
    damageDealt,
    numberOfClicks,
    nodes,
    passiveIncome,
    activeBuffs,
    birthdayAvailCount,
  } = useSelector((state) => state.game);

  const activeBuffsSetter = (buffs) => {
    dispatch(setActiveBuffs(buffs));
  };

  const damageDealtSetter = (clicks) => {
    dispatch(setDamageDealt(clicks));
  };

  const nodesSetter = (nodes) => {
    dispatch(setNodes(nodes));
  };

  const passiveIncomeSetter = (nodes) => {
    dispatch(setPassiveIncome(nodes));
  };

  const nodeAndPassiveIncomeUpdater = (effect) => {
    let nodesToSet = [...nodes];
    let passiveIncomeToSet = [...passiveIncome];
    let changeInNodes = false;
    let changeInPassiveIncomes = false;

    if (Array.isArray(effect.applyOn)) {
      for (let id of effect.applyOn) {
        let index = passiveIncomeToSet.findIndex(
          (item) => item.id === id
        );

        if (typeof index === 'number') {
          passiveIncomeToSet[index] = {
            ...passiveIncomeToSet[index],
            ...(effect.increaseInValueBy
              ? {
                  dps: new BigNumber(passiveIncomeToSet[index].dps)
                    .times(effect.increaseInValueBy)
                    .toString(),
                }
              : {
                  basePrice: new BigNumber(
                    passiveIncomeToSet[index].basePrice
                  )
                    .times(effect.discountOnPriceOf)
                    .toString(),
                }),
          };
          changeInPassiveIncomes = true;
        } else {
          index = nodesToSet.findIndex((item) => item.id === id);
          nodesToSet[index] = {
            ...nodesToSet[index],
            ...(effect.increaseInValueBy
              ? {
                  dps: new BigNumber(nodesToSet[index].dps)
                    .times(effect.increaseInValueBy)
                    .toString(),
                }
              : {
                  basePrice: new BigNumber(
                    nodesToSet[index].basePrice
                  )
                    .times(effect.discountOnPriceOf)
                    .toString(),
                }),
          };
          changeInNodes = true;
        }
      }
    } else {
      nodesToSet = nodesToSet.map((item) => ({
        ...item,
        ...(effect.increaseInValueBy
          ? {
              dps: new BigNumber(item.dps)
                .times(effect.increaseInValueBy)
                .toString(),
            }
          : {
              basePrice: new BigNumber(item.basePrice)
                .times(effect.discountOnPriceOf)
                .toString(),
            }),
      }));
      passiveIncomeToSet = passiveIncomeToSet.map((item) => ({
        ...item,
        ...(effect.increaseInValueBy
          ? {
              dps: new BigNumber(item.dps)
                .times(effect.increaseInValueBy)
                .toString(),
            }
          : {
              basePrice: new BigNumber(item.basePrice)
                .times(effect.discountOnPriceOf)
                .toString(),
            }),
      }));
      changeInNodes = true;
      changeInPassiveIncomes = true;
    }

    if (changeInNodes) nodesSetter(nodesToSet);
    if (changeInPassiveIncomes)
      passiveIncomeSetter(passiveIncomeToSet);
  };

  const buffClickHandler = (buffId, price, effect) => {
    const bigDamageDealt = new BigNumber(damageDealt || '0');
    const bigPrice = new BigNumber(price);
    if (
      !(activeBuffs && buffId in activeBuffs) &&
      bigDamageDealt.isGreaterThanOrEqualTo(bigPrice)
    ) {
      const valToUpdate = bigDamageDealt.minus(bigPrice);
      damageDealtSetter(valToUpdate);
      localStorage.setItem('GameDamageDealt', valToUpdate);
      localStorage.setItem('GameNumberOfClicks', numberOfClicks);
      const dataToSet = {
        ...(activeBuffs ? activeBuffs : {}),
        [buffId]: true,
      };
      localStorage.setItem('GameActiveBuffs', dataToSet);
      activeBuffsSetter(dataToSet);
      nodeAndPassiveIncomeUpdater(effect);
      playSFX('/assets/SFX/P2W_SFX_Buff_01.wav');
    }
  };

  useEffect(() => {
    const activeBuffsValue = localStorage.getItem('GameActiveBuffs');
    if (activeBuffsValue) {
      const buffsValue = JSON.parse(activeBuffsValue);
      activeBuffsSetter(buffsValue);
    }
  }, [birthdayAvailCount]);

  return (
    <div className={styles.activeBuffs}>
      {/* {birthdayAvailCount && (
        <div className={styles.birthdayAvailedWrapper}>
          <img src={`assets/cake_1.png`} alt="cake" />
          <sup className={styles.sup}>{birthdayAvailCount}</sup>
        </div>
      )} */}
      <div className={styles.activeBuffsWrapper}>
        {activeBuffsData.map(({ id, price, type, effect }, i) => {
          // const disabled =
          //   (activeBuffs && id in activeBuffs) || damageDealt < price;
          const disabled =
            !(activeBuffs && id in activeBuffs) &&
            new BigNumber(damageDealt || '0').isLessThan(price);

          return (
            <Tooltip
              key={id}
              content={
                !disabled && (
                  <TooltipContent data={{ type, price, effect }} />
                )
              }
            >
              <button
                className={`${styles.buff} ${
                  activeBuffs && id in activeBuffs
                    ? styles.active
                    : ''
                }`}
                onClick={() => buffClickHandler(id, price, effect)}
                disabled={disabled}
              >
                <img
                  className={styles.img}
                  alt={id}
                  src={`assets/Buff/${i + 1}.png`}
                />
                {/* <h1>Owned</h1> */}
                {/* {type} */}
              </button>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
}
