import React, { useEffect } from "react";
import Modal from "../common/Modal";
import styles from "./styled.module.css";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "../common/DatePicker";
import { setBirthday } from "../../store/slices/game.slice";
import RollerSelect from "../common/RollerSelect";

export const BirthdayMessageModal = ({ message, isModalOpen, closeModal }) => {
  const initialize = () => {};

  useEffect(() => {
    if (isModalOpen) {
      initialize();
    }
  }, [isModalOpen]);

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      <div className={styles.birthdayMessageContainer}>
        <h1 className={styles.message}>{message}</h1>
      </div>
    </Modal>
  );
};
