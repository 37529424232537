import styles from "./styled.module.css";
import DPS from "../../components/DPS";
import ActiveBuffs from "../../components/ActiveBuffs";
import Nodes from "../../components/Nodes";

export default function Right() {
  return (
    <div className={styles.right}>
      <DPS />
      <ActiveBuffs />
      <Nodes />
    </div>
  );
}
