// src/Tooltip.js
import React from "react";
import styles from "./styled.module.css";
import { formatCompactNumber, formatCompactNumberNew } from "../../functions";
import { useTranslation } from "../../hooks";
import BigNumber from "bignumber.js";

const TooltipContent = ({ data }) => {
  const { t } = useTranslation();

  const { basePrice, dps, baseDps } = data;

  return (
    <div className={styles.tooltipContent}>
      <p>
        {t("Current Yield")}:{" "}
        {formatCompactNumberNew(new BigNumber(dps).minus(baseDps))}
      </p>
      <p>
        {t("Cost")}: {formatCompactNumberNew(new BigNumber(basePrice))}
      </p>
      <p>
        {t("New Yield")}: {formatCompactNumberNew(new BigNumber(dps))}
      </p>
    </div>
  );
};

export default TooltipContent;
