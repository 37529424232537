import React, { useState } from 'react';
import styles from './style.module.css';

const ToggleSwitch = ({ onChange, isChecked }) => {
  return (
    <div className={styles.mainContainer}>
      <p>Auto run</p>
      <label className={styles.switch}>
        <span
          className={`${styles.slider} ${
            isChecked ? styles.checked : ''
          }`}
        >
          <div
            className={`${styles.sliderHandle} ${
              isChecked ? styles.checkedHandle : ''
            }`}
          ></div>
          <svg
            className={`${styles.sliderIcon} ${
              isChecked ? styles.iconVisible : ''
            }`}
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="presentation"
          >
            <path fill="none" d="m4 16.5 8 8 16-16"></path>
          </svg>
        </span>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={onChange}
          className={styles.input}
        />
      </label>
    </div>
  );
};

export default ToggleSwitch;
