import { click } from '@testing-library/user-event/dist/click';
import styles from './styled.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { ShopModal } from '../ShopModal';
import {
  formatCompactNumber,
  formatCompactNumberNew,
} from '../../functions';
import { LanguageModal } from '../LanguageModal';
import { usePlaySFX, useTranslation } from '../../hooks';
import {
  setDPS,
  setLanguage,
  setMuted,
} from '../../store/slices/game.slice';
import BigNumber from 'bignumber.js';
import { setAudio } from '../../store/slices/config.slice';

export default function DPS() {
  const { damageDealt, bonusLevels, nodes, passiveIncome } =
    useSelector((state) => state.game);
  const playSFX = usePlaySFX();
  const [shopModalOpen, setShopModalOpen] = useState(false);
  const [languageModalOpen, setLanguageModalOpen] = useState(false);
  const { audio } = useSelector((state) => state.config);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const shopModalHandler = () => {
    setShopModalOpen(!shopModalOpen);
    if (!shopModalOpen)
      playSFX('/assets/SFX/P2W_SFX_MenuSelection_01.wav');
  };

  const languageModalHandler = () => {
    if (!languageModalOpen)
      playSFX('/assets/SFX/P2W_SFX_MenuSelection_01.wav');
    setLanguageModalOpen(!languageModalOpen);
  };

  const audioHandler = () => {
    playSFX('/assets/SFX/P2W_SFX_MenuSelection_01.wav');
    dispatch(setAudio());
  };

  const dpsValue = useMemo(() => {
    const dpsFromLocalStorage = localStorage.getItem('GameDPS');

    let dpsSum = new BigNumber(dpsFromLocalStorage || 0);
    if (bonusLevels && nodes && passiveIncome) {
      for (let id in bonusLevels) {
        let value = nodes.find((item) => item.id === id);
        if (!value)
          value = passiveIncome.find((item) => item.id === id);
        const bigDps = new BigNumber(value.dps);
        dpsSum = dpsSum.plus(bigDps.minus(value.baseDps));
      }
    }

    localStorage.setItem('GameDPS', dpsSum);
    dispatch(setDPS(dpsSum));
    return dpsSum;
  }, [bonusLevels]);

  return (
    <div className={styles.dpsWrapper}>
      {damageDealt && nodes && passiveIncome ? (
        <>
          <h1 className={styles.damageDealt}>
            {t('Damage Dealt')}{' '}
            <span>
              {formatCompactNumberNew(new BigNumber(damageDealt))}
            </span>
          </h1>
          <h1 className={styles.dps}>
            DPS {formatCompactNumberNew(dpsValue)}
          </h1>
        </>
      ) : (
        <h1>Loading...</h1>
      )}
      <div className={styles.iconWrapper}>
        {/* <button onClick={languageModalHandler}>
          <div className={styles.options}></div>
        </button> */}
        <button onClick={audioHandler}>
          <div
            className={audio ? styles.audio : styles.audioMuted}
          ></div>
        </button>
        <button onClick={languageModalHandler}>
          <div className={styles.language}></div>
        </button>
        <button onClick={shopModalHandler}>
          <div className={styles.shop}></div>
        </button>
      </div>
      <ShopModal
        isModalOpen={shopModalOpen}
        closeModal={shopModalHandler}
      />
      <LanguageModal
        isModalOpen={languageModalOpen}
        closeModal={languageModalHandler}
      />
    </div>
  );
}
