import React, { useState } from "react";
import styles from "./styled.module.css";
import RollerSelect from "../RollerSelect";

export const DatePicker = ({ onConfirm }) => {
  const [selectedMonth, setSelectedMonth] = useState("JANUARY");
  const [tens, setTens] = useState("0");
  const [ones, setOnes] = useState("1");
  const [animateOnes, setAnimateOnes] = useState(false);
  const [animateTens, setAnimateTens] = useState(false);
  const [changeOnesTo, setchangeOnesTo] = useState(null);
  const [changeTensTo, setchangeTensTo] = useState(null);

  const getDaysInMonth = (month) => {
    const monthDays = {
      JANUARY: 31,
      FEBRUARY: 28,
      MARCH: 31,
      APRIL: 30,
      MAY: 31,
      JUNE: 30,
      JULY: 31,
      AUGUST: 31,
      SEPTEMBER: 30,
      OCTOBER: 31,
      NOVEMBER: 30,
      DECEMBER: 31,
    };
    return monthDays[month];
  };

  const handleMonthChange = (newMonth) => {
    setSelectedMonth(newMonth);
    const daysInMonth = getDaysInMonth(newMonth);
    const currentDay = parseInt(tens) * 10 + parseInt(ones);

    if (newMonth === "FEBRUARY" && parseInt(ones) > 8) {
      setAnimateOnes(true);
      setchangeOnesTo("8");
      // setTens(newTens);
    } else if (newMonth === "FEBRUARY" && parseInt(tens) > 2) {
      setAnimateTens(true);
      setchangeTensTo("2");
    }

    if (currentDay > daysInMonth) {
      setTens(Math.floor(daysInMonth / 10).toString());
      setAnimateOnes(true);
      setchangeOnesTo((daysInMonth % 10).toString());
    }
  };

  const handleTensChange = (newTens) => {

    const daysInMonth = getDaysInMonth(selectedMonth);
    const currentDay = parseInt(newTens) * 10 + parseInt(ones);

    if (selectedMonth === "FEBRUARY" && parseInt(ones) > 8) {
      setAnimateOnes(true);
      setchangeOnesTo("8");
      setTens(newTens);
    } else if (newTens === "3" && parseInt(ones) > 1) {
      // when ones go above 1 and user selects tens to be 3
      setAnimateOnes(true);
      setchangeOnesTo((daysInMonth % 10).toString());
      setTens(newTens);
      // setOnes(getDaysInMonth(selectedMonth) > 30 ? "1" : "0");
    } else {
      setTens(newTens);
      if (currentDay > daysInMonth) {
        setOnes(Math.min(daysInMonth % 10, parseInt(ones)).toString());
      }
    }
    setAnimateTens(false);
  };

  const handleOnesChange = (newOnes) => {
    const daysInMonth = getDaysInMonth(selectedMonth);
    const currentDay = parseInt(tens) * 10 + parseInt(newOnes);

    if (currentDay > daysInMonth) {
      setOnes((daysInMonth % 10).toString());
    } else {
      setOnes(newOnes);
    }
    setAnimateOnes(false);
  };

  const confirmBtnClickHandler = () => {
    const day = tens + ones;
    onConfirm({ month: selectedMonth, day });
  };

  const daysInMonth = getDaysInMonth(selectedMonth);

  const tensOptions = Array.from(
    { length: Math.floor(daysInMonth / 10) + 1 },
    (_, i) => i.toString()
  );

  const onesOptions = Array.from(
    {
      length:
        selectedMonth !== "FEBRUARY"
          ? parseInt(tens) === 3
            ? Math.min(10, daysInMonth - 30 + 1)
            : 10
          : Math.min(9, (daysInMonth % 10) + 1),
    },
    (_, i) => i.toString()
  );

  return (
    <div className={styles.datePickerContainer}>
      <div className={styles.datePicker}>
        <RollerSelect
          options={[
            "JANUARY",
            "FEBRUARY",
            "MARCH",
            "APRIL",
            "MAY",
            "JUNE",
            "JULY",
            "AUGUST",
            "SEPTEMBER",
            "OCTOBER",
            "NOVEMBER",
            "DECEMBER",
          ]}
          fontSize={62}
          onChange={handleMonthChange}
          ones={false}
        />
        <RollerSelect
          options={tensOptions}
          fontSize={62}
          onChange={handleTensChange}
          animate={animateTens}
          autoChangeTo={changeTensTo}
          ones={false}
        />
        <RollerSelect
          options={onesOptions}
          fontSize={62}
          onChange={handleOnesChange}
          animate={animateOnes}
          autoChangeTo={changeOnesTo}
          ones={true}
        />
      </div>
      <div>
        <button className={styles.confirmBtn} onClick={confirmBtnClickHandler}>
          Confirm
        </button>
      </div>
    </div>
  );
};
