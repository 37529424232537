export const storyData = (name) => {
  return {
    'story#1': {
      title: '1. Beginnings',
      dps: 10,
      scenes: [
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_1.wav',
          dialog: {
            en: 'Well well well. It looks like we got ourselves another one!',
            sp: 'Bueno, bueno, bueno.',
            ch: '好，好，好。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_2.wav',
          dialog: {
            en: 'Another victim you mean?',
            sp: '¿Otra víctima quieres decir?',
            ch: '你是说另一个受害者吗？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_3.wav',
          dialog: {
            en: 'No, another paying customer.',
            sp: 'No, otro cliente que paga.',
            ch: '不是，另一个付费客户。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_4.wav',
          dialog: {
            en: 'Are you sure about that? They only just started.',
            sp: '¿Estás seguro de eso? Apenas han comenzado.',
            ch: '你确定吗？他们才刚刚开始。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_5.wav',
          dialog: {
            en: "You know most players don't turn into payers until a few hours into the experience.",
            sp: 'Sabes que la mayoría de los jugadores no se convierten en pagadores hasta unas pocas horas después de comenzar.',
            ch: '你知道大多数玩家在体验几个小时后才会成为付费玩家。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_6.wav',
          dialog: {
            en: 'Oh, well. I suppose I just got ahead of myself.',
            sp: 'Oh, bueno. Supongo que me adelanté.',
            ch: '哦，好吧。我想我有点太急了。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_7.wav',
          dialog: {
            en: "That's right. You forgot to give our hero the tutorial!",
            sp: 'Así es. ¡Olvidaste darle al héroe el tutorial!',
            ch: '对了。你忘了给我们的英雄做教程了！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_8.wav',
          dialog: {
            en: 'Oh, indeed! Hero, you know what this is?',
            sp: '¡Oh, de hecho! Héroe, ¿sabes qué es esto?',
            ch: '哦，的确！英雄，你知道这是什么吗？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_9.wav',
          dialog: {
            en: "This is the world of ±Pay 2 Win±. It's like yours, but without the boring bits.",
            sp: 'Este es el mundo de ±Pay 2 Win±. Es como el tuyo, pero sin las partes aburridas.',
            ch: '这是±Pay 2 Win±的世界。就像你的世界，但没有无聊的部分。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_10.wav',
          dialog: {
            en: 'Sleep, school, taxes, all that lame stuff. The only thing left here is the grind.',
            sp: 'Sueño, escuela, impuestos, toda esa cosa aburrida. Lo único que queda aquí es el grind.',
            ch: '睡觉、学校、税收，所有那些无聊的事情。这里只有磨练。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_11.wav',
          dialog: {
            en: "That's what you came here for, after all isn't it? The grind.",
            sp: 'Para eso viniste aquí, después de todo, ¿no? El grind.',
            ch: '毕竟，这就是你来这里的原因，不是吗？磨练。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_12.wav',
          dialog: {
            en: 'The grind! The grind!',
            sp: '¡El grind! ¡El grind!',
            ch: '磨练！磨练！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_13.wav',
          dialog: {
            en: 'Aye. So then, how about we quit talking about it and get to it?',
            sp: 'Sí. Entonces, ¿qué tal si dejamos de hablar de ello y nos ponemos a ello?',
            ch: '对。那么，我们不如停止谈论它，开始行动吧？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_14.wav',
          dialog: {
            en: 'Righto! The game will unlock as you deal more and more damage, hero.',
            sp: '¡Exacto! El juego se desbloqueará a medida que inflijas más y más daño, héroe.',
            ch: '没错！随着你造成越来越多的伤害，游戏将会解锁，英雄。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_15.wav',
          dialog: {
            en: 'Indeed. Deal some damage, newcomer. And get that number as high as possible.',
            sp: 'Así es. Inflige algo de daño, recién llegado. Y haz que ese número sea lo más alto posible.',
            ch: '确实如此。造成一些伤害，新手。并尽可能地提高那个数字。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_16.wav',
          dialog: {
            en: "That's an easy enough mission to follow, for a newbie.",
            sp: 'Es una misión lo suficientemente fácil de seguir para un principiante.',
            ch: '对于一个新手来说，这是一个足够简单的任务。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_17.wav',
          dialog: {
            en: 'One more thing. See the bottom there, where it says "STORY?"',
            sp: 'Una cosa más. Mira en la parte inferior, donde dice "STORY?"',
            ch: '还有一件事。看看底部，那里写着“STORY？”',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_18.wav',
          dialog: {
            en: 'When you see one of those icons glow, click on it for a visit from us.',
            sp: 'Cuando veas que uno de esos íconos brilla, haz clic en él para una visita de nuestra parte.',
            ch: '当你看到其中一个图标发光时，点击它来接受我们的访问。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_19.wav',
          dialog: {
            en: 'Your friendly neighborhood guides!',
            sp: '¡Tus guías amigables del vecindario!',
            ch: '你们友好的邻里向导！',
          },
        },
        {
          character: 'annie_and_may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_20.wav',
          dialog: {
            en: 'Hehe. Haha. Hahehehahaha.',
            sp: 'Jeje. Jaja. Jejejejeje.',
            ch: '呵呵。哈哈。哈哈哈哈。',
          },
        },
      ],
    },
    'story#2': {
      title: '2. Annie and May',
      dps: 20,
      scenes: [
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene2/vo_1.wav',
          dialog: {
            en: 'It looks like the hero is progressing well.',
            sp: 'Parece que el héroe está progresando bien.',
            ch: '看起来英雄进展顺利。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene2/vo_2.wav',
          dialog: {
            en: "Are you enjoying the game? Don't forget to rate us 5 stars!",
            sp: '¿Estás disfrutando del juego? ¡No olvides darnos 5 estrellas!',
            ch: '你喜欢这个游戏吗？不要忘了给我们五星好评！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene2/vo_3.wav',
          dialog: {
            en: "They can't do that Annie, we're not licensed on those funny app stores yet.",
            sp: 'No pueden hacer eso, Annie, aún no estamos licenciados en esas tiendas de aplicaciones divertidas.',
            ch: '他们不能这样做，Annie，我们还没有在那些搞笑的应用商店获得许可。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene2/vo_4.wav',
          dialog: {
            en: 'Well, what else should we have our new slave... erm... hero, do?',
            sp: 'Bueno, ¿qué más deberíamos hacer que nuestro nuevo esclavo... eh... héroe haga?',
            ch: '那么，我们还应该让我们的新奴隶……呃……英雄做什么呢？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene2/vo_5.wav',
          dialog: {
            en: "Oh, I know! What's your name?",
            sp: '¡Oh, lo sé! ¿Cómo te llamas?',
            ch: '哦，我知道了！你叫什么名字？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene2/vo_6.wav',
          dialog: {
            en: `Quite the mouthful, ${name}. A tad gauche. Maybe even a bit cute. Acceptable.`,
            sp: `Bastante complicado, ${name}. Un poco torpe. Tal vez incluso un poco lindo. Aceptable.`,
            ch: `挺复杂的，${name}。有点笨拙。也许还稍微有点可爱。可以接受。`,
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene2/vo_7.wav',
          dialog: {
            en: `A pleasure to make your acquaintance, ${name}. I'm Annie, by the way.`,
            sp: `Un placer conocerte, ${name}. Soy Annie, por cierto.`,
            ch: `很高兴认识你，${name}。对了，我是Annie。`,
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene2/vo_8.wav',
          dialog: {
            en: "I've been working with the devs since their first game.",
            sp: 'He estado trabajando con los desarrolladores desde su primer juego.',
            ch: '我从他们的第一款游戏开始就与开发者合作。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene2/vo_9.wav',
          dialog: {
            en: "I started off as a community member. Now I'm practically the CEO! Wahaha-",
            sp: 'Comencé como miembro de la comunidad. ¡Ahora soy prácticamente la CEO! Wahaha-',
            ch: '我最初是作为社区成员开始的。现在我几乎是CEO了！哈哈哈——',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene2/vo_10.wav',
          dialog: {
            en: "And I'm May. I'm from acquisitions. A pleasure.",
            sp: 'Y yo soy May. Estoy en adquisiciones. Un placer.',
            ch: '我是May。我负责收购。很高兴见到你。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene2/vo_11.wav',
          dialog: {
            en: 'Now that we have the pleasantries out of the way...',
            sp: 'Ahora que hemos terminado con las cortesías...',
            ch: '既然我们完成了寒暄……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene2/vo_12.wav',
          dialog: {
            en: `It's time to get back to your task, ${name}.`,
            sp: `Es hora de volver a tu tarea, ${name}.`,
            ch: `是时候回到你的任务上了，${name}。`,
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene2/vo_13.wav',
          dialog: {
            en: 'Oh, yes! Back to the great work!',
            sp: '¡Oh, sí! ¡De vuelta al gran trabajo!',
            ch: '哦，是的！回到伟大的工作中去！',
          },
        },
      ],
    },
    'story#3': {
      title: '3. The Shop',
      dps: 30,
      scenes: [
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene3/vo_1.wav',
          dialog: {
            en: 'Hey you! Having fun yet?',
            sp: '¡Oye tú! ¿Te estás divirtiendo?',
            ch: '嘿，你！玩得开心吗？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene3/vo_2.wav',
          dialog: {
            en: "I'm not sure they figured out how the game works, Annie.",
            sp: 'No estoy seguro de que hayan entendido cómo funciona el juego, Annie.',
            ch: '我不确定他们是否弄明白了游戏是怎么回事，Annie。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene3/vo_3.wav',
          dialog: {
            en: 'Oh... they seem to be doing just fine.',
            sp: 'Oh... parecen estar bien。',
            ch: '哦……他们似乎没问题。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene3/vo_4.wav',
          dialog: {
            en: "I don't think so... after all...they haven't spent a dime on the shop yet.",
            sp: 'No lo creo... después de todo...todavía no han gastado un centavo en la tienda。',
            ch: '我不这么认为……毕竟……他们还没在商店里花一分钱。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene3/vo_5.wav',
          dialog: {
            en: '...the shop?',
            sp: '...¿la tienda?',
            ch: '……商店？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene3/vo_6.wav',
          dialog: {
            en: 'Aye. Microtransactions are the soul of any modern video game.',
            sp: 'Sí. Las microtransacciones son el alma de cualquier videojuego moderno.',
            ch: '是的。微交易是任何现代视频游戏的灵魂。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene3/vo_7.wav',
          dialog: {
            en: 'Hero - take a look at the corner of your screen there.',
            sp: 'Héroe - echa un vistazo a la esquina de tu pantalla。',
            ch: '英雄 - 看看你屏幕的角落。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene3/vo_8.wav',
          dialog: {
            en: 'You can click that button to open up the shop.',
            sp: 'Puedes hacer clic en ese botón para abrir la tienda。',
            ch: '你可以点击那个按钮来打开商店。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene3/vo_9.wav',
          dialog: {
            en: "Once it's open, you can buy some items to speed you along.",
            sp: 'Una vez que esté abierta, puedes comprar algunos artículos para avanzar más rápido。',
            ch: '一旦打开，你可以购买一些物品来加速你的进度。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene3/vo_10.wav',
          dialog: {
            en: "You'll need some... Oh. OH....",
            sp: 'Vas a necesitar algunos... Oh. OH....',
            ch: '你需要一些……哦。哦……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene3/vo_11.wav',
          dialog: {
            en: 'Hero, your wallet is looking a little light.',
            sp: 'Héroe, tu billetera parece un poco vacía。',
            ch: '英雄，你的钱包看起来有点空。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene3/vo_12.wav',
          dialog: {
            en: "I'll get you some free vouchers so you can start.",
            sp: 'Te daré algunos vales gratuitos para que puedas empezar。',
            ch: '我会给你一些免费券让你开始。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene3/vo_13.wav',
          dialog: {
            en: "But only to start. After that, you'll have to pay.",
            sp: 'Pero solo para comenzar. Después de eso, tendrás que pagar。',
            ch: '但只是为了开始。之后你需要付款。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene3/vo_14.wav',
          dialog: {
            en: "But don't worry. The devs have made sure the shop experience is perfect.",
            sp: 'Pero no te preocupes. Los desarrolladores se han asegurado de que la experiencia de la tienda sea perfecta。',
            ch: '但不用担心。开发者确保了商店体验是完美的。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene3/vo_15.wav',
          dialog: {
            en: 'Umm... May, the shop tutorial is great and all.',
            sp: 'Hmm... May, el tutorial de la tienda está bien y todo。',
            ch: '嗯……May，商店教程很好。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene3/vo_16.wav',
          dialog: {
            en: 'But what does this have to do with playing the game?',
            sp: 'Pero, ¿qué tiene que ver esto con jugar el juego?',
            ch: '但这和玩游戏有什么关系？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene3/vo_17.wav',
          dialog: {
            en: 'Quiet now, Annie.',
            sp: 'Cállate ahora, Annie.',
            ch: '安静，Annie。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene3/vo_18.wav',
          dialog: {
            en: 'A good microtransaction shop will be the key to our success.',
            sp: 'Una buena tienda de microtransacciones será la clave de nuestro éxito.',
            ch: '一个好的微交易商店将是我们成功的关键。',
          },
        },
      ],
    },
    'story#4': {
      title: '4. Nodes',
      dps: 50,
      scenes: [
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_1.wav',
          dialog: {
            en: 'Hmm hm hmmmm (humming)',
            sp: 'Hmm hm hmmmm (tarareando)',
            ch: '嗯哼哼哼（哼唱）',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_2.wav',
          dialog: {
            en: 'Our hero seems to be progressing well.',
            sp: 'Nuestro héroe parece estar progresando bien.',
            ch: '我们的英雄似乎进展不错。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_3.wav',
          dialog: {
            en: 'Yup. Terribly slow though. (sigh)',
            sp: 'Sí. Aunque terriblemente lento. (suspiro)',
            ch: '是的。不过非常慢。（叹气）',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_4.wav',
          dialog: {
            en: "Aye. Seems even that epic gear we gave them isn't helping much.",
            sp: 'Sí. Parece que incluso ese equipo épico que les dimos no está ayudando mucho.',
            ch: '是的。看来即使是我们给他们的史诗装备也没什么帮助。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_5.wav',
          dialog: {
            en: 'What are we to do?',
            sp: '¿Qué vamos a hacer?',
            ch: '我们该怎么办？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_6.wav',
          dialog: {
            en: 'I have a few ideas. We can set up botting farms, autoclickers...',
            sp: 'Tengo algunas ideas. Podemos configurar granjas de bots, autoclickers...',
            ch: '我有几个想法。我们可以设置机器人农场，自动点击器……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_7.wav',
          dialog: {
            en: 'Oh my. What next, referral scams?',
            sp: 'Oh Dios. ¿Qué sigue, estafas de referidos?',
            ch: '哦我的。接下来是推荐诈骗吗？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_8.wav',
          dialog: {
            en: "You know, that's not a bad idea.",
            sp: 'Sabes, no es una mala idea.',
            ch: '你知道，这不是一个坏主意。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_9.wav',
          dialog: {
            en: 'Ahem. Hero!',
            sp: 'Ahem. ¡Héroe!',
            ch: '咳咳。英雄！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_10.wav',
          dialog: {
            en: "I've unlocked a new node on the right over there.",
            sp: 'He desbloqueado un nuevo nodo a la derecha.',
            ch: '我已经在右边解锁了一个新节点。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_11.wav',
          dialog: {
            en: "It's passive, so you can just sit back and watch the numbers fly.",
            sp: 'Es pasivo, así que puedes sentarte y ver los números volar.',
            ch: '这是被动的，所以你可以坐下来，看着数字飞涨。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_12.wav',
          dialog: {
            en: '...Once you buy them of course.',
            sp: '...Una vez que los compres, por supuesto.',
            ch: '……当然，一旦你购买它们。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_13.wav',
          dialog: {
            en: "I don't know about this, May.",
            sp: 'No sé acerca de esto, May.',
            ch: '我对这个不太确定，May。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_14.wav',
          dialog: {
            en: 'Oh, quit being a goody two shoes, Annie.',
            sp: 'Oh, deja de ser una santurrona, Annie.',
            ch: '哦，别再做个好人了，Annie。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_15.wav',
          dialog: {
            en: "Don't you want our hero to progress? To save the world?!",
            sp: '¿No quieres que nuestro héroe progrese? ¡¿Para salvar el mundo?!',
            ch: '你不希望我们的英雄进步吗？拯救世界？！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_16.wav',
          dialog: {
            en: 'Botting is industry standard in our genre of game anyway.',
            sp: 'De todos modos, el botting es estándar en nuestro género de juego.',
            ch: '反正，使用机器人是我们这个游戏类型的行业标准。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_17.wav',
          dialog: {
            en: 'Plus it will make our player count look *amazing*.',
            sp: 'Además, hará que nuestro conteo de jugadores se vea *increíble*.',
            ch: '而且它会让我们的玩家数量看起来*惊人*。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_18.wav',
          dialog: {
            en: "Don't you want that?",
            sp: '¿No quieres eso?',
            ch: '你不想要吗？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_19.wav',
          dialog: {
            en: 'Oh, definitely! We should have as many players as possible!',
            sp: '¡Oh, definitivamente! ¡Deberíamos tener la mayor cantidad de jugadores posible!',
            ch: '哦，当然！我们应该有尽可能多的玩家！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_20.wav',
          dialog: {
            en: 'The entire world should be playing our game!',
            sp: '¡Todo el mundo debería estar jugando nuestro juego!',
            ch: '全世界都应该玩我们的游戏！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_21.wav',
          dialog: {
            en: 'Indeed. Plus, if gaming journalists are anything like investors...',
            sp: 'En efecto. Además, si los periodistas de videojuegos son como los inversores...',
            ch: '确实。而且，如果游戏记者像投资者一样……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_22.wav',
          dialog: {
            en: "They'll never actually check if the numbers are real, after all.",
            sp: 'Después de todo, nunca verificarán si los números son reales.',
            ch: '毕竟，他们永远不会检查数字是否真实。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene4/vo_23.wav',
          dialog: {
            en: "(giggling) Now that's a plan! Let's go!",
            sp: '(riendo) ¡Ahora eso es un plan! ¡Vamos!',
            ch: '（咯咯笑）这真是个计划！我们走吧！',
          },
        },
      ],
    },
    'story#5': {
      title: '5. Mobile Games',
      dps: 100,
      scenes: [
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_1.wav',
          dialog: {
            en: 'Tch. Grmgmr. Mrgrgr. Hmmmm..',
            sp: 'Tch. Grmgmr. Mrgrgr. Hmmmm..',
            ch: '咂。咕咕咕。咕咕。嗯……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_2.wav',
          dialog: {
            en: 'Dang it! Lost another roll!',
            sp: '¡Maldita sea! ¡Perdí otro tiro!',
            ch: '该死！又输了一个抽奖！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_3.wav',
          dialog: {
            en: "Annie? What is that you're playing?",
            sp: '¿Annie? ¿Qué estás jugando?',
            ch: '安妮？你在玩什么？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_4.wav',
          dialog: {
            en: "Oh - I'm just rolling for some characters on *Bonkai Impact*.",
            sp: 'Oh, solo estoy intentando conseguir algunos personajes en *Bonkai Impact*.',
            ch: '哦，我只是想在*Bonkai Impact*上抽几个角色。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_5.wav',
          dialog: {
            en: "*Bonkai Impact?* That's another Mindwave property. How delightful.",
            sp: '*Bonkai Impact*? Eso es otra propiedad de Mindwave. Qué encantador.',
            ch: '*Bonkai Impact*？那是另一个Mindwave的财产。真令人愉快。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_6.wav',
          dialog: {
            en: "There's nothing delightful about it May. These new gacha games are terrible.",
            sp: 'No hay nada encantador en ello, May. Estos nuevos juegos de gacha son terribles.',
            ch: '没什么好令人愉快的，梅。这些新的抽卡游戏很糟糕。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_7.wav',
          dialog: {
            en: 'The drop rates on loot are low.',
            sp: 'Las tasas de caída de botín son bajas.',
            ch: '战利品的掉落率很低。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_8.wav',
          dialog: {
            en: "The rolling system gives you barely anything for free and it's all paywalled.",
            sp: 'El sistema de tiradas apenas te da algo gratis y todo está bloqueado tras un muro de pago.',
            ch: '抽奖系统几乎不给你任何免费的东西，全部都被付费墙挡住了。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_9.wav',
          dialog: {
            en: 'Plus the characters are your typical busty vixens, you know the kind...',
            sp: 'Además, los personajes son las típicas chicas voluptuosas, ya sabes el tipo...',
            ch: '而且角色都是那些典型的丰满美眉，你知道那种……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_10.wav',
          dialog: {
            en: 'The kind of anime girls made to appeal to the lowest common denominator. Honestly!',
            sp: 'El tipo de chicas de anime hechas para atraer al denominador común más bajo. ¡Honestamente!',
            ch: '那些为了迎合最低层次观众的动漫女孩。老实说！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_11.wav',
          dialog: {
            en: 'Just imagining unwashed teenagers slobbering and drooling over cartoon characters... Awful!',
            sp: 'Solo imaginar adolescentes sucios babeando y salivando sobre personajes de dibujos animados... ¡Horrible!',
            ch: '仅仅想象一下那些没洗澡的青少年在卡通角色面前流口水……太可怕了！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_12.wav',
          dialog: {
            en: 'Well why ever are you playing it then?',
            sp: 'Entonces, ¿por qué lo estás jugando?',
            ch: '那你为什么还要玩它呢？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_13.wav',
          dialog: {
            en: "Don't tease me, woman! We all have our vices.",
            sp: '¡No me burles, mujer! Todos tenemos nuestros vicios.',
            ch: '别逗我了，女人！我们都有自己的恶习。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_14.wav',
          dialog: {
            en: "Our game is the same, no? I'd say we're pretty cute as far as anime girls go.",
            sp: 'Nuestro juego es el mismo, ¿no? Diría que somos bastante lindas en cuanto a chicas de anime.',
            ch: '我们的游戏也是一样的，不是吗？我会说我们在动漫女孩中算是挺可爱的。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_15.wav',
          dialog: {
            en: 'The only reason our hero is even interested is probably for the same vulgar reasons.',
            sp: 'La única razón por la que nuestro héroe está interesado es probablemente por las mismas razones vulgares.',
            ch: '我们英雄感兴趣的唯一原因可能也是出于同样的庸俗理由。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_16.wav',
          dialog: {
            en: 'No, we are beautiful network angels, not pixel PROSTITUTES!',
            sp: '¡No, somos hermosos ángeles de la red, no PROSTITUTAS pixeladas!',
            ch: '不，我们是美丽的网络天使，不是像素化的妓女！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_17.wav',
          dialog: {
            en: 'Our hero is playing for the plot. And for our great personalities.',
            sp: 'Nuestro héroe está jugando por la trama. Y por nuestras grandes personalidades.',
            ch: '我们的英雄是为了情节和我们的伟大个性而玩游戏。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_18.wav',
          dialog: {
            en: 'But nevermind that. I tell you May, the world is moving in the wrong direction...',
            sp: 'Pero no importa. Te digo, May, el mundo se está moviendo en la dirección equivocada...',
            ch: '不过算了。我告诉你，梅，世界正朝着错误的方向发展……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_19.wav',
          dialog: {
            en: 'The games industry is dead! The suits have taken over!',
            sp: '¡La industria de los juegos está muerta! ¡Los trajes han tomado el control!',
            ch: '游戏产业已经死了！那些西装革履的人占据了主导地位！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_20.wav',
          dialog: {
            en: 'I am right here, Annie...',
            sp: 'Estoy justo aquí, Annie...',
            ch: '我就在这里，安妮……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_21.wav',
          dialog: {
            en: "You're different, May. Most of those gaming executives though?",
            sp: 'Eres diferente, May. ¿La mayoría de esos ejecutivos de juegos?',
            ch: '你不同，梅。不过那些游戏高管呢？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_22.wav',
          dialog: {
            en: "They've probably never even met the teams under them.",
            sp: 'Probablemente ni siquiera han conocido a los equipos bajo su mando.',
            ch: '他们可能从未见过他们下面的团队。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_23.wav',
          dialog: {
            en: 'The only thing a games executive is an actual expert on...',
            sp: 'Lo único en lo que un ejecutivo de juegos es un verdadero experto...',
            ch: '游戏高管唯一真正擅长的……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_24.wav',
          dialog: {
            en: 'Is the size of their yacht and the square footage of their mansion.',
            sp: 'Es el tamaño de su yate y los metros cuadrados de su mansión.',
            ch: '就是他们的游艇大小和豪宅的面积。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_25.wav',
          dialog: {
            en: 'Well, that has some truth to it... My superiors have indeed never played a video game.',
            sp: 'Bueno, eso tiene algo de verdad... Mis superiores realmente nunca han jugado un videojuego.',
            ch: '嗯，这确实有些道理……我的上司们确实从未玩过电子游戏。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_26.wav',
          dialog: {
            en: 'Gamers are a touchy bunch, May. Some day we will revolt! Gamers rise up!',
            sp: 'Los jugadores son un grupo sensible, May. ¡Algún día nos rebelaremos! ¡Jugadores, levántense!',
            ch: '玩家们是很敏感的，梅。总有一天我们会反抗的！玩家们起来吧！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_27.wav',
          dialog: {
            en: 'Ahem. Until then, I suppose the profits will keep rolling. And so will you.',
            sp: 'Ahem. Hasta entonces, supongo que las ganancias seguirán fluyendo. Y tú también.',
            ch: '咳。到那时，我想利润将会继续滚动。你也会继续的。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: 'assets/Story/Audios/VO/Scene5/vo_28.wav',
          dialog: {
            en: '...',
            sp: '...',
            ch: '……',
          },
        },
      ],
    },
    'story#6': {
      title: '6. The Studio',
      dps: 100,
      scenes: [
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_annie1.mp3',
          dialog: {
            en: `Freeze, ${name}!`,
            sp: `¡Espera, ${name}!`,
            ch: `停下，${name}！`,
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_may1.mp3',
          dialog: {
            en: 'What is it now, Annie?',
            sp: '¿Qué pasa ahora, Annie?',
            ch: '现在怎么了，安妮？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_annie2.mp3',
          dialog: {
            en: 'We need to give more exposition.',
            sp: 'Necesitamos dar más exposición.',
            ch: '我们需要更多的背景介绍。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_may2.mp3',
          dialog: {
            en: 'Oh I see. What are we teaching them now?',
            sp: 'Oh, ya veo. ¿Qué les estamos enseñando ahora?',
            ch: '哦，我明白了。我们现在要教他们什么？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_annie3.mp3',
          dialog: {
            en: 'Like all great stories, we need to build a background.',
            sp: 'Como todas las grandes historias, necesitamos construir un trasfondo.',
            ch: '像所有伟大的故事一样，我们需要建立背景。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_annie4.mp3',
          dialog: {
            en: "It's the only way to have a satisfying conclusion later on.",
            sp: 'Es la única forma de tener una conclusión satisfactoria más adelante.',
            ch: '这是后来获得令人满意结局的唯一方法。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_may3.mp3',
          dialog: {
            en: 'Get on with it then, what do you have in mind?',
            sp: 'Entonces sigue, ¿qué tienes en mente?',
            ch: '那就继续吧，你有什么想法？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_annie5.mp3',
          dialog: {
            en: 'The hero should learn how we arrived in this... state.',
            sp: 'El héroe debería aprender cómo llegamos a este... estado.',
            ch: '英雄应该了解我们是如何陷入这个……状态的。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_may4.mp3',
          dialog: {
            en: 'You mean a broke games company on the verge of bankruptcy?',
            sp: '¿Te refieres a una compañía de juegos quebrada al borde de la bancarrota?',
            ch: '你是说一家濒临破产的破产游戏公司？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_annie6.mp3',
          dialog: {
            en: 'No!... well... yes...',
            sp: '¡No!... bueno... sí...',
            ch: '不！……嗯……是的……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_annie7.mp3',
          dialog: {
            en: 'Listen, hero. The devs were valiant and wise and handsome....',
            sp: 'Escucha, héroe. Los desarrolladores eran valientes, sabios y guapos...',
            ch: '听着，英雄。开发者们既勇敢又智慧，还很帅……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_may5.mp3',
          dialog: {
            en: 'And..?',
            sp: '¿Y...?',
            ch: '然后呢？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_annie8.mp3',
          dialog: {
            en: 'But no matter how valiant and wise and handsome they were...',
            sp: 'Pero no importaba cuán valientes, sabios y guapos eran...',
            ch: '但无论他们多么勇敢、智慧、帅气……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_annie9.mp3',
          dialog: {
            en: "They couldn't really turn a profit. Those were dark days...",
            sp: 'No podían realmente obtener ganancias. Fueron días oscuros...',
            ch: '他们确实无法盈利。那些日子很黑暗……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_annie10.mp3',
          dialog: {
            en: 'I remember it like it was yesterday.',
            sp: 'Lo recuerdo como si fuera ayer.',
            ch: '我记得就像是昨天发生的一样。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_may6.mp3',
          dialog: {
            en: 'Hmm...',
            sp: 'Hmm...',
            ch: '嗯……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_annie11.mp3',
          dialog: {
            en: 'In order to save the studio, they sold off a majority stake in the company.',
            sp: 'Para salvar el estudio, vendieron una participación mayoritaria en la empresa.',
            ch: '为了拯救工作室，他们出售了公司的大部分股份。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_may7.mp3',
          dialog: {
            en: 'Not uncommon in a corporate war era such as this.',
            sp: 'No es raro en una era de guerra corporativa como esta.',
            ch: '在这样的企业战争时代并不罕见。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_annie12.mp3',
          dialog: {
            en: 'Yes... It was sold recently to Mindwave Limited.',
            sp: 'Sí... Fue vendido recientemente a Mindwave Limited.',
            ch: '是的……它最近被出售给了Mindwave Limited。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_may8.mp3',
          dialog: {
            en: 'A most illustrious company indeed.',
            sp: 'Una compañía de gran prestigio, de hecho.',
            ch: '确实是家最著名的公司。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_annie13.mp3',
          dialog: {
            en: 'Yeah right! Those guys are just money grubbers! I hate them all!',
            sp: '¡Claro! ¡Esos tipos solo son avaros! ¡Los odio a todos!',
            ch: '对啊！那些家伙只是钱贪婪者！我讨厌他们所有人！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_may9.mp3',
          dialog: {
            en: 'You know I work for them right?',
            sp: '¿Sabes que trabajo para ellos, verdad?',
            ch: '你知道我为他们工作，对吧？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_annie14.mp3',
          dialog: {
            en: '(sighs). Yes. Well.',
            sp: '(suspira). Sí. Bueno.',
            ch: '（叹气）。是的。那么。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_may10.mp3',
          dialog: {
            en: 'Listen, the only reason the studio still exists is because of that deal.',
            sp: 'Escucha, la única razón por la que el estudio todavía existe es por ese trato.',
            ch: '听着，工作室仍然存在的唯一原因就是那个交易。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_may11.mp3',
          dialog: {
            en: 'Selling out is just what everyone does in these times.',
            sp: 'Venderse es solo lo que todos hacen en estos tiempos.',
            ch: '卖掉自己只是这个时代大家都在做的事情。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_annie15.mp3',
          dialog: {
            en: 'Hmph! For pennies on the dollar!',
            sp: '¡Hum! ¡Por centavos en el dólar!',
            ch: '哼！以分文不值的价格！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_may12.mp3',
          dialog: {
            en: 'The market is always right you know. The price was fair.',
            sp: 'El mercado siempre tiene razón, ya sabes. El precio era justo.',
            ch: '市场总是对的，你知道的。价格是公平的。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thestudio_may13.mp3',
          dialog: {
            en: "Now it's our job to turn this thing around!",
            sp: '¡Ahora es nuestro trabajo darle la vuelta a esto!',
            ch: '现在轮到我们把这个局面扭转过来了！',
          },
        },
      ],
    },
    'story#7': {
      title: '7. The Astroclock',
      dps: 150,
      scenes: [
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_may1.mp3',
          dialog: {
            en: 'It seems our hero has unlocked the Astroclock.',
            sp: 'Parece que nuestro héroe ha desbloqueado el Astroclock.',
            ch: '看来我们的英雄解锁了星象钟。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_annie1.mp3',
          dialog: {
            en: 'All hail the Astroclock!',
            sp: '¡Viva el Astroclock!',
            ch: '万岁，星象钟！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_may2.mp3',
          dialog: {
            en: "It's a neat toy.",
            sp: 'Es un juguete interesante.',
            ch: '这是一件很不错的玩具。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_annie2.mp3',
          dialog: {
            en: 'Far more than just a toy! (offended)',
            sp: '¡Mucho más que un simple juguete! (ofendida)',
            ch: '远不止是玩具！(不满)',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_may3.mp3',
          dialog: {
            en: 'Sure, whatever. (amused/dismissive)',
            sp: 'Claro, lo que sea. (divertido/despectivo)',
            ch: '当然了，无所谓。 ( amused/dismissive)',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_annie3.mp3',
          dialog: {
            en: 'You finance types are all the same. A bunch of soulless cowards.',
            sp: 'Todos los tipos de finanzas son iguales. Un montón de cobardes sin alma.',
            ch: '你们这些金融类型的人都是一样的。一群没有灵魂的懦夫。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_annie4.mp3',
          dialog: {
            en: 'Do you even realize the great significance of Astrology?',
            sp: '¿Te das cuenta de la gran importancia de la astrología?',
            ch: '你是否意识到占星术的重要意义？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_may4.mp3',
          dialog: {
            en: 'No.',
            sp: 'No.',
            ch: '不。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_annie5.mp3',
          dialog: {
            en: '(sighs) Astrology measures the effect of distant celestial bodies on our mortal lives.',
            sp: '(suspira) La astrología mide el efecto de los cuerpos celestes distantes en nuestras vidas mortales.',
            ch: '（叹气）占星术测量遥远天体对我们凡人生活的影响。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_annie6.mp3',
          dialog: {
            en: "Don't you know anything about gravity??",
            sp: '¿No sabes nada sobre la gravedad?',
            ch: '你不知道什么是重力吗？？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_annie7.mp3',
          dialog: {
            en: 'Do you remember anything from physics class??',
            sp: '¿Recuerdas algo de la clase de física?',
            ch: '你还记得物理课上的内容吗？？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_may5.mp3',
          dialog: {
            en: "I'm getting the impression you haven't even taken a physics class.",
            sp: 'Tengo la impresión de que ni siquiera has tomado una clase de física.',
            ch: '我感觉你甚至没有上过物理课。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_annie8.mp3',
          dialog: {
            en: "Whatever. Doesn't matter. Stop asking questions.",
            sp: 'Lo que sea. No importa. Deja de hacer preguntas.',
            ch: '随便。没关系。别再问问题了。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_annie9.mp3',
          dialog: {
            en: 'Just tell the hero what the Astroclock does.',
            sp: 'Solo dile al héroe qué hace el Astroclock.',
            ch: '只要告诉英雄星象钟的作用。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_may6.mp3',
          dialog: {
            en: 'Aye. Remember when you entered in your birthday to play the game?',
            sp: 'Sí. ¿Recuerdas cuando ingresaste tu cumpleaños para jugar el juego?',
            ch: '对了。还记得你输入生日来玩游戏的时候吗？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_may7.mp3',
          dialog: {
            en: "You didn't fake that, did you? That's a TOS violation.",
            sp: 'No lo falsificaste, ¿verdad? Eso es una violación de los TOS.',
            ch: '你没伪造吧？那是违反服务条款的。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_may8.mp3',
          dialog: {
            en: "Anyways, whenever it's your birthday's astrological sign....",
            sp: 'De todos modos, siempre que sea el signo astrológico de tu cumpleaños...',
            ch: '无论如何，只要是你生日的星座……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_may9.mp3',
          dialog: {
            en: 'You can query the Astroclock for an Astrothought.',
            sp: 'Puedes consultar el Astroclock para obtener un Astrothought.',
            ch: '你可以查询星象钟以获取一条星象思考。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_may10.mp3',
          dialog: {
            en: 'The Astrothought is a kitschy one-liner which-',
            sp: 'El Astrothought es una línea cursi que-',
            ch: '星象思考是一句俗气的台词——',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_annie10.mp3',
          dialog: {
            en: "It's not a kitschy one-liner, it's a piece of timeless wisdom!",
            sp: '¡No es una línea cursi, es una pieza de sabiduría atemporal!',
            ch: '这不是句俗气的台词，这是永恒智慧的一部分！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_may11.mp3',
          dialog: {
            en: 'Right. Anyways, the Astrothought gives you a huge performance boost.',
            sp: 'Claro. De todos modos, el Astrothought te da un gran impulso en el rendimiento.',
            ch: '对。总之，星象思考会给你一个巨大的性能提升。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_may12.mp3',
          dialog: {
            en: 'Further, whenever the current sign on the clock shares an elemental typing with your sign, you get a smaller bonus.',
            sp: 'Además, siempre que el signo actual en el reloj comparta un tipo elemental con tu signo, obtendrás un bono más pequeño.',
            ch: '此外，每当时钟上的当前星座与你的星座共享一个元素类型时，你会获得一个较小的奖励。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_annie11.mp3',
          dialog: {
            en: "Pretty cool, isn't it? Much better than that technical analysis garbage.",
            sp: 'Bastante genial, ¿verdad? Mucho mejor que esa basura de análisis técnico.',
            ch: '很酷，不是吗？比那些技术分析的垃圾好得多。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_annie12.mp3',
          dialog: {
            en: 'Why rely on the chaos between your temples, when you could rely on the divine forces of the planet and stars?',
            sp: '¿Por qué depender del caos entre tus sienes, cuando puedes depender de las fuerzas divinas del planeta y las estrellas?',
            ch: '为何依赖于你脑中的混乱，而不是依赖于星球和星星的神圣力量？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_annie13.mp3',
          dialog: {
            en: 'It takes a lot of courage to embrace your small role in the universe, you know.',
            sp: 'Se necesita mucho coraje para aceptar tu pequeño papel en el universo, sabes.',
            ch: '拥抱你在宇宙中微小的角色需要很大的勇气，你知道的。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_may13.mp3',
          dialog: {
            en: 'Personally, I place about as much faith in Astrology as I do in technical analysis.',
            sp: 'Personalmente, tengo tanta fe en la astrología como en el análisis técnico.',
            ch: '就我个人而言，我对占星术的信仰与对技术分析的信仰一样多。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_annie14.mp3',
          dialog: {
            en: "That's a good start for a godless profiteer!",
            sp: '¡Esa es una buena base para un mercader ateo!',
            ch: '对于一个无神论的盈利者来说，这是一个不错的开始！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/upgradeastroclock_annie15.mp3',
          dialog: {
            en: 'But Astrology is way better.',
            sp: 'Pero la astrología es mucho mejor.',
            ch: '但占星术要好得多。',
          },
        },
      ],
    },
    'story#8': {
      title: "8. What's in a Name",
      dps: 200,
      scenes: [
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thenameofpay2win_may1.mp3',
          dialog: {
            en: 'Pay 1 Win. Such an unmarketable name. Why did they do it?',
            sp: 'Pagar 1 Ganar. Un nombre tan poco comercial. ¿Por qué lo hicieron?',
            ch: '付费 1 赢。一个如此不具市场性的名字。他们为什么这么做？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thenameofpay2win_annie1.mp3',
          dialog: {
            en: 'I know the answer to this!',
            sp: '¡Sé la respuesta a esto!',
            ch: '我知道答案！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thenameofpay2win_may2.mp3',
          dialog: {
            en: 'Go on.',
            sp: 'Adelante.',
            ch: '继续。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thenameofpay2win_annie2.mp3',
          dialog: {
            en: "It is a translation from the dev's native language.",
            sp: 'Es una traducción del idioma nativo del desarrollador.',
            ch: '这是对开发者母语的翻译。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thenameofpay2win_annie3.mp3',
          dialog: {
            en: "The lead dev, a superstitious man, used the glyphs for 'Pay,' 'Foremost,' and 'Win' as the name.",
            sp: "El desarrollador principal, un hombre supersticioso, usó los glifos para 'Pagar', 'Primero' y 'Ganar' como nombre.",
            ch: '主要开发者，一个迷信的人，使用了“付费”，“首要”和“赢”的象形文字作为名称。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thenameofpay2win_annie4.mp3',
          dialog: {
            en: "He wished to manifest the game's financial success.",
            sp: 'Deseaba manifestar el éxito financiero del juego.',
            ch: '他希望体现游戏的财务成功。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thenameofpay2win_annie5.mp3',
          dialog: {
            en: 'It literally translated to English as Pay 1 Win.',
            sp: 'Se tradujo literalmente al inglés como Pay 1 Win.',
            ch: '它在英语中字面翻译为 Pay 1 Win。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thenameofpay2win_may3.mp3',
          dialog: {
            en: 'What about Pay 2 Win?',
            sp: '¿Qué pasa con Pay 2 Win?',
            ch: '那“付费 2 赢”呢？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thenameofpay2win_annie6.mp3',
          dialog: {
            en: 'After the corporate acquisition, the new CEO did not speak that language.',
            sp: 'Después de la adquisición corporativa, el nuevo CEO no hablaba ese idioma.',
            ch: '在公司收购后，新CEO不讲那种语言。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thenameofpay2win_annie7.mp3',
          dialog: {
            en: 'He wanted the studio to make a sequel, and decided to change the number from 1 to 2.',
            sp: 'Quería que el estudio hiciera una secuela y decidió cambiar el número de 1 a 2.',
            ch: '他想让工作室制作续集，并决定将数字从 1 改为 2。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thenameofpay2win_may4.mp3',
          dialog: {
            en: "That's when I got involved, so I know the rest.",
            sp: 'Ahí fue cuando me involucré, así que sé el resto.',
            ch: '那时我参与了，所以我知道后续的事情。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thenameofpay2win_may5.mp3',
          dialog: {
            en: 'But what was this language that the devs spoke?',
            sp: '¿Pero cuál era este idioma que hablaban los desarrolladores?',
            ch: '但是开发者讲的是什么语言？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thenameofpay2win_annie8.mp3',
          dialog: {
            en: 'Nobody knows.',
            sp: 'Nadie lo sabe.',
            ch: '没有人知道。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thenameofpay2win_may6.mp3',
          dialog: {
            en: 'All traces of it were erased from the record.',
            sp: ' Todos los rastros de él fueron borrados del registro.',
            ch: '所有关于它的痕迹都从记录中删除了。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thenameofpay2win_annie9.mp3',
          dialog: {
            en: "Wouldn't you know at least? You were around after all...",
            sp: '¿No lo sabrías al menos? Estuviste presente después de todo...',
            ch: '你至少应该知道吧？毕竟你还在那时……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thenameofpay2win_may7.mp3',
          dialog: {
            en: 'I was mostly on the forums, and the CEO never spoke to me in that language...',
            sp: 'Principalmente estuve en los foros, y el CEO nunca me habló en ese idioma...',
            ch: '我主要在论坛上，CEO 从未用那种语言跟我说话……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/thenameofpay2win_may7.mp3',
          dialog: {
            en: 'A likely story.',
            sp: 'Una historia plausible.',
            ch: '这倒是个有可能的故事。',
          },
        },
      ],
    },
    'story#9': {
      title: '9. A Brief History',
      dps: 300,
      scenes: [
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_may1.mp3',
          dialog: {
            en: 'Annie.',
            sp: 'Annie.',
            ch: '安妮。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_annie1.mp3',
          dialog: {
            en: 'May.',
            sp: 'May.',
            ch: '梅。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_may2.mp3',
          dialog: {
            en: 'The hero thoroughly enjoyed the last story. I think they want more.',
            sp: 'El héroe disfrutó mucho de la última historia. Creo que quieren más.',
            ch: '英雄非常喜欢上一个故事。我想他们还想要更多。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_annie2.mp3',
          dialog: {
            en: "Well, good stories don't grow on trees!",
            sp: '¡Bueno, las buenas historias no crecen en los árboles!',
            ch: '好故事可不是随便就有的！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_annie3.mp3',
          dialog: {
            en: 'Plus our writers are unpaid in accordance with the new company polic-.',
            sp: 'Además, nuestros escritores no reciben pago de acuerdo con la nueva política de la empresa-.',
            ch: '再说我们的编剧都是按照新公司的政策无偿工作的……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_may3.mp3',
          dialog: {
            en: "AHEM. Why don't you tell the hero about yourself?",
            sp: 'AHEM. ¿Por qué no le cuentas al héroe sobre ti misma?',
            ch: '咳咳。为什么不告诉英雄一些关于你自己的事呢？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_annie4.mp3',
          dialog: {
            en: 'Ah, my favorite subject!',
            sp: '¡Ah, mi tema favorito!',
            ch: '啊，我最喜欢的话题！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_annie5.mp3',
          dialog: {
            en: "My name is Annie Liston, my favorite food is rice with natto, I'm 24 years-",
            sp: 'Mi nombre es Annie Liston, mi comida favorita es arroz con natto, tengo 24 años-',
            ch: '我的名字是安妮·利斯顿，我最喜欢的食物是纳豆饭，我24岁——',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_may4.mp3',
          dialog: {
            en: "Annie, I meant more pertinent details. Like how you're involved in this whole operation.",
            sp: 'Annie, me refería a detalles más pertinentes. Como cómo estás involucrada en toda esta operación.',
            ch: '安妮，我是说更多相关的细节。 比如你是如何参与到整个操作中的。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_annie6.mp3',
          dialog: {
            en: 'The game, you mean?',
            sp: '¿El juego, quieres decir?',
            ch: '你是说这款游戏？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_may6.mp3',
          dialog: {
            en: 'Indeed. Surely you have a most compelling backstory.',
            sp: 'Así es. Seguramente tienes una historia de fondo muy convincente.',
            ch: '确实如此。你一定有一个非常吸引人的背景故事。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_annie7.mp3',
          dialog: {
            en: "Well, it all started with the dev's first game actually.",
            sp: 'Bueno, todo comenzó con el primer juego del desarrollador, en realidad.',
            ch: '嗯，其实一切都始于开发者的第一个游戏。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_annie8.mp3',
          dialog: {
            en: 'I was one of the players in their community...',
            sp: 'Yo era uno de los jugadores en su comunidad...',
            ch: '我曾是他们社区中的一名玩家……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_annie9.mp3',
          dialog: {
            en: "I was actually kind of famous! I'm sure a lot of players still remember me.",
            sp: '¡De hecho, era algo famosa! Estoy segura de que muchos jugadores todavía me recuerdan.',
            ch: '我当时其实有点名气！我相信很多玩家现在还记得我。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_annie10.mp3',
          dialog: {
            en: 'The CEO messaged me one day and we hit it off!',
            sp: 'Un día el CEO me envió un mensaje y congeniamos.',
            ch: '有一天CEO给我发了消息，我们聊得很投机！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_may7.mp3',
          dialog: {
            en: "That still doesn't explain how you wound up working for him.",
            sp: 'Eso aún no explica cómo terminaste trabajando para él.',
            ch: '这还不能解释你是怎么最终为他工作了。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_annie11.mp3',
          dialog: {
            en: 'As a loyal supporter, they gave me a prestigious role!',
            sp: 'Como una fiel seguidora, ¡me dieron un papel prestigioso!',
            ch: '作为一个忠实支持者，他们给了我一个很有声望的角色！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_annie12.mp3',
          dialog: {
            en: 'Curator of Online Coordination and Director of Communal Management.',
            sp: 'Curadora de Coordinación Online y Directora de Gestión Comunitaria.',
            ch: '在线协调策展人和社区管理主任。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_may8.mp3',
          dialog: {
            en: 'So like an intern right?',
            sp: '¿Así que como un pasante, verdad?',
            ch: '那就像是实习生对吧？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_annie13.mp3',
          dialog: {
            en: 'No! Much more than that! I had so much responsibility!',
            sp: '¡No! ¡Mucho más que eso! ¡Tenía mucha responsabilidad!',
            ch: '不！远不止这些！我承担了很多责任！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_annie14.mp3',
          dialog: {
            en: 'You have no idea how responsible I was. Oh, it was terrible!',
            sp: 'No tienes idea de cuán responsable era. ¡Oh, fue terrible!',
            ch: '你完全不知道我有多负责。哦，那真是太糟糕了！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_may9.mp3',
          dialog: {
            en: 'Sounds like a dream job.',
            sp: 'Suena como un trabajo soñado.',
            ch: '听起来像是梦想中的工作。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_annie15.mp3',
          dialog: {
            en: 'If only to work with the devs. Anyway, I was here for a few years.',
            sp: 'Si solo fuera para trabajar con los desarrolladores. De todos modos, estuve aquí unos años.',
            ch: '如果仅仅是为了和开发者们一起工作就好了。无论如何，我在这里待了几年。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_annie16.mp3',
          dialog: {
            en: 'When the company got acquired by Mindwave, well.',
            sp: 'Cuando la empresa fue adquirida por Mindwave, bueno.',
            ch: '当公司被 Mindwave 收购时，嗯。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_annie17.mp3',
          dialog: {
            en: 'I guess I decided to stick around.',
            sp: 'Supongo que decidí quedarme.',
            ch: '我想我决定留下来。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_may10.mp3',
          dialog: {
            en: 'Charming.',
            sp: 'Encantador.',
            ch: '迷人。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_annie18.mp3',
          dialog: {
            en: "Yup. You know what they say - you can't buy loyalty!",
            sp: 'Sí. Sabes lo que dicen: ¡no se puede comprar lealtad!',
            ch: '没错。你知道他们怎么说的——忠诚是买不来的！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/anniespast_may11.mp3',
          dialog: {
            en: "(chuckle) We'll see about that.",
            sp: '(risita) Ya veremos sobre eso.',
            ch: '(笑) 我们拭目以待。',
          },
        },
      ],
    },
    'story#10': {
      title: '10. Debts & Lessons',
      dps: 400,
      scenes: [
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_annie1.mp3',
          dialog: {
            en: 'Miss May.',
            sp: 'Señorita May.',
            ch: '梅小姐。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may1.mp3',
          dialog: {
            en: 'Yes?',
            sp: '¿Sí?',
            ch: '是的？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_annie2.mp3',
          dialog: {
            en: "It's unfair to only give my side of the story you know.",
            sp: 'Es injusto solo dar mi versión de la historia, ¿sabes?',
            ch: '只讲我的故事是不公平的，你知道的。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may2.mp3',
          dialog: {
            en: 'What do you mean?',
            sp: '¿Qué quieres decir?',
            ch: '你是什么意思？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_annie3.mp3',
          dialog: {
            en: "I laid my past bare for all to see! I think it's your turn.",
            sp: '¡He expuesto mi pasado para que todos lo vean! Creo que es tu turno.',
            ch: '我已经把过去的事都摊开给大家看了！现在该轮到你了。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may3.mp3',
          dialog: {
            en: "Oh no, I'd never do that.",
            sp: 'Oh no, nunca haría eso.',
            ch: '哦不，我绝不会这么做的。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_annie4.mp3',
          dialog: {
            en: 'Well you must. Besides, the hero will be more inclined to spend...',
            sp: 'Bueno, debes hacerlo. Además, el héroe estará más inclinado a gastar...',
            ch: '你必须这么做。而且，英雄们会更愿意花钱……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_annie5.mp3',
          dialog: {
            en: '..If they feel a closer connection to us.',
            sp: '..Si sienten una conexión más cercana con nosotros.',
            ch: '……如果他们感到与我们有更紧密的联系。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may4.mp3',
          dialog: {
            en: 'You make a convincing case. Very well...',
            sp: 'Haces un caso convincente. Muy bien...',
            ch: '你说服力很强。好吧……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may5.mp3',
          dialog: {
            en: 'Do you know much about Mindwave?',
            sp: '¿Sabes mucho sobre Mindwave?',
            ch: '你对 Mindwave 了解多少？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_annie6.mp3',
          dialog: {
            en: 'The company that acquired the game studio. They suck!',
            sp: '¡La empresa que adquirió el estudio de juegos. ¡Son terribles!',
            ch: '收购了游戏工作室的公司。他们太糟糕了！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may6.mp3',
          dialog: {
            en: "That's just your opinion, Annie.",
            sp: 'Esa es solo tu opinión, Annie.',
            ch: '那只是你的观点，安妮。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may7.mp3',
          dialog: {
            en: '...Anyway it goes like this.',
            sp: '...De todos modos, es así.',
            ch: '……无论如何，事情是这样的。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may8.mp3',
          dialog: {
            en: 'Mindwave Limited is a Chinese entertainment company.',
            sp: 'Mindwave Limited es una empresa de entretenimiento china.',
            ch: 'Mindwave Limited 是一家中国娱乐公司。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_annie7.mp3',
          dialog: {
            en: "They're huge.",
            sp: 'Son enormes.',
            ch: '他们非常庞大。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may9.mp3',
          dialog: {
            en: 'Aye. They have majority stakes in most of the games you play.',
            sp: 'Sí. Tienen la mayoría de las participaciones en la mayoría de los juegos que juegas.',
            ch: '是的。他们在你玩的绝大多数游戏中持有多数股份。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_annie8.mp3',
          dialog: {
            en: "Aren't they all just pencil pushers? Why games?",
            sp: '¿Acaso no son solo burócratas? ¿Por qué juegos?',
            ch: '他们不都是些只会搞文书工作的么？为什么会涉足游戏？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may10.mp3',
          dialog: {
            en: 'Games making incredible profits - even more than war.',
            sp: 'Los juegos generan beneficios increíbles, incluso más que la guerra.',
            ch: '游戏带来了巨额的利润——甚至比战争还要多。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may11.mp3',
          dialog: {
            en: 'Mindwave likes to have a claim to those profits.',
            sp: 'A Mindwave le gusta tener derecho a esos beneficios.',
            ch: 'Mindwave 喜欢获取这些利润的份额。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_annie9.mp3',
          dialog: {
            en: 'But what about the fun? What about the soul?',
            sp: '¿Pero qué pasa con la diversión? ¿Qué pasa con el alma?',
            ch: '但乐趣呢？灵魂呢？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may12.mp3',
          dialog: {
            en: "You can't monetize the soul, Annie. Many have tried.",
            sp: 'No se puede monetizar el alma, Annie. Muchos lo han intentado.',
            ch: '你无法将灵魂货币化，安妮。许多人尝试过。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may13.mp3',
          dialog: {
            en: 'Anyway, I was working my way up the accounting department.',
            sp: 'De todos modos, estaba ascendiendo en el departamento de contabilidad.',
            ch: '无论如何，我在会计部门逐步晋升。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may14.mp3',
          dialog: {
            en: 'I worked at Mindwave for 10 years. Balancing budgets, and cooking the books...',
            sp: 'Trabajé en Mindwave durante 10 años. Balanceando presupuestos y manipulando las cuentas...',
            ch: '我在 Mindwave 工作了 10 年。平衡预算和做假账……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may15.mp3',
          dialog: {
            en: 'But...',
            sp: 'Pero...',
            ch: '但是……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_annie10.mp3',
          dialog: {
            en: "But?! Don't leave me hanging!",
            sp: '¡¿Pero?! ¡No me dejes en suspenso!',
            ch: '但是？！别吊我胃口！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may16.mp3',
          dialog: {
            en: 'Ugh. This is a bit embarrassing....',
            sp: 'Ugh. Esto es un poco embarazoso....',
            ch: '唉，这有点尴尬……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_annie11.mp3',
          dialog: {
            en: 'Oh you HAVE to tell us now.',
            sp: 'Oh, ¡Tienes que contarnos ahora!',
            ch: '哦，你现在必须告诉我们。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may17.mp3',
          dialog: {
            en: 'I have a... bit of a gambling addiction, alright? You know those pachinko games?',
            sp: 'Tengo una... pequeña adicción al juego, ¿vale? ¿Conoces esos juegos de pachinko?',
            ch: '我有一点……赌博成瘾。你知道那些弹珠游戏吗？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_annie12.mp3',
          dialog: {
            en: 'Those japanese slot machines?',
            sp: '¿Esos tragamonedas japoneses?',
            ch: '那些日本的老虎机？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may18.mp3',
          dialog: {
            en: '... yeah. I got so caught up one time chasing a win at a parlor and I uh...',
            sp: 'Sí. Me atrapó tanto una vez persiguiendo una victoria en un salón y yo, eh...',
            ch: '……是的。我有一次在追逐胜利的过程中太过投入，结果我……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_annie13.mp3',
          dialog: {
            en: 'You...?',
            sp: '¿Tú...?',
            ch: '你……？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may19.mp3',
          dialog: {
            en: "Mmm... Let's just say I had access to the company card. And that I regret my actions.",
            sp: 'Mmm... Digamos que tenía acceso a la tarjeta de la empresa. Y que lamento mis acciones.',
            ch: '嗯……就说我能使用公司卡，并且对我的行为感到后悔。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_annie14.mp3',
          dialog: {
            en: 'You took money from the company card to gamble?',
            sp: '¿Tomaste dinero de la tarjeta de la empresa para apostar?',
            ch: '你用公司卡的钱去赌博了？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may20.mp3',
          dialog: {
            en: 'I said I regret it! Stealing from corporations is truly evil! The worst thing you can do!',
            sp: '¡Dije que lo lamento! ¡Robar a las corporaciones es verdaderamente malo! ¡Lo peor que puedes hacer!',
            ch: '我说了我后悔！从公司那里偷钱真的很恶劣！这是你能做的最糟糕的事！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may21.mp3',
          dialog: {
            en: 'Like punching a baby! Or downloading movies!',
            sp: '¡Como golpear a un bebé! ¡O descargar películas!',
            ch: '就像打小孩！或者下载电影！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may22.mp3',
          dialog: {
            en: '(sigh). Anyway, I got reassigned to the entertainment division.',
            sp: '(sigh). De todos modos, me reasignaron a la división de entretenimiento.',
            ch: '（叹气）无论如何，我被重新分配到娱乐部门。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may23.mp3',
          dialog: {
            en: 'And this project is my current assignment.',
            sp: 'Y este proyecto es mi tarea actual.',
            ch: '而这个项目是我当前的任务。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_annie15.mp3',
          dialog: {
            en: 'Kind of sad, May.',
            sp: 'Algo triste, May.',
            ch: '有点悲伤，梅。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may24.mp3',
          dialog: {
            en: 'That I stole from my employers?',
            sp: '¿Que robé a mis empleadores?',
            ch: '我偷了雇主的钱？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_annie16.mp3',
          dialog: {
            en: 'That you got caught!',
            sp: '¡Que te atraparon!',
            ch: '是你被抓了！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/mayspast_may25.mp3',
          dialog: {
            en: '....',
            sp: '....',
            ch: '……',
          },
        },
      ],
    },
    'story#11': {
      title: '11. The CEO',
      dps: 500,
      scenes: [
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_1.wav',
          dialog: {
            en: "Annie! Did you brief our hero on the company's new CEO?",
            sp: '¡Annie! ¿Informaste a nuestro héroe sobre el nuevo CEO de la empresa?',
            ch: 'Annie！你向我们的英雄介绍公司新任CEO了吗？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_2.wav',
          dialog: {
            en: "No... I don't think so..",
            sp: 'No... no lo creo...',
            ch: '没有……我不这么认为……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_3.wav',
          dialog: {
            en: 'Irresponsible. A strong leader is the core of any venture',
            sp: 'Irresponsable. Un líder fuerte es el núcleo de cualquier empresa',
            ch: '不负责任。一个强有力的领导者是任何事业的核心',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_3.wav',
          dialog: {
            en: 'Necessary to rally the troops. Now then, his credentials~',
            sp: 'Necesario para reunir a las tropas. Ahora bien, sus credenciales~',
            ch: '有必要集结部队。现在，让我们看看他的资历~',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_4.wav',
          dialog: {
            en: "Ah! Here it is! He's over 8 feet tall.",
            sp: '¡Ah! ¡Aquí está! Mide más de 8 pies de altura.',
            ch: '啊！在这里！他身高超过8英尺。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_5.wav',
          dialog: {
            en: 'Dark and handsome, too.',
            sp: 'Oscuro y apuesto, también.',
            ch: '高大帅气。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_6.wav',
          dialog: {
            en: 'A Psi-10 Ultrahuman.',
            sp: 'Un Ultrahumano Psi-10.',
            ch: '一个Psi-10超级人类。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_7.wav',
          dialog: {
            en: 'Total pseudoscience. But he probably is.',
            sp: 'Pseudociencia total. Pero probablemente lo es.',
            ch: '完全是伪科学。但他可能真的是。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_8.wav',
          dialog: {
            en: 'A Confucius Institute scholar.',
            sp: 'Un erudito del Instituto Confucio.',
            ch: '孔子学院的学者。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_9.wav',
          dialog: {
            en: 'A learned man.',
            sp: 'Un hombre sabio.',
            ch: '一个博学的人。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_10.wav',
          dialog: {
            en: 'Former Director of Special Projects at The Celeste Group',
            sp: 'Exdirector de Proyectos Especiales en The Celeste Group',
            ch: '前Celeste集团特别项目总监',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_11.wav',
          dialog: {
            en: 'Former Chief Impact Officer at Patriots Division.',
            sp: 'Exjefe de Impacto en la División de Patriotas.',
            ch: '前爱国者司令部首席影响官。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_12.wav',
          dialog: {
            en: 'Former Chief Diversity Officer at Deadeye Enforcement.',
            sp: 'Exjefe de Diversidad en Deadeye Enforcement.',
            ch: '前Deadeye Enforcement首席多元化官。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_13.wav',
          dialog: {
            en: 'Former Intern at Arotech Telecom.',
            sp: 'Exinterno en Arotech Telecom.',
            ch: '前Arotech Telecom实习生。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_14.wav',
          dialog: {
            en: 'Janitor at Lebenskraft Armorers Gigafactory.',
            sp: 'Conserje en Lebenskraft Armorers Gigafactory.',
            ch: '的Lebenskraft Armorers Gigafactory的清洁工。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_14.wav',
          dialog: {
            en: 'Kressanica.',
            sp: 'Kressanica.',
            ch: 'Kressanica',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_15.wav',
          dialog: {
            en: 'Wow! So much experience!',
            sp: '¡Guau! ¡Tanta experiencia!',
            ch: '哇！经验真丰富！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_16.wav',
          dialog: {
            en: "He's been to prison. Says here the charge was embezzlement...",
            sp: 'Ha estado en prisión. Dice aquí que el cargo fue malversación...',
            ch: '他进过监狱。这里说他被指控挪用公款……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/2.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_17.wav',
          dialog: {
            en: 'Also inappropriate use of corporate military assets, with a death toll >10,000.',
            sp: 'También uso inapropiado de activos militares corporativos, con una cifra de muertos superior a 10,000.',
            ch: '还不当使用公司军事资产，导致死亡人数超过1万。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_18.wav',
          dialog: {
            en: 'Even villains get their due.',
            sp: 'Incluso los villanos obtienen lo que les corresponde.',
            ch: '连反派也得到了应有的下场。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_19.wav',
          dialog: {
            en: 'After his two-month sentence at a minimum-security prison...',
            sp: 'Después de su condena de dos meses en una prisión de mínima seguridad...',
            ch: '在最低安全级别监狱服刑两个月后……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_20.wav',
          dialog: {
            en: 'He started a non-profit dedicated to the victims of corporate espionage.',
            sp: 'Fundó una organización sin fines de lucro dedicada a las víctimas del espionaje corporativo.',
            ch: '他成立了一个致力于公司间谍受害者的非营利组织。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_21.wav',
          dialog: {
            en: 'Reformed! As they say, you cannot be good without first being bad.',
            sp: '¡Reformado! Como dicen, no se puede ser bueno sin primero ser malo.',
            ch: '改过自新！俗话说，不先变坏就不能变好。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/2.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_22.wav',
          dialog: {
            en: 'Hm, it seems that he was also responsible for that incident in the middle east.',
            sp: 'Hm, parece que también fue responsable de ese incidente en el Medio Oriente.',
            ch: '嗯，他似乎也对中东的那起事件负有责任。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_23.wav',
          dialog: {
            en: 'Which one?',
            sp: '¿Cuál?',
            ch: '哪一个？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/2.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_24.wav',
          dialog: {
            en: 'Oh my. Did I say incident? I meant incidents. This list is a bit long.',
            sp: 'Oh, vaya. ¿Dije incidente? Quise decir incidentes. Esta lista es un poco larga.',
            ch: '哦，天啊。我说事件了吗？我指的是多起事件。这份名单有点长。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/2.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_25.wav',
          dialog: {
            en: "Some of these weren't even in the news...",
            sp: 'Algunos de estos ni siquiera estaban en las noticias...',
            ch: '其中一些甚至没有上新闻……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/2.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_26.wav',
          dialog: {
            en: "Aaand. I think that's it. Hey, May. Question: What does this stuff have to do with making video games?",
            sp: 'Yyy. Creo que eso es todo. Oye, May. Pregunta: ¿Qué tiene que ver esto con hacer videojuegos?',
            ch: '呃。我想就是这样。嘿，May。问题是：这些事情与制作视频游戏有什么关系？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/2.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_27.wav',
          dialog: {
            en: '...Video games?',
            sp: '...¿Videojuegos?',
            ch: '……视频游戏？',
          },
        },
      ],
    },
    'story#12': {
      title: '12. The Shadow War',
      dps: 1000,
      scenes: [
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_annie1.mp3',
          dialog: {
            en: 'Hey, May, have you heard of the Shadow War?',
            sp: 'Oye, May, ¿has oído hablar de la Guerra de las Sombras?',
            ch: '嘿，梅，你听说过影子战争吗？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_may1.mp3',
          dialog: {
            en: 'The what?',
            sp: '¿El qué?',
            ch: '什么？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_annie2.mp3',
          dialog: {
            en: "The Shadow War. It's an ongoing war, fought as covert conspiracy.",
            sp: 'La Guerra de las Sombras. Es una guerra en curso, librada como una conspiración encubierta.',
            ch: '影子战争。这是一场正在进行的战争，以隐秘的阴谋形式进行。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_annie3.mp3',
          dialog: {
            en: "It includes many of the most powerful corporations of our time, including Mindwave's own parent company, Sanzu Biomedical.",
            sp: 'Incluye muchas de las corporaciones más poderosas de nuestro tiempo, incluida la empresa matriz de Mindwave, Sanzu Biomedical.',
            ch: '它包括了我们时代许多最强大的公司，包括 Mindwave 自己的母公司，Sanzu Biomedical。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_annie4.mp3',
          dialog: {
            en: 'It is a twisted and tangled web of schemes:',
            sp: 'Es una red retorcida y enredada de conspiraciones:',
            ch: '这是一个扭曲而错综复杂的阴谋网络：',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_annie5.mp3',
          dialog: {
            en: 'Partially to contain the spread of a super-intelligent AI...',
            sp: 'Parcialmente para contener la propagación de una IA superinteligente...',
            ch: '部分是为了遏制超级智能AI的传播……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_annie6.mp3',
          dialog: {
            en: "...and partially to secure corporate hegemony once it's contained.",
            sp: '…y parcialmente para asegurar la hegemonía corporativa una vez que esté contenida.',
            ch: '……部分是为了在遏制之后确保公司霸权。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_may2.mp3',
          dialog: {
            en: '...',
            sp: '...',
            ch: '……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_may3.mp3',
          dialog: {
            en: 'Very interesting. How long did it take you to make this up?',
            sp: 'Muy interesante. ¿Cuánto tiempo te llevó inventarlo?',
            ch: '非常有趣。你花了多长时间编造这个？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_annie7.mp3',
          dialog: {
            en: "I didn't make it up! A reliable source told me about it.",
            sp: '¡No lo inventé! Una fuente confiable me habló de ello.',
            ch: '我没有编造！一个可靠的来源告诉我这个。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_may4.mp3',
          dialog: {
            en: 'And what source might that be?',
            sp: '¿Y qué fuente podría ser esa?',
            ch: '那是什么来源呢？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_annie8.mp3',
          dialog: {
            en: '(flustered, angry) A reliable one!',
            sp: '(confusa, enojada) ¡Una confiable!',
            ch: '(愤怒，恼火) 一个可靠的！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_may5.mp3',
          dialog: {
            en: "You read it on the internet, didn't you?",
            sp: 'Lo leíste en internet, ¿no?',
            ch: '你是在网上读到的，对吗？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_annie9.mp3',
          dialog: {
            en: "Yes... but that doesn't make the source unreliable!",
            sp: 'Sí... ¡pero eso no hace que la fuente sea poco confiable!',
            ch: '是的……但这并不使这个来源不可靠！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_may6.mp3',
          dialog: {
            en: 'Oh dear Annie.',
            sp: 'Oh querida Annie.',
            ch: '哦，亲爱的安妮。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_may6.mp3',
          dialog: {
            en: 'You should know...',
            sp: 'Deberías saber...',
            ch: '你应该知道……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_may7.mp3',
          dialog: {
            en: 'Every story on the internet is a work of falsehood.',
            sp: 'Cada historia en internet es una obra de falsedad.',
            ch: '互联网上的每个故事都是虚假的。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_may8.mp3',
          dialog: {
            en: 'Only fools take anything written there as fact.',
            sp: 'Solo los tontos toman lo escrito allí como un hecho.',
            ch: '只有傻瓜才会把那里写的东西当作事实。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_annie10.mp3',
          dialog: {
            en: "It's real! The shadow war is real! You'll see!",
            sp: '¡Es real! ¡La guerra en las sombras es real! ¡Ya verás!',
            ch: '这是真的！影子战争是真的！你会看到的！',
          },
        },
      ],
    },
    'story#13': {
      title: '13. Terrible Influencers',
      dps: 1400,
      scenes: [
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_1.wav',
          dialog: {
            en: 'Oh, hero. Are you around?',
            sp: 'Oh, héroe. ¿Estás por aquí?',
            ch: '哦，英雄。你在吗？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_2.wav',
          dialog: {
            en: 'Such pretense May.',
            sp: 'Qué pretensión, May.',
            ch: '多么虚伪，May。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_2.wav',
          dialog: {
            en: 'You know the hero could never ignore his darling assistants!',
            sp: 'Sabes que el héroe nunca podría ignorar a sus queridas asistentes!',
            ch: '你知道英雄永远不会忽视他亲爱的助手！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_3.wav',
          dialog: {
            en: "I suppose that's true. Heh.",
            sp: 'Supongo que eso es cierto. Je.',
            ch: '我想这是真的。呵呵。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_4.wav',
          dialog: {
            en: 'Anyway, I wanted to give a status report on our influencers.',
            sp: 'De todos modos, quería dar un informe de estado sobre nuestros influencers.',
            ch: '无论如何，我想提供一份关于我们影响者的状态报告。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_5.wav',
          dialog: {
            en: 'Oh I do so love status reports. Tell us the status!',
            sp: 'Oh, me encantan los informes de estado. ¡Cuéntanos el estado!',
            ch: '哦，我非常喜欢状态报告。告诉我们情况！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_6.wav',
          dialog: {
            en: 'Ahem. ',
            sp: 'Ejem.',
            ch: '嗯。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_6.wav',
          dialog: {
            en: 'After aggregating weekly social media performance...',
            sp: 'Después de agregar el rendimiento semanal de las redes sociales...',
            ch: '在汇总每周社交媒体表现后……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_7.wav',
          dialog: {
            en: 'Tabulating the monthly KPIs.',
            sp: 'Tabulando los KPI mensuales.',
            ch: '根据合同交付项',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_7.wav',
          dialog: {
            en: 'In accordance with contract deliverables...',
            sp: 'De acuerdo con los entregables del contrato...',
            ch: '计算每月KPI……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_8.wav',
          dialog: {
            en: 'And evaluating inbound referral metrics...',
            sp: 'Y evaluando las métricas de referencias entrantes...',
            ch: '并评估入站推荐指标……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_9.wav',
          dialog: {
            en: 'Spit it out already!',
            sp: '¡Suéltalo ya!',
            ch: '快点说出来！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_10.wav',
          dialog: {
            en: 'Well.',
            sp: 'Bueno.',
            ch: '好吧。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/2.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_11.wav',
          dialog: {
            en: 'Ahem. In summary, it seems our influencers have done less than nothing.',
            sp: 'Ejem. En resumen, parece que nuestros influencers han hecho menos que nada.',
            ch: '嗯。总的来说，看来我们的影响者做得还不如什么都不做。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/2.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_12.wav',
          dialog: {
            en: 'Less than nothing? Ehhhhh?! How is that even possible?!',
            sp: '¿Menos que nada? ¡Ehhhhh! ¿Cómo es eso posible?!',
            ch: '还不如什么都不做？哎？！这怎么可能？！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_13.wav',
          dialog: {
            en: 'It seems most of the people we hired were 20 somethings with no business experience.',
            sp: 'Parece que la mayoría de las personas que contratamos eran veinteañeros sin experiencia en negocios.',
            ch: '看来我们雇的大多数人都是20多岁、没有商业经验的人。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/2.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_14.wav',
          dialog: {
            en: 'In fact their posts about us were so bad we now have a worse reputation among the public.',
            sp: 'De hecho, sus publicaciones sobre nosotros fueron tan malas que ahora tenemos una peor reputación entre el público.',
            ch: '事实上，他们关于我们的帖子如此糟糕，以至于我们在公众中的声誉更差。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/2.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_15.wav',
          dialog: {
            en: 'I even found one showing off a rented sports car on their social media.',
            sp: 'Incluso encontré a uno presumiendo un auto deportivo alquilado en sus redes sociales.',
            ch: '我甚至发现其中一人在社交媒体上炫耀一辆租来的跑车。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/3.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_16.wav',
          dialog: {
            en: "My goodness... And they're still on company payroll?",
            sp: 'Dios mío... ¿Y todavía están en la nómina de la empresa?',
            ch: '天哪……他们还在公司的工资单上吗？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_17.wav',
          dialog: {
            en: 'Aye. I suppose good help really is hard to come by.',
            sp: 'Sí. Supongo que la buena ayuda realmente es difícil de conseguir.',
            ch: '是的。我想好帮手确实很难找到。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/2.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_18.wav',
          dialog: {
            en: 'I feel scammed! They came in on good word after all.',
            sp: '¡Me siento estafada! Después de todo, vinieron con buenas referencias.',
            ch: '我觉得自己被骗了！毕竟，他们是通过好评来的。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/3.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_19.wav',
          dialog: {
            en: 'Is there anything we can do? I say we fire them all right this second!',
            sp: '¿Hay algo que podamos hacer? ¡Digo que los despidamos a todos ahora mismo!',
            ch: '我们能做点什么吗？我说我们马上解雇他们！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_20.wav',
          dialog: {
            en: 'Not so hasty, Annie.',
            sp: 'No tan rápido, Annie.',
            ch: '别这么急，Annie。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_21.wav',
          dialog: {
            en: 'I have a solution.',
            sp: 'Tengo una solución.',
            ch: '我有一个解决办法。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/2.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_22.wav',
          dialog: {
            en: "You don't mean....?",
            sp: 'No querrás decir....?',
            ch: '你不是指……？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_23.wav',
          dialog: {
            en: "Oh yes. Hero, if you'll take a closer look here....",
            sp: 'Oh, sí. Héroe, si echas un vistazo más de cerca aquí....',
            ch: '哦，是的。英雄，如果你仔细看看这里……',
          },
        },
      ],
    },
    'story#14': {
      title: '14. Grindy But Generous',
      dps: 2000,
      scenes: [
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_may1.mp3',
          dialog: {
            en: 'You know, I am quite a proponent of the grind. But at this point it is rather ridiculous.',
            sp: 'Sabes, soy bastante partidaria del grind. Pero en este punto es bastante ridículo.',
            ch: '你知道，我非常支持磨练。但现在这点真是有些荒谬。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_annie1.mp3',
          dialog: {
            en: 'The game is grindy, but pretty generous with the free stuff.',
            sp: 'El juego es un grind, pero es bastante generoso con las cosas gratis.',
            ch: '游戏很耗时间，但在免费物品方面相当慷慨。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_annie2.mp3',
          dialog: {
            en: "I've never spent a penny on the game, and I just got my last piece of best-in-slot gear yesterday.",
            sp: 'Nunca he gastado un centavo en el juego, y ayer conseguí mi última pieza de equipo de mejor nivel.',
            ch: '我从未在游戏上花过一分钱，昨天我刚刚得到最后一件顶级装备。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_may2.mp3',
          dialog: {
            en: 'How many hours do you have in it?',
            sp: '¿Cuántas horas tienes en él?',
            ch: '你玩了多少小时？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_annie3.mp3',
          dialog: {
            en: '5615 hours since the closed pre-alpha. My progress carried over between versions.',
            sp: '5615 horas desde la pre-alpha cerrada. Mi progreso se mantuvo entre versiones.',
            ch: '自闭测封测以来已玩5615小时。我的进度在版本之间传递。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_may3.mp3',
          dialog: {
            en: 'Annie, the game has only been in development for a year.',
            sp: 'Annie, el juego solo ha estado en desarrollo durante un año.',
            ch: '安妮，游戏才开发了一年。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_may4.mp3',
          dialog: {
            en: "If you've clocked that many hours, you play this game for an average of...",
            sp: 'Si has acumulado tantas horas, juegas a este juego en promedio...',
            ch: '如果你玩了这么多小时，你平均每天玩这个游戏……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_may5.mp3',
          dialog: {
            en: '110 hours per week.',
            sp: '110 horas por semana.',
            ch: '每周110小时。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_may6.mp3',
          dialog: {
            en: "That's almost 16 hours per day.",
            sp: 'Eso son casi 16 horas por día.',
            ch: '这几乎是每天16小时。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_may7.mp3',
          dialog: {
            en: 'Do you even sleep?',
            sp: '¿Incluso duermes?',
            ch: '你还睡觉吗？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_annie4.mp3',
          dialog: {
            en: 'Not really, no.',
            sp: 'No realmente, no.',
            ch: '不太有。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/theshadowwar_may2.mp3',
          dialog: {
            en: '...',
            sp: '...',
            ch: '……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_annie4.mp3',
          dialog: {
            en: "In any case, I'm proof that you don't have to spend any money on this game.",
            sp: 'En cualquier caso, soy la prueba de que no necesitas gastar dinero en este juego.',
            ch: '无论如何，我证明了你不需要在这个游戏上花任何钱。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_annie4.mp3',
          dialog: {
            en: 'Dedication and skill pay off.',
            sp: 'La dedicación y la habilidad dan sus frutos.',
            ch: '奉献和技能得到了回报。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_may8.mp3',
          dialog: {
            en: "If you're willing to have no life, I guess.",
            sp: 'Si estás dispuesto a no tener vida, supongo.',
            ch: '如果你愿意没有生活的话，我想。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_may9.mp3',
          dialog: {
            en: 'Hero, might I recommend you use more microtransactions?',
            sp: 'Héroe, ¿podría recomendarte usar más microtransacciones?',
            ch: '英雄，我可以建议你使用更多的微交易吗？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_may10.mp3',
          dialog: {
            en: 'You can play for less than 16 hours a day that way.',
            sp: 'Puedes jugar menos de 16 horas al día de esa manera.',
            ch: '你可以这样玩不到16小时一天。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_annie5.mp3',
          dialog: {
            en: "Hey! That's cheating!",
            sp: '¡Hey! ¡Eso es hacer trampa!',
            ch: '嘿！那是作弊！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_may11.mp3',
          dialog: {
            en: 'We need players to buy microtransactions or else the studio goes broke, Annie.',
            sp: 'Necesitamos que los jugadores compren microtransacciones o el estudio quebrará, Annie.',
            ch: '我们需要玩家购买微交易，否则工作室会破产，安妮。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_annie6.mp3',
          dialog: {
            en: "Oh! You're right! Yes! Buy more microtransactions!",
            sp: '¡Oh! ¡Tienes razón! ¡Sí! ¡Compra más microtransacciones!',
            ch: '哦！你说得对！是的！买更多微交易！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/grindybutgenerous_annie7.mp3',
          dialog: {
            en: "But don't forget: The game is very generous with the free drops....",
            sp: 'Pero no olvides: El juego es muy generoso con las gotas gratis....',
            ch: '但别忘了：游戏在免费掉落方面非常慷慨……',
          },
        },
      ],
    },
    'story#15': {
      title: '15. From Damage to Credits',
      dps: 2500,
      scenes: [
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/fromdamagetocredits_annie1.mp3',
          dialog: {
            en: 'Hmmmm...',
            sp: 'Hmmmm...',
            ch: '嗯……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/fromdamagetocredits_annie2.mp3',
          dialog: {
            en: 'urgh...',
            sp: 'urgh...',
            ch: '唔……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/fromdamagetocredits_annie3.mp3',
          dialog: {
            en: '(Frustrated noises)',
            sp: '(Sonidos de frustración)',
            ch: '(沮丧的声音)',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/fromdamagetocredits_annie4.mp3',
          dialog: {
            en: "This isn't working!!!!",
            sp: '¡Esto no está funcionando!!!!',
            ch: '这没用！！！！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/fromdamagetocredits_may1.mp3',
          dialog: {
            en: 'Uh, Annie?',
            sp: '¿Eh, Annie?',
            ch: '呃，安妮？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/fromdamagetocredits_annie5.mp3',
          dialog: {
            en: '(sound of items being scattered around a room)',
            sp: '(sonido de objetos esparciéndose por una habitación)',
            ch: '(房间里物品散落的声音)',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/fromdamagetocredits_annie6.mp3',
          dialog: {
            en: "Our progression, May! The hero isn't doing enough damage!",
            sp: '¡Nuestra progresión, May! ¡El héroe no está haciendo suficiente daño!',
            ch: '我们的进展，梅！英雄造成的伤害不够！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/fromdamagetocredits_may2.mp3',
          dialog: {
            en: "Oh. Well isn't that the point?",
            sp: 'Oh. Bueno, ¿no es ese el punto?',
            ch: '哦。那不是重点吗？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/fromdamagetocredits_may3.mp3',
          dialog: {
            en: 'We have to artificially inflate play time. We need to be boosting vanity metrics.',
            sp: 'Tenemos que inflar artificialmente el tiempo de juego. Necesitamos aumentar las métricas de vanidad.',
            ch: '我们需要人为地增加游戏时间。我们需要提升虚荣指标。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/fromdamagetocredits_may4.mp3',
          dialog: {
            en: 'Numbersmaxxing our followers. Pumping our socials.',
            sp: 'Maximizando números de nuestros seguidores. Impulsando nuestras redes sociales.',
            ch: '最大化我们的关注者数量。提升我们的社交媒体。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/fromdamagetocredits_annie7.mp3',
          dialog: {
            en: 'None of that will happen at this rate. We need something more.',
            sp: 'Nada de eso sucederá a este ritmo. Necesitamos algo más.',
            ch: '在这种情况下这些都不会发生。我们需要更多的东西。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/fromdamagetocredits_may5.mp3',
          dialog: {
            en: 'Black market drugs?',
            sp: '¿Drogas del mercado negro?',
            ch: '黑市药品？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/fromdamagetocredits_annie8.mp3',
          dialog: {
            en: 'No.',
            sp: 'No.',
            ch: '不。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/fromdamagetocredits_may6.mp3',
          dialog: {
            en: 'Well, there IS something we could do...',
            sp: 'Bueno, hay algo que podríamos hacer...',
            ch: '嗯，我们可以做点什么……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/fromdamagetocredits_annie9.mp3',
          dialog: {
            en: 'Yes?',
            sp: '¿Sí?',
            ch: '是吗？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/fromdamagetocredits_may7.mp3',
          dialog: {
            en: 'Indeed. See, the main problem we have is our output.',
            sp: 'De hecho. Verás, el principal problema que tenemos es nuestra salida.',
            ch: '确实如此。你看，我们面临的主要问题是我们的输出。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/fromdamagetocredits_may8.mp3',
          dialog: {
            en: 'Dealing more damage is only going to get us so far.',
            sp: 'Hacer más daño solo nos llevará hasta cierto punto.',
            ch: '造成更多伤害只是能让我们走到这一步。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/fromdamagetocredits_annie10.mp3',
          dialog: {
            en: 'What do you mean?',
            sp: '¿Qué quieres decir?',
            ch: '你是什么意思？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/fromdamagetocredits_may9.mp3',
          dialog: {
            en: "I mean we're maximizing for the wrong thing.",
            sp: 'Quiero decir que estamos maximizando por lo incorrecto.',
            ch: '我的意思是我们在最大化错误的东西。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/fromdamagetocredits_may10.mp3',
          dialog: {
            en: 'Instead of damage, we should be earning money.',
            sp: 'En lugar de daño, deberíamos estar ganando dinero.',
            ch: '与其造成伤害，我们应该赚取金钱。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/fromdamagetocredits_annie11.mp3',
          dialog: {
            en: "What's the point of that?",
            sp: '¿Cuál es el punto de eso?',
            ch: '那有什么意义？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/fromdamagetocredits_may11.mp3',
          dialog: {
            en: 'Money is the universal solvent. With money, you can get anything.',
            sp: 'El dinero es el solvente universal. Con dinero, puedes conseguir cualquier cosa.',
            ch: '金钱是万能的溶剂。只要有钱，你就能得到任何东西。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/fromdamagetocredits_may12.mp3',
          dialog: {
            en: 'Including funding future games and titles.',
            sp: 'Incluyendo financiar futuros juegos y títulos.',
            ch: '包括资助未来的游戏和标题。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/fromdamagetocredits_annie12.mp3',
          dialog: {
            en: 'Future games?',
            sp: '¿Juegos futuros?',
            ch: '未来的游戏？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/fromdamagetocredits_may13.mp3',
          dialog: {
            en: 'Aye. If we amass enough capital, we can turn this company around!',
            sp: 'Sí. Si acumulamos suficiente capital, ¡podemos darle la vuelta a esta empresa!',
            ch: '是的。如果我们积累足够的资金，我们可以扭转这家公司！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/fromdamagetocredits_may14.mp3',
          dialog: {
            en: 'Dare I say it, we could even make a Pay 3 Win.',
            sp: 'O dare decirlo, podríamos incluso hacer un Pay 3 Win.',
            ch: '我敢说，我们甚至可以做一个付费赢。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/fromdamagetocredits_annie13.mp3',
          dialog: {
            en: 'Oh, wow! Then we can include all the original ideas we wanted!',
            sp: '¡Oh, wow! ¡Entonces podemos incluir todas las ideas originales que queríamos!',
            ch: '哦，哇！那样我们就可以包含所有我们想要的原创想法！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/fromdamagetocredits_may15.mp3',
          dialog: {
            en: 'Uhh... yeah. Sure. So, what do you say?',
            sp: 'Uh... sí. Claro. Entonces, ¿qué dices?',
            ch: '呃……是的。好的。那么，你说呢？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/fromdamagetocredits_annie14.mp3',
          dialog: {
            en: "Let's do it! Let's make some money!",
            sp: '¡Hagámoslo! ¡Hagamos algo de dinero!',
            ch: '干吧！我们赚点钱！',
          },
        },
      ],
    },
    'story#16': {
      title: '16. Corporate Philosophy',
      dps: 5000,
      scenes: [
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/corporatephilosophy_may1.mp3',
          dialog: {
            en: "Annie, do you know the company's corporate philosophy?",
            sp: '¿Annie, conoces la filosofía corporativa de la empresa?',
            ch: '安妮，你知道公司的企业哲学吗？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/corporatephilosophy_may2.mp3',
          dialog: {
            en: 'We should let the hero know about our core values.',
            sp: 'Deberíamos hacerle saber al héroe sobre nuestros valores fundamentales.',
            ch: '我们应该让英雄知道我们的核心价值观。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/corporatephilosophy_annie1.mp3',
          dialog: {
            en: 'Yes! We will do our best...',
            sp: '¡Sí! Haremos nuestro mejor esfuerzo...',
            ch: '是的！我们将尽全力……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/corporatephilosophy_annie2.mp3',
          dialog: {
            en: 'To make competitive multiplayer games...',
            sp: 'Para hacer juegos multijugador competitivos...',
            ch: '制作有竞争性的多人游戏……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/corporatephilosophy_annie3.mp3',
          dialog: {
            en: '...which are fun despite the high stakes...',
            sp: '...que sean divertidos a pesar de las altas apuestas...',
            ch: '...尽管风险很高，但仍然有趣……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/corporatephilosophy_annie4.mp3',
          dialog: {
            en: '... which allow only the most skilled players to win-',
            sp: '...que permitan solo a los jugadores más hábiles ganar-',
            ch: '...只允许最熟练的玩家获胜——',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/corporatephilosophy_may3.mp3',
          dialog: {
            en: "No, no. Where did you get that? That's all wrong.",
            sp: 'No, no. ¿De dónde sacaste eso? Está todo mal.',
            ch: '不，不。你从哪里得到的？这些都错了。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/corporatephilosophy_annie5.mp3',
          dialog: {
            en: "No it's not! That's the founding philosophy!",
            sp: '¡No es así! ¡Esa es la filosofía fundacional!',
            ch: '不是的！那是创始哲学！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/corporatephilosophy_may4.mp3',
          dialog: {
            en: 'We have a new CEO, Annie. A new CEO means a new corporate philosophy.',
            sp: 'Tenemos un nuevo CEO, Annie. Un nuevo CEO significa una nueva filosofía corporativa.',
            ch: '我们有了新的CEO，安妮。新的CEO意味着新的企业哲学。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/corporatephilosophy_may5.mp3',
          dialog: {
            en: 'Did you even consult your corporate handbook?',
            sp: '¿Consultaste tu manual corporativo?',
            ch: '你有没有查看你的公司手册？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/corporatephilosophy_may6.mp3',
          dialog: {
            en: 'The memo we sent to your inbox?',
            sp: '¿El memo que enviamos a tu bandeja de entrada?',
            ch: '我们发到你收件箱的备忘录？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/corporatephilosophy_annie6.mp3',
          dialog: {
            en: '... no. I tried to get my handbook from HR...',
            sp: '... no. Intenté obtener mi manual de RRHH...',
            ch: '...没有。我试图从HR那里获取我的手册……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/corporatephilosophy_annie7.mp3',
          dialog: {
            en: "...but their serpentine features were too scary. I'm afraid of snakes, you know.",
            sp: '...pero sus características serpenteantes eran demasiado aterradoras. Tengo miedo a las serpientes, sabes.',
            ch: '...但他们的蛇形特征太可怕了。我害怕蛇，你知道的。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/corporatephilosophy_may7.mp3',
          dialog: {
            en: '(*sigh)*. Alright. I suppose I can consult the handbook.',
            sp: '(*suspiro)*. Está bien. Supongo que puedo consultar el manual.',
            ch: '（叹气）好吧。我想我可以查阅手册。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/corporatephilosophy_may8.mp3',
          dialog: {
            en: 'Ahem. Page 862 of the corporate handbook.',
            sp: 'Ahem. Página 862 del manual corporativo.',
            ch: '咳咳。企业手册第862页。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/corporatephilosophy_may9.mp3',
          dialog: {
            en: '"Drawing from the success of our CEO\'s merger...',
            sp: '"Basado en el éxito de la fusión de nuestro CEO...',
            ch: '“借鉴我们CEO合并的成功……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/corporatephilosophy_may10.mp3',
          dialog: {
            en: '"Between Bebesi Co and the East Palestine Chemical Sanitation Company...',
            sp: '"Entre Bebesi Co y la East Palestine Chemical Sanitation Company...',
            ch: '“在Bebesi Co和东巴勒斯坦化学清洁公司之间……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/corporatephilosophy_may11.mp3',
          dialog: {
            en: '"...Whereby our noble CEO managed to turn a hazardous chemical spill...',
            sp: '"...Donde nuestro noble CEO logró convertir un derrame químico peligroso...',
            ch: '“……我们的高贵CEO设法将有害化学品泄漏……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/corporatephilosophy_may12.mp3',
          dialog: {
            en: '"... into an opportunity to sell a new soft drink to the masses...',
            sp: '"... en una oportunidad para vender una nueva bebida a las masas...',
            ch: '……成为向大众销售新软饮的机会……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/corporatephilosophy_may13.mp3',
          dialog: {
            en: '"...The corporate philosophy is thus:"',
            sp: '"...La filosofía corporativa es la siguiente:"',
            ch: '“……企业哲学如下：',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/corporatephilosophy_may14.mp3',
          dialog: {
            en: "'Laws do not exist, and our customers deserve to die.'",
            sp: "'Las leyes no existen, y nuestros clientes merecen morir.'",
            ch: '‘法律不存在，我们的客户应该死。’',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/corporatephilosophy_annie8.mp3',
          dialog: {
            en: 'Wow! So inspirational!',
            sp: '¡Guau! ¡Tan inspirador!',
            ch: '哇！真是鼓舞人心！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/story/audios/corporatephilosophy_annie9.mp3',
          dialog: {
            en: 'Do you think the CEO will manage to pull off a clever play like that again to save the studio?',
            sp: '¿Crees que el CEO podrá hacer otra jugada inteligente para salvar el estudio?',
            ch: '你认为CEO会再次施展巧妙的手段来拯救工作室吗？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/corporatephilosophy_may15.mp3',
          dialog: {
            en: 'Great Scott! How did this end up in here?',
            sp: '¡Gran Scott! ¿Cómo acabó esto aquí?',
            ch: '天啊！这怎么会出现在这里？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/story/audios/corporatephilosophy_may16.mp3',
          dialog: {
            en: 'What will the shareholders think! What will we do if the press gets ahold of this?',
            sp: '¡Qué pensarán los accionistas! ¿Qué haremos si la prensa se entera de esto?',
            ch: '股东们会怎么看！如果媒体得知此事我们该怎么办？',
          },
        },
      ],
    },
    'story#17': {
      title: '17. Upgrade - Karmic Probabilities',
      dps: 10000,
      scenes: [
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_1.wav',
          dialog: {
            en: 'Oh hero - there you are.',
            sp: 'Oh héroe, ahí estás.',
            ch: '哦，英雄，你在这里。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_2.wav',
          dialog: {
            en: "I think they're diligently focused on the task at hand.",
            sp: 'Creo que están enfocados diligentemente en la tarea actual.',
            ch: '我认为他们正在努力专注于手头的任务。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_3.wav',
          dialog: {
            en: "Aye. But I think it's time we help them out a bit.",
            sp: 'Sí. Pero creo que es hora de que les ayudemos un poco.',
            ch: '是的。但我认为是时候帮助他们一些了。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_4.wav',
          dialog: {
            en: "Let's do it! Hero, do you know about donating?",
            sp: '¡Hagámoslo! Héroe, ¿sabes sobre donar?',
            ch: '我们来做吧！英雄，你知道捐赠吗？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_5.wav',
          dialog: {
            en: 'Every company does it.',
            sp: 'Cada empresa lo hace.',
            ch: '每家公司都这么做。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_6.wav',
          dialog: {
            en: "It's part of the Seeker teachings!",
            sp: '¡Es parte de las enseñanzas del Buscador!',
            ch: '这是探索者教导的一部分！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_7.wav',
          dialog: {
            en: 'The universe rewards the faithful and virtuous, you know.',
            sp: 'El universo recompensa a los fieles y virtuosos, ya sabes.',
            ch: '你知道，宇宙会奖赏忠诚和有德的人。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_8.wav',
          dialog: {
            en: '*sigh*',
            sp: '*suspiro*',
            ch: '*叹气*',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_9.wav',
          dialog: {
            en: "What are you sighing about?! You can't disagree with this one, May!",
            sp: '¿De qué suspiras?! No puedes estar en desacuerdo con esto, ¡May!',
            ch: '你在叹什么气？！你不能不同意这一点，May！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_10.wav',
          dialog: {
            en: 'The **science** backs it!',
            sp: '¡La **ciencia** lo respalda!',
            ch: '科学支持它！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_11.wav',
          dialog: {
            en: "Annie has a way of embellishing things, but she's partially correct.",
            sp: 'Annie tiene una forma de embellecer las cosas, pero en parte tiene razón.',
            ch: 'Annie有一种美化事物的方式，但她部分正确。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_12.wav',
          dialog: {
            en: 'Empirical studies do show that companies which partake in charitable deeds',
            sp: 'Los estudios empíricos muestran que las empresas que participan en actos caritativos',
            ch: '经验研究显示，参与慈善事',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_12.wav',
          dialog: {
            en: 'have better financial performance.',
            sp: 'tienen un mejor desempeño financiero.',
            ch: '事业的公司有更好的财务表现。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_13.wav',
          dialog: {
            en: 'The difference is small, but enough to matter.',
            sp: 'La diferencia es pequeña, pero suficiente para importar.',
            ch: '差异很小，但足以引起注意。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_14.wav',
          dialog: {
            en: 'The Seekers proved it! They know everything!',
            sp: '¡Los Buscadores lo probaron! ¡Ellos lo saben todo!',
            ch: '探索者证明了这一点！他们什么都知道！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_15.wav',
          dialog: {
            en: 'The Seekers?',
            sp: '¿Los Buscadores?',
            ch: '探索者？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_16.wav',
          dialog: {
            en: "The Seekers. They're only the biggest church in the world, May.",
            sp: 'Los Buscadores. Son solo la iglesia más grande del mundo, May.',
            ch: '探索者。他们只是世界上最大的教堂，May。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_17.wav',
          dialog: {
            en: 'Oh, that odd establishment. The one full of fanatics.',
            sp: 'Oh, ese extraño establecimiento. El que está lleno de fanáticos.',
            ch: '哦，那个奇怪的机构。那个充满狂热者的机构。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_18.wav',
          dialog: {
            en: 'Devout followers, May. Devout.',
            sp: 'Devotos seguidores, May. Devotos.',
            ch: '虔诚的信徒，May。虔诚的。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_19.wav',
          dialog: {
            en: "I don't really have an opinion on new age spirituality.",
            sp: 'Realmente no tengo una opinión sobre la espiritualidad de la nueva era.',
            ch: '我对新时代的灵性没有什么看法。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_20.wav',
          dialog: {
            en: 'Though I suppose that research on charity *was* funded by the Seekers.',
            sp: 'Aunque supongo que la investigación sobre la caridad *fue* financiada por los Buscadores.',
            ch: '虽然我猜想那项关于慈善的研究是由探索者资助的。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_21.wav',
          dialog: {
            en: "I don't know if that's a good thing, though.",
            sp: 'Aunque no sé si eso es algo bueno.',
            ch: '不过我不知道这是不是一件好事。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_22.wav',
          dialog: {
            en: 'People have *always* done this stuff. Philanthropy is a practice as old as civilization.',
            sp: 'La gente *siempre* ha hecho estas cosas. La filantropía es una práctica tan antigua como la civilización.',
            ch: '人们*一直*在做这些事情。慈善是一种与文明一样古老的实践。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_23.wav',
          dialog: {
            en: 'However, the Seekers, with their genius ethicists and moralists...',
            sp: 'Sin embargo, los Buscadores, con sus genios éticos y moralistas...',
            ch: '然而，探索者和他们的天才伦理学家和道德家......',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_24.wav',
          dialog: {
            en: 'They have refined morality to a science.',
            sp: 'Han refinado la moralidad a una ciencia.',
            ch: '他们已将道德提炼成一门科学。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_26.wav',
          dialog: {
            en: '...and *exactly* how to get the most karma from your dollars!',
            sp: '...y *exactamente* cómo obtener el máximo karma de tus dólares!',
            ch: '...以及*确切*地如何从你的美元中获得最多的因果报应！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_27.wav',
          dialog: {
            en: 'Annie, nobody uses the dollar anymore.',
            sp: 'Annie, ya nadie usa el dólar.',
            ch: 'Annie，现在没有人再使用美元了。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_28.wav',
          dialog: {
            en: 'Sure, whatever. Anyways, you can go check out the paper for yourself.',
            sp: 'Claro, como sea. De todos modos, puedes ir a verificar el documento por ti mismo.',
            ch: '当然，随便。无论如何，你可以亲自去查看论文。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_29.wav',
          dialog: {
            en: 'It pioneered the field of metaphysical statistics.',
            sp: 'Fue pionero en el campo de las estadísticas metafísicas.',
            ch: '它开创了形而上学统计学领域。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_30.wav',
          dialog: {
            en: "And it's never been replicated, or published in any serious journal.",
            sp: 'Y nunca ha sido replicado, ni publicado en ninguna revista seria.',
            ch: '而且从未在任何正规期刊中复制或发表过。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_31.wav',
          dialog: {
            en: "...What does 'metaphysical statistics' even mean, anyways?",
            sp: "...¿Qué significa 'estadísticas metafísicas' de todos modos?",
            ch: '...“形而上学统计”到底是什么意思呢？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_32.wav',
          dialog: {
            en: '*Whatever*. Stuff like replication or peer review...',
            sp: '*Como sea*. Cosas como la replicación o la revisión por pares...',
            ch: '*无论如何*。像复制或同行评审这样的事情......',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_33.wav',
          dialog: {
            en: '...would just undermine the importance of faith in this matter.',
            sp: '...solo socavaría la importancia de la fe en este asunto.',
            ch: '...只会削弱这件事中信仰的重要性。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_34.wav',
          dialog: {
            en: 'Anyways! Hero! There should be a new button on your dashboard.',
            sp: '¡De todos modos! ¡Héroe! Debería haber un nuevo botón en tu tablero.',
            ch: '无论如何！英雄！你的仪表板上应该有一个新按钮。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_35.wav',
          dialog: {
            en: 'You can use it to spend a bit of money and commit a single virtuous deed.',
            sp: 'Puedes usarlo para gastar un poco de dinero y cometer un acto virtuoso único.',
            ch: '你可以使用它花一点钱并做出一个单一的善行。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_36.wav',
          dialog: {
            en: 'This virtuous deed will increase your throughput for a short time.',
            sp: 'Este acto virtuoso aumentará tu productividad durante un corto período de tiempo.',
            ch: '这个善行将在短时间内增加你的吞吐量。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_37.wav',
          dialog: {
            en: 'Until the karma expires. Hehe.',
            sp: 'Hasta que el karma expire. Jeje.',
            ch: '直到因果报应消失。呵呵。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_38.wav',
          dialog: {
            en: 'Use it often. And remember:',
            sp: 'Úsalo a menudo. Y recuerda:',
            ch: '经常使用它。并记住：',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 39.wav',
          dialog: {
            en: 'One virtuous act per day can be the difference between you finding love and wealth, or dying alone and sad.',
            sp: 'Un acto virtuoso por día puede ser la diferencia entre encontrar amor y riqueza, o morir solo y triste.',
            ch: '每天一个善行可能是你找到爱和财富或孤独悲伤死亡之间的区别。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 40.wav',
          dialog: {
            en: "The difference isn't *that* big, Annie.",
            sp: 'La diferencia no es *tan* grande, Annie.',
            ch: '差异并不*那么*大，Annie。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 41.wav',
          dialog: {
            en: 'But hero, our financial models indicate that you should press the button whenever you can.',
            sp: 'Pero héroe, nuestros modelos financieros indican que deberías presionar el botón siempre que puedas.',
            ch: '但是英雄，我们的财务模型表明你应该尽可能地按下按钮。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 42.wav',
          dialog: {
            en: 'The spreadsheets never lie, and neither do the Seekers!',
            sp: 'Las hojas de cálculo nunca mienten, ¡y los Buscadores tampoco!',
            ch: '电子表格永远不会撒谎，探索者也不会！',
          },
        },
      ],
    },
    'story#18': {
      title: '18. The Great Fugazi',
      dps: 20000,
      scenes: [
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_1.wav',
          dialog: {
            en: '(yawning) Ugh, good morning...',
            sp: '(bostezando) Ugh, buenos días...',
            ch: '（打哈欠）呃，早上好……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_2.wav',
          dialog: {
            en: "Rise and shine May, we have a day's work ahead of us.",
            sp: 'Levántate y brilla May, tenemos un día de trabajo por delante.',
            ch: '起床了，May，我们有一天的工作在前面。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_3.wav',
          dialog: {
            en: "Not before I've had my coffee...",
            sp: 'No antes de haber tomado mi café...',
            ch: '在我喝咖啡之前不行……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_4.wav',
          dialog: {
            en: "Coffee or not, this market won't wait! Take a look at this piece of news.",
            sp: 'Con café o sin él, ¡este mercado no espera! Echa un vistazo a esta noticia.',
            ch: '无论有没有咖啡，这个市场都不会等待！看看这条新闻。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_5.wav',
          dialog: {
            en: "(groggy) Erm, let's see...",
            sp: '(aturdido) Ehm, vamos a ver...',
            ch: '（昏昏欲睡）呃，我们看看……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_6.wav',
          dialog: {
            en: 'Popular VR action game claimed over 1 trillion downloads....',
            sp: 'Popular juego de acción en VR reclamó más de 1 billón de descargas...',
            ch: '流行的 VR 动作游戏声称超过 1 万亿次下载……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_6.wav',
          dialog: {
            en: 'company CEO was later indicted for publishing false statistics.',
            sp: 'el CEO de la compañía fue posteriormente acusado de publicar estadísticas falsas.',
            ch: '公司 CEO 后来因发布虚假统计数据而被起诉。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_7.wav',
          dialog: {
            en: 'Well that certainly makes sense. A trillion downloads? Who would believe that?',
            sp: 'Bueno, eso ciertamente tiene sentido. ¿Un billón de descargas? ¿Quién lo creería?',
            ch: '嗯，这当然有道理。一万亿次下载？谁会相信？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_8.wav',
          dialog: {
            en: "It's what I see all those VR games claiming these days.",
            sp: 'Es lo que veo que todos esos juegos de VR reclaman estos días.',
            ch: '这是我这些天看到的所有 VR 游戏都在声称的。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_9.wav',
          dialog: {
            en: "They fill their servers with AI bots and say they're in high demand.",
            sp: 'Llenan sus servidores con bots de IA y dicen que están en alta demanda.',
            ch: '他们用 AI 机器人填充他们的服务器，并说他们需求很高。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_10.wav',
          dialog: {
            en: "The only demands they're really getting is investors demanding refunds.",
            sp: 'Lo único que realmente están obteniendo son inversores que exigen reembolsos.',
            ch: '他们真正得到的唯一要求是投资者要求退款。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_11.wav',
          dialog: {
            en: "It's a phony! One big lie! The great fugazi!",
            sp: '¡Es un farsante! ¡Una gran mentira! ¡El gran fugazi!',
            ch: '这是个骗子！一个大谎言！伟大的fugazi！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 12.wav',
          dialog: {
            en: 'Honestly May, I would have thought you to be a fan of this sort of thing.',
            sp: 'Honestamente May, habría pensado que serías fanática de este tipo de cosas.',
            ch: '老实说 May，我本以为你会是这类事情的粉丝。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions 1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 13.wav',
          dialog: {
            en: 'What thing?',
            sp: '¿Qué cosa?',
            ch: '什么事？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions 1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 14.wav',
          dialog: {
            en: 'Oh you know - charts that go up and to the right, and all that.',
            sp: 'Oh, ya sabes: gráficos que suben y hacia la derecha, y todo eso.',
            ch: '哦，你知道的 - 向上和向右的图表，诸如此类。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions 1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 15.wav',
          dialog: {
            en: "Aye. But only if there's an alibi. But this?... ",
            sp: 'Sí. Pero solo si hay una coartada. Pero esto?... ',
            ch: '是的。但只有在有不在场证明的情况下。但这个？...',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions 1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 15.wav',
          dialog: {
            en: 'They make it much too obvious. Strategic error.',
            sp: 'Lo hacen demasiado obvio. Error estratégico.',
            ch: '他们让它太明显了。战略错误。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions 1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 16.wav',
          dialog: {
            en: '(giggles) Oh May.',
            sp: '(risitas) Oh May.',
            ch: '（吃吃笑）哦，May。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions 1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 17.wav',
          dialog: {
            en: 'What?',
            sp: '¿Qué?',
            ch: '什么？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions 1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 18.wav',
          dialog: {
            en: "You've got a heart after all!",
            sp: '¡Después de todo, tienes corazón!',
            ch: '你毕竟还是有心的！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions 1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 19.wav',
          dialog: {
            en: "Don't tease me now...",
            sp: 'No me molestes ahora...',
            ch: '现在别逗我……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions 1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 20.wav',
          dialog: {
            en: 'Hehe. Let me get you that coffee...',
            sp: 'Jeje. Déjame conseguirte ese café...',
            ch: '呵呵。让我给你拿咖啡……',
          },
        },
      ],
    },
    'story#19': {
      title: '19. The Retail Crash of 2034',
      dps: 30000,
      scenes: [
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_1.wav',
          dialog: {
            en: 'Ho hum.',
            sp: 'Ho hum.',
            ch: '唉。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_2.wav',
          dialog: {
            en: 'Down in the dumps are we?',
            sp: '¿Estamos decaídos?',
            ch: '我们情绪低落吗？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_3.wav',
          dialog: {
            en: "Don't use that word, Annie. It's insensitive.",
            sp: 'No uses esa palabra, Annie. Es insensible.',
            ch: '不要用那个词，Annie。这是不敏感的。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_4.wav',
          dialog: {
            en: 'Dump? Why ever not...',
            sp: '¿Volcar? ¿Por qué no...',
            ch: '倾倒？为什么不呢...',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_5.wav',
          dialog: {
            en: "You said it again! We're going to get cancelled at this rate...",
            sp: '¡Lo has dicho de nuevo! A este paso nos van a cancelar...',
            ch: '你又说了！以这种速度，我们会被取消的...',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_6.wav',
          dialog: {
            en: "Am I missing something? It's just a word.",
            sp: '¿Me estoy perdiendo de algo? Es solo una palabra.',
            ch: '我错过了什么吗？这只是一个词。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 7.wav',
          dialog: {
            en: '(sigh) In accordance with Corporate Rules of Conduct Page 8 Subsection B13...',
            sp: '(suspiro) De acuerdo con las Normas de Conducta Corporativa Página 8 Subsección B13...',
            ch: '（叹气）根据公司行为规范第8页B13小节...',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 8.wav',
          dialog: {
            en: 'All price-negative parlance and trades are to be strictly controlled. This includes...',
            sp: 'Toda la jerga y transacciones con efectos negativos en el precio deben ser estrictamente controladas. Esto incluye...',
            ch: '所有价格负面的说法和交易都必须严格控制。这包括...',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 9.wav',
          dialog: {
            en: "Words and phrases such as 'dump', 'dump it', 'sell the news',",
            sp: "Palabras y frases como 'volcar', 'vóltalo', 'vende la noticia',",
            ch: "词语和短语，如'倾倒'、'倾倒它'、'卖出新闻'，",
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 10.wav',
          dialog: {
            en: 'In addition to activities such as short selling, negative delta positions, and put options.',
            sp: 'Además de actividades como la venta en corto, posiciones delta negativas y opciones de venta.',
            ch: '以及卖空、负增量头寸和看跌期权等活动。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 11.wav',
          dialog: {
            en: "(yawn) What's the point of all that anyway?",
            sp: '(bostezo) ¿Cuál es el sentido de todo eso de todos modos?',
            ch: '（打哈欠）那一切的意义是什么呢？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 12.wav',
          dialog: {
            en: "Oh, you really don't know your history do you? Allow me to explain.",
            sp: 'Oh, realmente no conoces tu historia, ¿verdad? Permíteme explicar.',
            ch: '哦，你真的不了解你的历史，对吗？让我来解释一下。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 13.wav',
          dialog: {
            en: 'Many years ago in 2034, the entire global stock market experienced a flash crash.',
            sp: 'Hace muchos años, en 2034, todo el mercado bursátil mundial experimentó un crash repentino.',
            ch: '许多年前的2034年，整个全球股市经历了一次闪崩。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 14.wav',
          dialog: {
            en: "Doesn't that happen all the time?",
            sp: '¿No ocurre eso todo el tiempo?',
            ch: '那不是一直都在发生吗？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 15.wav',
          dialog: {
            en: 'Oh no, this was very different.',
            sp: 'Oh no, esto fue muy diferente. ',
            ch: '哦不，这次非常不同。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 15.wav',
          dialog: {
            en: "Usually a crash recovers and it's not too deep.",
            sp: 'Normalmente un crash se recupera y no es muy profundo.',
            ch: '崩盘会恢复，而且不会太深。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 16.wav',
          dialog: {
            en: 'But this time nearly everything went to zero, almost for good.',
            sp: 'Pero esta vez casi todo llegó a cero, casi definitivamente.',
            ch: '但这一次几乎所有东西都归零了，几乎是永久性的。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 17.wav',
          dialog: {
            en: 'Scary.',
            sp: 'Aterrador.',
            ch: '可怕。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 18.wav',
          dialog: {
            en: 'Aye. And the cause? One lone investor in California.',
            sp: 'Sí. ¿Y la causa? Un solo inversor en California.',
            ch: '是的。原因呢？加州的一个孤独投资者。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 19.wav',
          dialog: {
            en: 'Lone investor? Like those hikkikomori types?',
            sp: '¿Inversor solitario? ¿Como esos tipos hikkikomori?',
            ch: '孤独的投资者？像那些引き籠もり类型？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 20.wav',
          dialog: {
            en: 'Yes, indeed. Turns out this person found out a way to engineer a few thousand USD',
            sp: 'Sí, de hecho. Resulta que esta persona encontró una manera de ingeniar unos pocos miles de USD',
            ch: '是的，确实。原来这个人找到了一种方法，用几千美元',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 21.wav',
          dialog: {
            en: 'Into positions worth trillions of dollars using high leverage tactics and several code exploits.',
            sp: '利用高杠杆策略和多个代码漏洞，将其转换为价值数万亿美元的头寸。',
            ch: '使用高杠杆策略和多个代码漏洞，将其转换为价值数万亿美元的头寸。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 22.wav',
          dialog: {
            en: 'At the end of it all his profit made him a bit under 4,000$ US.',
            sp: 'Al final de todo, su ganancia le dejó un poco menos de 4,000$ US.',
            ch: '最终他的利润让他赚了不到4000美元。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 23.wav',
          dialog: {
            en: 'At the cost of the global markets imploding, of course.',
            sp: 'Por supuesto, a costa de la implosión de los mercados globales.',
            ch: '当然，代价是全球市场的内爆。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 24.wav',
          dialog: {
            en: "What, that's it?!",
            sp: '¿Qué, eso es todo?!',
            ch: '什么，就这些？！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 25.wav',
          dialog: {
            en: 'Yes. A most embarrassing moment for the financial markets.',
            sp: 'Sí. Un momento muy embarazoso para los mercados financieros.',
            ch: '是的。金融市场最尴尬的时刻。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 26.wav',
          dialog: {
            en: "In any case, we've come a long way since those dark days.",
            sp: 'En cualquier caso, hemos recorrido un largo camino desde aquellos días oscuros.',
            ch: '无论如何，自那些黑暗的日子以来，我们已经走了很长的路。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 27.wav',
          dialog: {
            en: "We don't even use dollars since that event.",
            sp: 'Desde ese evento ni siquiera usamos dólares.',
            ch: '自那次事件以来，我们甚至不使用美元。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 28.wav',
          dialog: {
            en: 'Now we have protections set in place, and market crashes will never happen again.',
            sp: 'Ahora tenemos protecciones establecidas, y los crashes de mercado nunca volverán a ocurrir.',
            ch: '现在我们已经设置了保护措施，市场崩盘再也不会发生了。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 29.wav',
          dialog: {
            en: 'I guess it makes sense.',
            sp: 'Supongo que tiene sentido.',
            ch: '我猜这是有道理的。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 30.wav',
          dialog: {
            en: 'Yes. Part of the new rules is that price-negative talk and trades are controlled now.',
            sp: 'Sí. Parte de las nuevas reglas es que ahora se controlan las conversaciones y transacciones negativas en precio.',
            ch: '是的。新规则的一部分是现在控制价格负面的谈话和交易。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 31.wav',
          dialog: {
            en: "You can't just go and sell something these days. You need to ask permission first.",
            sp: 'Estos días no puedes simplemente ir y vender algo. Necesitas pedir permiso primero.',
            ch: '这些天你不能只是去卖东西。你需要先征得许可。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 32.wav',
          dialog: {
            en: 'Then sign the appropriate forms, and await an administrator to handle the transaction.',
            sp: 'Luego firma los formularios adecuados y espera a que un administrador maneje la transacción.',
            ch: '然后签署适当的表格，并等待管理员处理交易。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 33.wav',
          dialog: {
            en: '(snoring)',
            sp: '(ronquidos)',
            ch: '（打鼾）',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 34.wav',
          dialog: {
            en: 'Hey... Are you listening? (sigh)',
            sp: 'Oye... ¿Estás escuchando? (suspiro)',
            ch: '嘿......你在听吗？（叹气）',
          },
        },
      ],
    },
    'story#20': {
      title: '20. Upgrade - Incite Chaos',
      dps: 40000,
      scenes: [
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_1.wav',
          dialog: {
            en: 'Mayyyyy!',
            sp: '¡Mayyyyy!',
            ch: 'Mayyyyy！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_2.wav',
          dialog: {
            en: 'Yes?',
            sp: '¿Sí?',
            ch: '是的？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_3.wav',
          dialog: {
            en: 'This whole karma stuff is too slow. The buff keeps falling off.',
            sp: 'Todo este asunto del karma es demasiado lento. El beneficio sigue desapareciendo.',
            ch: '这整个因果报应的事太慢了。增益不断消失。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_4.wav',
          dialog: {
            en: 'Oh?',
            sp: '¿Oh?',
            ch: '哦？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_5.wav',
          dialog: {
            en: "Why can't we do more good more often?",
            sp: '¿Por qué no podemos hacer más bien más a menudo?',
            ch: '为什么我们不能更频繁地做更多的好事？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 6.wav',
          dialog: {
            en: "There's only so much good you can do at once.",
            sp: 'Solo puedes hacer tanto bien a la vez.',
            ch: '你一次只能做这么多好事。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 7.wav',
          dialog: {
            en: 'Is there some way to change that?',
            sp: '¿Hay alguna manera de cambiar eso?',
            ch: '有什么办法可以改变这一点吗？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 8.wav',
          dialog: {
            en: "I'm just... so bored! And more importantly our output is so low!",
            sp: '¡Solo estoy... tan aburrida! Y lo que es más importante, ¡nuestra producción es tan baja!',
            ch: '我只是......太无聊了！更重要的是，我们的产量太低了！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 9.wav',
          dialog: {
            en: 'Maybe there is...',
            sp: 'Tal vez haya...',
            ch: '也许有......',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 10.wav',
          dialog: {
            en: 'I have an idea. Let me message some old contacts at Deadeye Enforcement.',
            sp: 'Tengo una idea. Permíteme enviar un mensaje a algunos contactos antiguos en Deadeye Enforcement.',
            ch: '我有一个主意。让我给Deadeye Enforcement的一些老联系人发个消息。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 11.wav',
          dialog: {
            en: 'Deadeye... Enforcement...?',
            sp: 'Deadeye... Enforcement...?',
            ch: 'Deadeye...... Enforcement......？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 12.wav',
          dialog: {
            en: "Aye. They're a private security contractor. I'm surprised you don't know them.",
            sp: 'Sí. Son un contratista de seguridad privada. Me sorprende que no los conozcas.',
            ch: '是的。他们是一家私人安全承包商。我很惊讶你不认识他们。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 13.wav',
          dialog: {
            en: 'Ooh! Private security! What will they do? Make the world safer?',
            sp: '¡Oh! ¡Seguridad privada! ¿Qué harán? ¿Hacer el mundo más seguro?',
            ch: '哦！私人安全！他们会做什么？使世界更安全？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 14.wav',
          dialog: {
            en: 'Ahem. Since when have private security companies made the world safer?',
            sp: 'Ejem. ¿Desde cuándo las empresas de seguridad privada han hecho el mundo más seguro?',
            ch: '咳咳。私人安全公司什么时候让世界变得更安全了？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 15.wav',
          dialog: {
            en: 'Quite preposterous, dear Annie.',
            sp: 'Bastante absurdo, querida Annie.',
            ch: '相当荒谬，亲爱的Annie。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 16.wav',
          dialog: {
            en: "In any case - if we can't do good since everything is going well...",
            sp: 'En cualquier caso, si no podemos hacer el bien ya que todo va bien...',
            ch: '无论如何 - 如果我们不能做好事，因为一切都进行得很好......',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 17.wav',
          dialog: {
            en: 'Why not cause a bit of trouble?',
            sp: '¿Por qué no causar un poco de problemas?',
            ch: '为什么不制造一点麻烦呢？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 18.wav',
          dialog: {
            en: "But May! Wouldn't that make us bad people?",
            sp: '¡Pero May! ¿Eso no nos convertiría en malas personas?',
            ch: '但是May！那不会让我们变成坏人吗？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 19.wav',
          dialog: {
            en: 'No.',
            sp: 'No.',
            ch: '不。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 20.wav',
          dialog: {
            en: 'Oh... well if you say so.',
            sp: 'Oh... bueno, si tú lo dices.',
            ch: '哦......好吧，如果你这么说。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 21.wav',
          dialog: {
            en: 'I guess we could do more good if more people are in trouble.',
            sp: 'Supongo que podríamos hacer más bien si más personas están en problemas.',
            ch: '我猜如果有更多人陷入困境，我们可以做更多的好事。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 22.wav',
          dialog: {
            en: "Precisely, Annie. Now you're thinking like a true capitalist.",
            sp: 'Exactamente, Annie. Ahora estás pensando como una verdadera capitalista.',
            ch: '确切地说，Annie。现在你在像一个真正的资本家那样思考。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 23.wav',
          dialog: {
            en: 'Hero, a new button has appeared on your dashboard.',
            sp: 'Héroe, un nuevo botón ha aparecido en tu tablero.',
            ch: '英雄，你的仪表板上出现了一个新按钮。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 24.wav',
          dialog: {
            en: "It will allow you to 'INCITE CHAOS.'",
            sp: "Te permitirá 'INCITAR EL CAOS'.",
            ch: '它将允许你“煽动混乱”。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 25.wav',
          dialog: {
            en: 'When you press it, my Deadeye contacts will cause a bit of... unrest.',
            sp: 'Cuando lo presiones, mis contactos de Deadeye causarán un poco de... inquietud.',
            ch: '当你按下它时，我的Deadeye联系人会引起一些......不安。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 26.wav',
          dialog: {
            en: 'That unrest should reset the cooldown on your donation button.',
            sp: 'Esa inquietud debería restablecer el tiempo de espera de tu botón de donación.',
            ch: '那种不安应该重置你的捐赠按钮的冷却时间。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 27.wav',
          dialog: {
            en: 'Yes! Press it!',
            sp: '¡Sí! ¡Presiónalo!',
            ch: '是的！按下它！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 28.wav',
          dialog: {
            en: 'I SOLEMNLY SWEAR TO SAVE AS MANY PEOPLE AS I CAN, EVEN IF I MUST IMPERIL THEM MYSELF!',
            sp: '¡JURO SOLEMNEMENTE SALVAR A TANTAS PERSONAS COMO PUEDA, INCLUSO SI TENGO QUE PONERLAS EN PELIGRO YO MISMO!',
            ch: '我庄严宣誓尽我所能拯救尽可能多的人，即使我必须亲自危及他们！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 29.wav',
          dialog: {
            en: "You've always had a bit of a savior complex, haven't you?",
            sp: 'Siempre has tenido un poco de complejo de salvador, ¿no es así?',
            ch: '你一直有一点救世主情结，不是吗？',
          },
        },
      ],
    },
    'story#21': {
      title: '21. Upgrade - Crypto Mines',
      dps: 50000,
      scenes: [
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_1.wav',
          dialog: {
            en: '(humming) (clicking noises)',
            sp: '(tarareando) (ruidos de clics)',
            ch: '（哼唱）（点击声）',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_2.wav',
          dialog: {
            en: 'Annie?',
            sp: '¿Annie?',
            ch: 'Annie？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_3.wav',
          dialog: {
            en: '(humming)',
            sp: '(tarareando)',
            ch: '（哼唱）',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 4.wav',
          dialog: {
            en: 'Annie?',
            sp: '¿Annie?',
            ch: 'Annie？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 5.wav',
          dialog: {
            en: 'WHAT!?!',
            sp: '¿¡QUÉ!?',
            ch: '什么！？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 6.wav',
          dialog: {
            en: 'Ahem. What are you doing?',
            sp: 'Ejem. ¿Qué estás haciendo?',
            ch: '咳咳。你在做什么？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 7.wav',
          dialog: {
            en: 'Helping our hero by clicking for him, what else!',
            sp: '¡Ayudando a nuestro héroe haciendo clic por él, qué más!',
            ch: '帮助我们的英雄为他点击，还有什么！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 8.wav',
          dialog: {
            en: "I'm not sure that is the most scalable strategy.",
            sp: 'No estoy seguro de que sea la estrategia más escalable.',
            ch: '我不确定这是最可扩展的策略。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 9.wav',
          dialog: {
            en: 'What do you mean? You better be clicking too.',
            sp: '¿Qué quieres decir? Más te vale estar haciendo clic también.',
            ch: '你什么意思？你最好也点击。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 10.wav',
          dialog: {
            en: 'Oh no. I have a much better idea. You want to get more money right?',
            sp: 'Oh no. Tengo una idea mucho mejor. Quieres conseguir más dinero, ¿verdad?',
            ch: '哦不。我有一个更好的主意。你想要赚更多的钱，对吗？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 11.wav',
          dialog: {
            en: 'Yes!',
            sp: '¡Sí!',
            ch: '是的！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 12.wav',
          dialog: {
            en: 'Listen, no one is going to play this game as it is. It needs something... more.',
            sp: 'Escucha, nadie va a jugar este juego tal como está. Necesita algo... más.',
            ch: '听着，没有人会像现在这样玩这个游戏。它需要一些......更多。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 13.wav',
          dialog: {
            en: 'We need to implement… SPECULATIVE INVESTMENTS!',
            sp: '¡Necesitamos implementar… INVERSIONES ESPECULATIVAS!',
            ch: '我们需要实施......投机性投资！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 14.wav',
          dialog: {
            en: 'And just how will that improve the gameplay?',
            sp: '¿Y cómo mejorará eso la jugabilidad?',
            ch: '那将如何提升游戏性？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 15.wav',
          dialog: {
            en: 'Listen Annie, do you want to make some money or not?',
            sp: 'Escucha Annie, ¿quieres ganar algo de dinero o no?',
            ch: '听着Annie，你想赚钱还是不想？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 16.wav',
          dialog: {
            en: 'Well yes.',
            sp: 'Bueno sí.',
            ch: '好吧，是的。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 17.wav',
          dialog: {
            en: 'In this economy, the only way for games to succeed is by implementing some financial aspect.',
            sp: 'En esta economía, la única manera de que los juegos tengan éxito es implementando algún aspecto financiero.',
            ch: '在这种经济环境下，游戏成功的唯一方式是实施一些财务方面的内容。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 18.wav',
          dialog: {
            en: 'Everything is financialized these days after all.',
            sp: 'Después de todo, hoy en día todo está financiarizado.',
            ch: '毕竟，这些天一切都是金融化的。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 19.wav',
          dialog: {
            en: 'Trust me, if we do this, we are absolutely going to make it.',
            sp: 'Confía en mí, si hacemos esto, definitivamente lo lograremos.',
            ch: '相信我，如果我们这样做，我们绝对会成功的。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 20.wav',
          dialog: {
            en: 'Does it mean I can stop clicking?',
            sp: '¿Eso significa que puedo dejar de hacer clic?',
            ch: '这是否意味着我可以停止点击？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 21.wav',
          dialog: {
            en: 'Yes Annie. Yes it does.',
            sp: 'Sí Annie. Sí lo hace.',
            ch: '是的，Annie。是的，确实如此。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 22.wav',
          dialog: {
            en: 'Oh thank goodness.',
            sp: 'Oh gracias a Dios.',
            ch: '哦，谢天谢地。',
          },
        },
      ],
    },
    'story#22': {
      title: '22. The Listing',
      dps: 100000,
      scenes: [
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_1.wav',
          dialog: {
            en: 'Aaaand there! Tada!!!',
            sp: '¡Y ahí! ¡Tachán!!!',
            ch: '就在那里！哒哒！！！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_2.wav',
          dialog: {
            en: 'Annie? Why are you on the SEC website?',
            sp: '¿Annie? ¿Por qué estás en el sitio web de la SEC?',
            ch: 'Annie？你为什么在SEC网站上？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 3.wav',
          dialog: {
            en: 'I just submitted a filing for our company to IPO.',
            sp: 'Acabo de enviar una solicitud para que nuestra empresa salga a bolsa.',
            ch: '我刚刚提交了一份文件，让我们的公司进行首次公开募股。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 4.wav',
          dialog: {
            en: "We're listing on the New York Stock Exchange, baby!",
            sp: '¡Vamos a cotizar en la Bolsa de Nueva York, cariño!',
            ch: '宝贝，我们将在纽约证券交易所上市！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 5.wav',
          dialog: {
            en: "Wh-what?! It's way too early for that sort of thing! Oh no!",
            sp: '¿¡Qué qué?! ¡Es demasiado pronto para eso! ¡Oh no!',
            ch: '什么？！现在做这种事太早了！哦不！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 6.wav',
          dialog: {
            en: 'Oh yes! We are going to be bigger than Disney!',
            sp: '¡Oh sí! ¡Vamos a ser más grandes que Disney!',
            ch: '哦是的！我们将比迪士尼更大！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 7.wav',
          dialog: {
            en: 'But why would you do this?',
            sp: 'Pero, ¿por qué harías esto?',
            ch: '但你为什么要这么做？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 8.wav',
          dialog: {
            en: 'Well, I got to thinking. We need to generate some capital, right?',
            sp: 'Bueno, me puse a pensar. Necesitamos generar algo de capital, ¿verdad?',
            ch: '嗯，我在想。我们需要产生一些资本，对吧？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 9.wav',
          dialog: {
            en: 'Well - yes, but-',
            sp: 'Bueno - sí, pero-',
            ch: '嗯 - 是的，但是-',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 10.wav',
          dialog: {
            en: 'And we need to do it before we turn all old and wrinkly, right?',
            sp: 'Y necesitamos hacerlo antes de que nos pongamos todos viejos y arrugados, ¿verdad?',
            ch: '而且我们需要在我们变得老迈和皱纹之前做到这一点，对吧？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 11.wav',
          dialog: {
            en: 'Well, I would hope so but-',
            sp: 'Bueno, eso esperaría pero-',
            ch: '嗯，我希望如此但是-',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 12.wav',
          dialog: {
            en: 'So this is perfect! If we list on the stock market, we will get tons of greedy investors.',
            sp: '¡Así que esto es perfecto! Si salimos a bolsa, conseguiremos montones de inversores codiciosos.',
            ch: '所以这很完美！如果我们在股市上市，我们会得到大量的贪婪投资者。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 13.wav',
          dialog: {
            en: 'And that means lots of money to fund our enterprise!',
            sp: '¡Y eso significa mucho dinero para financiar nuestra empresa!',
            ch: '这意味着很多钱来资助我们的企业！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 14.wav',
          dialog: {
            en: "Hmmm... It's certainly possible... but...",
            sp: 'Hmm... Es ciertamente posible... pero...',
            ch: '嗯......这当然是可能的......但是......',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 15.wav',
          dialog: {
            en: 'But?',
            sp: '¿Pero?',
            ch: '但是？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 16.wav',
          dialog: {
            en: "It's just that... we don't have anything backing our company yet.",
            sp: 'Es solo que... todavía no tenemos nada que respalde nuestra empresa.',
            ch: '只是......我们的公司还没有任何支持。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 17.wav',
          dialog: {
            en: "Making a stock is pointless if there's no fundamentals...",
            sp: 'Crear una acción no tiene sentido si no hay fundamentos...',
            ch: '如果没有基本面，发行股票是没有意义的......',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 18.wav',
          dialog: {
            en: 'Who would buy a company with no assets and no product?',
            sp: '¿Quién compraría una empresa sin activos y sin producto?',
            ch: '谁会购买一家没有资产和产品的公司？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 19.wav',
          dialog: {
            en: 'Oh May. May May May May May...',
            sp: 'Oh May. May May May May May...',
            ch: '哦May。May May May May May......',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 20.wav',
          dialog: {
            en: 'Am I missing something here?',
            sp: '¿Me estoy perdiendo de algo aquí?',
            ch: '我在这里错过了什么吗？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 21.wav',
          dialog: {
            en: 'Yes! Our fundamentals are rock solid! Take a look...',
            sp: '¡Sí! ¡Nuestros fundamentos son sólidos como una roca! Echa un vistazo...',
            ch: '是的！我们的基础坚如磐石！看看......',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 22.wav',
          dialog: {
            en: 'Hmm.. eh?!',
            sp: 'Hmm.. ¿eh?!',
            ch: '嗯..哎？！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 23.wav',
          dialog: {
            en: 'Yup. I submitted our filing but as you can see...',
            sp: 'Sí. Envié nuestra solicitud pero como puedes ver...',
            ch: '是的。我提交了我们的文件，但正如你所看到的......',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 24.wav',
          dialog: {
            en: 'I changed the company logo to a picture of a cute dog.',
            sp: 'Cambié el logo de la empresa por una imagen de un perro lindo.',
            ch: '我把公司的logo换成了一张可爱的狗的图片。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 25.wav',
          dialog: {
            en: 'This is madness! And what will this even do?!',
            sp: '¡Esto es una locura! ¿Y qué hará esto incluso?!',
            ch: '这是疯狂的！这甚至会做什么？！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 26.wav',
          dialog: {
            en: "Everyone loves cute dogs. Wall Street can't resist.",
            sp: 'A todos les encantan los perros lindos. Wall Street no puede resistirse.',
            ch: '每个人都喜欢可爱的狗。华尔街无法抗拒。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 27.wav',
          dialog: {
            en: "Annie I don't think -",
            sp: 'Annie no creo -',
            ch: 'Annie我不认为——',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 28.wav',
          dialog: {
            en: 'There it is! Opening bell!',
            sp: '¡Ahí está! ¡Campana de apertura!',
            ch: '在那里！开市钟声！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 29.wav',
          dialog: {
            en: 'Woah. Is that-?',
            sp: 'Guau. ¿Es eso-?',
            ch: '哇。那是——？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 30.wav',
          dialog: {
            en: 'Mhm. Looks like people think the dog is cute.',
            sp: 'Mhm. Parece que la gente piensa que el perro es lindo.',
            ch: '嗯。看起来人们认为这只狗很可爱。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 31.wav',
          dialog: {
            en: 'Our valuation is skyrocketing! Up 500%!',
            sp: '¡Nuestra valoración se está disparando! ¡Hasta un 500%!',
            ch: '我们的估值正在飙升！上涨了500%！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 32.wav',
          dialog: {
            en: '(sound of her fainting)',
            sp: '(sonido de ella desmayándose)',
            ch: '（她昏倒的声音）',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 33.wav',
          dialog: {
            en: '(giggling) You can thank me later.',
            sp: '(risitas) Puedes agradecerme más tarde.',
            ch: '（咯咯笑）你可以稍后感谢我。',
          },
        },
      ],
    },
    'story#23': {
      title: '23. Kleptocurrency',
      dps: 150000,
      scenes: [
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_1.wav',
          dialog: {
            en: "Hey, I've got mail.",
            sp: 'Oye, tengo correo.',
            ch: '嘿，我收到邮件了。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_2.wav',
          dialog: {
            en: "What's it say?",
            sp: '¿Qué dice?',
            ch: '上面写了什么？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_3.wav',
          dialog: {
            en: 'Ahem. To the egregious May Liston, enclosed we present a final cash settlement...',
            sp: 'Ejem. A la atroz May Liston, adjuntamos una liquidación final en efectivo...',
            ch: '咳咳。致过分的May Liston，我们随函附上最终现金结算……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 4.wav',
          dialog: {
            en: 'for Case Number 172-5B: The State vs Zeno Müller.',
            sp: 'para el Caso Número 172-5B: El Estado vs Zeno Müller.',
            ch: '涉及案件编号172-5B：州政府对Zeno Müller的案件。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 5.wav',
          dialog: {
            en: 'Zeno Müller? The finance tycoon?',
            sp: '¿Zeno Müller? ¿El magnate de las finanzas?',
            ch: 'Zeno Müller？金融大亨？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 6.wav',
          dialog: {
            en: 'The very same. This is for one of his early companies - I was actually an investor.',
            sp: 'El mismo. Esto es para una de sus primeras empresas - de hecho, yo era inversora.',
            ch: '正是他。这是他早期公司之一——实际上我是投资者。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 7.wav',
          dialog: {
            en: 'Turns out it was one giant ponzi scheme.',
            sp: 'Resulta que era un gran esquema Ponzi.',
            ch: '结果证明这是一个巨大的庞氏骗局。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 5.wav',
          dialog: {
            en: 'What was the copany building?',
            sp: '¿Qué estaba construyendo la empresa?',
            ch: '公司在建什么？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 8.wav',
          dialog: {
            en: 'It was a cryptocurrency lending application that-',
            sp: 'Era una aplicación de préstamos de criptomonedas que-',
            ch: '它是一个加密货币借贷应用程序，那个-',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 9.wav',
          dialog: {
            en: 'Pfffft.',
            sp: 'Pfffft.',
            ch: 'Pfffft。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 10.wav',
          dialog: {
            en: 'A cryptocurrency len-',
            sp: 'Un préstamo de criptomoneda len-',
            ch: '一种加密货币借贷 len-',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 11.wav',
          dialog: {
            en: '(laughing uncontrollably) HAHAHAHA- WHAHAWHAHA *snort*',
            sp: '(riendo incontrolablemente) JA JA JA- WHAHAWHAHA *snort*',
            ch: '（笑得失控）哈哈哈哈-哇哈哈哈*鼻音*',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 12.wav',
          dialog: {
            en: 'Ugh, what is so humorous!',
            sp: 'Ugh, ¿qué tiene tanta gracia!',
            ch: '哦，有什么好笑的！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 13.wav',
          dialog: {
            en: "Oh May!!! You invested in a cryptocurrency company? What's wrong with you? *laughs*",
            sp: '¡Oh May! ¿Invertiste en una empresa de criptomonedas? ¿Qué te pasa? *risas*',
            ch: '哦May！！！你投资了一家加密货币公司？你怎么了？*笑声*',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 14.wav',
          dialog: {
            en: 'What do you mean? It was a perfectly good idea at the time and-',
            sp: '¿Qué quieres decir? Era una idea perfectamente buena en ese momento y-',
            ch: '你什么意思？当时这是一个非常好的想法和-',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 15.wav',
          dialog: {
            en: '*laughs again*, *calming down*. Oh May, for all of your financial wisdom...',
            sp: '*se ríe de nuevo*, *calmándose*. Oh May, con toda tu sabiduría financiera...',
            ch: '*再次笑声*，*平静下来*。哦May，对于你所有的财务智慧......',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 16.wav',
          dialog: {
            en: 'You should know that all crypto related businesses are scams.',
            sp: 'Deberías saber que todos los negocios relacionados con cripto son estafas.',
            ch: '你应该知道，所有与加密相关的业务都是骗局。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 17.wav',
          dialog: {
            en: "I'm actually surprised you of all people fell for something like that...",
            sp: 'En realidad, me sorprende que tú, de todas las personas, cayeras en algo así...',
            ch: '实际上，我很惊讶你这样的人会上当受骗......',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 18.wav',
          dialog: {
            en: 'Well excuse me for believing in new technologies...',
            sp: 'Bueno, discúlpame por creer en nuevas tecnologías...',
            ch: '好吧，原谅我相信新技术......',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 19.wav',
          dialog: {
            en: 'Hm.. Wait a minute...',
            sp: 'Mm... Espera un minuto...',
            ch: '嗯......等一下......',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 20.wav',
          dialog: {
            en: 'Uhuh?',
            sp: '¿Uhuh?',
            ch: '嗯嗯？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 21.wav',
          dialog: {
            en: "Didn't we start a crypto mining operation recently?",
            sp: '¿No comenzamos una operación de minería de cripto recientemente?',
            ch: '我们最近不是开始了一个加密挖矿操作吗？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 22.wav',
          dialog: {
            en: 'Yup!',
            sp: '¡Sí!',
            ch: '是的！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 23.wav',
          dialog: {
            en: "...Didn't you just say those are all scams?",
            sp: '...¿No acabas de decir que todos esos son estafas?',
            ch: '...你不是刚说那些都是骗局吗？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 24.wav',
          dialog: {
            en: "Yeah, but not when we're the ones running it!",
            sp: 'Sí, ¡pero no cuando nosotros somos los que lo dirigimos!',
            ch: '是的，但不是当我们是运营它的人！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 25.wav',
          dialog: {
            en: "Now that's just good business.",
            sp: 'Eso es simplemente un buen negocio.',
            ch: '那只是好生意。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 26.wav',
          dialog: {
            en: "Oh my... Annie, you've certainly come a long way.",
            sp: 'Ay... Annie, ciertamente has recorrido un largo camino.',
            ch: '哦，我的......Annie，你确实走了很长的路。',
          },
        },
      ],
    },
    'story#24': {
      title: '24. The Compulsive Gambler',
      dps: 200000,
      scenes: [
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_1.wav',
          dialog: {
            en: 'Cmon, cmon, go up! Up I say! Up!',
            sp: '¡Vamos, vamos, sube! ¡Digo que suba! ¡Arriba!',
            ch: '来吧，来吧，上去！我说上去！上去！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_2.wav',
          dialog: {
            en: 'May, what are you up to? Why are you so stressed?',
            sp: 'May, ¿qué estás haciendo? ¿Por qué estás tan estresada?',
            ch: 'May，你在做什么？你为什么这么紧张？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_3.wav',
          dialog: {
            en: "Ahem. I'm currently engaging in high frequency financial operations...",
            sp: 'Ejem. Actualmente estoy participando en operaciones financieras de alta frecuencia...',
            ch: '咳咳。我目前正在从事高频金融操作......',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 4.wav',
          dialog: {
            en: 'Hedging volatility with inverse synthetic trades..',
            sp: 'Con operaciones sintéticas inversas, cubriendo la volatilidad...',
            ch: '通过逆向合成交易对冲波动性..',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 5.wav',
          dialog: {
            en: 'Position sizing reflexively based on market demand.',
            sp: 'Dimensionamiento de la posición basado reflejamente en la demanda del mercado.',
            ch: '根据市场需求反射性地调整头寸大小。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 6.wav',
          dialog: {
            en: "Ohhhhhh.... so you're gambling.",
            sp: 'Ohhhhhh.... así que estás jugando.',
            ch: '哦哦哦......所以你在赌博。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 7.wav',
          dialog: {
            en: 'What! This is nothing like that!',
            sp: '¡Qué! ¡Esto no es nada de eso!',
            ch: '什么！这不是那样的！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 8.wav',
          dialog: {
            en: "I'm not so sure May. I look at your screen and just see flashing red and green buttons.",
            sp: 'No estoy tan segura, May. Miro tu pantalla y solo veo botones rojos y verdes parpadeando.',
            ch: '我不太确定May。我看你的屏幕，只看到闪烁的红色和绿色按钮。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 9.wav',
          dialog: {
            en: "That's the buy and sell terminal!",
            sp: '¡Ese es el terminal de compra y venta!',
            ch: '那是买卖终端！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 10.wav',
          dialog: {
            en: 'And look at this chart, it moves completely randomly!',
            sp: '¡Y mira este gráfico, se mueve completamente al azar!',
            ch: '看看这个图表，它完全随机移动！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 11.wav',
          dialog: {
            en: "They're bound by the laws of supply and demand!",
            sp: '¡Están regidos por las leyes de oferta y demanda!',
            ch: '它们受供求法则的约束！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 12.wav',
          dialog: {
            en: 'And it even has a high score on the bottom there.',
            sp: 'E incluso tiene una puntuación alta en la parte inferior.',
            ch: '甚至在底部还有一个高分。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 13.wav',
          dialog: {
            en: "That's my account balance!",
            sp: '¡Ese es el saldo de mi cuenta!',
            ch: '那是我的账户余额！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 14.wav',
          dialog: {
            en: "Doesn't look too good May. Not too good at all.",
            sp: 'No se ve muy bien, May. Nada bien en absoluto.',
            ch: '看起来不太好，May。一点也不好。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 15.wav',
          dialog: {
            en: 'Face it.',
            sp: 'Enfréntalo.',
            ch: '面对吧。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 16.wav',
          dialog: {
            en: "You're a gambler! A degenerate, compulsive gambler! Yes you are!",
            sp: '¡Eres un jugador! ¡Un jugador degenerado y compulsivo! ¡Sí lo eres!',
            ch: '你是个赌徒！一个堕落的，强迫性的赌徒！是的，你是！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 17.wav',
          dialog: {
            en: 'You can leave the casino, but the casino never leaves you.',
            sp: 'Puedes dejar el casino, pero el casino nunca te deja.',
            ch: '你可以离开赌场，但赌场永远不会离开你。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 18.wav',
          dialog: {
            en: "WAHAHAHAHAHAHA (slow start then belts out, like kira's laughing scene from death note)",
            sp: 'WAHAHAHAHAHAHA (comienza lento y luego se desata, como la escena de risa de kira de death note)',
            ch: 'WAHAHAHAHAHAHA（慢慢开始然后爆发出来，就像死亡笔记中kira的笑声场景）',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 19.wav',
          dialog: {
            en: "That's right I admit it!",
            sp: '¡Así es, lo admito!',
            ch: '没错我承认！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 20.wav',
          dialog: {
            en: 'I love to gamble! The thrill of the wager! Risking it all on a coin flip!',
            sp: '¡Me encanta apostar! ¡La emoción de la apuesta! ¡Arriesgándolo todo en un lanzamiento de moneda!',
            ch: '我喜欢赌博！赌注的刺激！在一次硬币翻转中冒险赌上一切！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 21.wav',
          dialog: {
            en: "It's just SUBLIME!!!",
            sp: '¡Es simplemente SUBLIME!!!',
            ch: '这简直是崇高的！！！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 22.wav',
          dialog: {
            en: 'After all, everyone has a vice, right! Gambling is the most mature form of thrill!',
            sp: 'Después de todo, ¡todos tienen un vicio, verdad! ¡El juego es la forma más madura de emoción!',
            ch: '毕竟，每个人都有一个恶习，对吧！赌博是最成熟的刺激形式！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 23.wav',
          dialog: {
            en: 'Betting everything! My heart! My soul!',
            sp: '¡Apostando todo! ¡Mi corazón! ¡Mi alma!',
            ch: '押注一切！我的心！我的灵魂！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo 24.wav',
          dialog: {
            en: 'Uhhh... okay. May - you have problems...',
            sp: 'Ehhh... está bien. May - tienes problemas...',
            ch: '呃......好吧。May——你有问题......',
          },
        },
      ],
    },
    'story#25': {
      title: '25. Upgrade: Demonic Pact',
      dps: 300000,
      scenes: [
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_1.wav',
          dialog: {
            en: 'May, take a look at this.',
            sp: 'May, echa un vistazo a esto.',
            ch: 'May，看看这个。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_2.wav',
          dialog: {
            en: 'Sure, I can - great Scott, what is that?!',
            sp: 'Claro, puedo - ¡dios mío, ¿qué es eso?!',
            ch: '当然，我可以——天哪，那是什么？！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_3.wav',
          dialog: {
            en: "I've been doing a bit of thinking and...",
            sp: 'He estado pensando un poco y...',
            ch: '我一直在思考......',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_4.wav',
          dialog: {
            en: 'Tada! This is our new demonic pentagram.',
            sp: '¡Tachán! Este es nuestro nuevo pentagrama demoníaco.',
            ch: '哒哒！这是我们新的恶魔五角星。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_5.wav',
          dialog: {
            en: 'Annie are you ok?',
            sp: '¿Annie, estás bien?',
            ch: 'Annie，你还好吗？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_6.wav',
          dialog: {
            en: 'Totally.',
            sp: 'Totalmente.',
            ch: '完全没问题。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_7.wav',
          dialog: {
            en: 'And this red.. is this... your blood?',
            sp: 'Y este rojo... ¿es esto... tu sangre?',
            ch: '这个红色的……是你的血吗？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_8.wav',
          dialog: {
            en: "Oh no, I'm squeamish. I got the local butcher to send this to me in a jar.",
            sp: 'Oh no, soy aprensiva. Le pedí al carnicero local que me enviara esto en un frasco.',
            ch: '哦，不，我有点胆小。我让当地的屠夫把这个放在罐子里寄给我。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_9.wav',
          dialog: {
            en: 'And they just did it, no questions asked?',
            sp: '¿Y simplemente lo hicieron, sin hacer preguntas?',
            ch: '他们就这么做了，没有问任何问题吗？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_10.wav',
          dialog: {
            en: "I've made such requests before. I'm a learned witch you know.",
            sp: 'He hecho tales solicitudes antes. Sabes que soy una bruja culta.',
            ch: '我以前提出过这样的要求。你知道，我是个博学的女巫。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_11.wav',
          dialog: {
            en: 'Okay so...',
            sp: 'Bueno, entonces...',
            ch: '好吧，所以......',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_12.wav',
          dialog: {
            en: "Well like I said. I've been thinking about how we can make even more money",
            sp: 'Bueno, como dije. He estado pensando en cómo podemos ganar aún más dinero',
            ch: '就像我说的。我一直在思考我们如何能赚到更多的钱',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_13.wav',
          dialog: {
            en: 'And I think we could use a bit of supernatural help!',
            sp: '¡Y creo que podríamos usar un poco de ayuda sobrenatural!',
            ch: '我认为我们可以用一些超自然的帮助！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_14.wav',
          dialog: {
            en: "Surely there's a law against this.",
            sp: 'Seguramente hay una ley contra esto.',
            ch: '肯定有法律禁止这样做。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_15.wav',
          dialog: {
            en: "I wouldn't have thought you to be against this, May.",
            sp: 'No habría pensado que estuvieras en contra de esto, May.',
            ch: '我本以为你不会反对这个，May。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_16.wav',
          dialog: {
            en: "All companies make demonic pacts you know. It's practically standard.",
            sp: 'Todas las empresas hacen pactos demoníacos, sabes. Es prácticamente estándar.',
            ch: '你知道，所有公司都会做恶魔契约。这几乎是标准的。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_17.wav',
          dialog: {
            en: 'Demonic Pact is the leading cause of childhood stardom!',
            sp: '¡El Pacto Demoníaco es la principal causa de la fama infantil!',
            ch: '恶魔契约是儿童成名的主要原因！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_18.wav',
          dialog: {
            en: "That wasn't in the handbook...",
            sp: 'Eso no estaba en el manual...',
            ch: '手册上没写这个……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_19.wav',
          dialog: {
            en: 'To hell with handbooks! And to unbelievers!!!!',
            sp: '¡Al diablo con los manuales! ¡Y a los no creyentes!!!!',
            ch: '去他妈的手册！致所有不信的人！！！！！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_20.wav',
          dialog: {
            en: 'Hear me!',
            sp: '¡Escúchenme!',
            ch: '听我说！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_21.wav',
          dialog: {
            en: 'I call upon the ancient powers and gatekeepers of Tartarus.',
            sp: 'Invoco a los antiguos poderes y guardianes del Tártaro.',
            ch: '我呼唤塔尔塔罗斯的古老力量和守门人。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_22.wav',
          dialog: {
            en: 'Thou will concede to this contract writ in blood!',
            sp: '¡Cederás a este contrato escrito en sangre!',
            ch: '你将承认这个用鲜血写成的契约！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_23.wav',
          dialog: {
            en: 'May our unchained avarice fluster even the gacha gods!',
            sp: '¡Que nuestra avaricia desatada desconcierte incluso a los dioses gacha!',
            ch: '愿我们不受束缚的贪婪甚至让抽卡之神困惑！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_24.wav',
          dialog: {
            en: 'May our loathsome paypiggies surrender every last drop of glinting gold!',
            sp: '¡Que nuestros odiosos paganos rindan hasta la última gota de oro brillante!',
            ch: '愿我们可憎的“付费猪”交出最后一滴闪闪发光的黄金！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_25.wav',
          dialog: {
            en: 'And may our enemies break and shatter against our mighty pay walls!',
            sp: '¡Y que nuestros enemigos se rompan y quiebren contra nuestros poderosos muros de pago!',
            ch: '愿我们的敌人撞在我们强大的付费墙上而粉碎！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_26.wav',
          dialog: {
            en: 'We are high-class demon accountants!',
            sp: '¡Somos contadores demoníacos de alta clase!',
            ch: '我们是高级恶魔会计师！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_27.wav',
          dialog: {
            en: 'Great scott! Annie, what have you done...',
            sp: '¡Dios mío! Annie, ¿qué has hecho...',
            ch: '天啊！Annie，你做了什么......',
          },
        },
      ],
    },
    'story#26': {
      title: '26. Control',
      dps: 400000,
      scenes: [
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_1.wav',
          dialog: {
            en: "Oh hero. Such great progress you're making!",
            sp: 'Oh héroe. ¡Qué gran progreso estás haciendo!',
            ch: '哦英雄。你进步真大！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_2.wav',
          dialog: {
            en: "Aye. Looks like they've truly taken to it.",
            sp: 'Sí. Parece que realmente lo han aceptado.',
            ch: '是的。看来他们真的适应了。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_3.wav',
          dialog: {
            en: "You're probably one of the best players we've had.",
            sp: 'Probablemente seas uno de los mejores jugadores que hemos tenido.',
            ch: '你可能是我们拥有过的最好的玩家之一。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_4.wav',
          dialog: {
            en: 'Certainly easier to manage than the ones who churn out.',
            sp: 'Ciertamente más fácil de manejar que los que se van.',
            ch: '当然，比那些流失的玩家更容易管理。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_5.wav',
          dialog: {
            en: 'Well, our brave hero might be in a class of their own...',
            sp: 'Bueno, nuestro valiente héroe podría estar en una clase propia...',
            ch: '嗯，我们勇敢的英雄可能是独一无二的……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_6.wav',
          dialog: {
            en: "... but at this point they're basically our slave.",
            sp: '... pero en este punto, básicamente son nuestros esclavos.',
            ch: '...但在这一点上，他们基本上是我们的奴隶。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/2.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_7.wav',
          dialog: {
            en: '....slave?',
            sp: '....¿esclavo?',
            ch: '....奴隶？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_8.wav',
          dialog: {
            en: 'Indeed. They have no choice but to click.',
            sp: 'De hecho. No tienen otra opción que hacer clic.',
            ch: '确实如此。他们别无选择，只能点击。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_9.wav',
          dialog: {
            en: 'Oh my...',
            sp: 'Oh por Dios...',
            ch: '哦天啊……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_10.wav',
          dialog: {
            en: "But I'm quite sure they enjoy it.",
            sp: 'Pero estoy bastante seguro de que lo disfrutan.',
            ch: '但我很确定他们喜欢它。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_11.wav',
          dialog: {
            en: 'Hmmm... well if we have a new slave...',
            sp: 'Hmm... bueno, si tenemos un nuevo esclavo...',
            ch: '嗯...如果我们有一个新的奴隶...',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_12.wav',
          dialog: {
            en: 'Perhaps we should test the limits of our powers.',
            sp: 'Quizás deberíamos probar los límites de nuestros poderes.',
            ch: '也许我们应该测试一下我们的能力极限。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_13.wav',
          dialog: {
            en: 'What did you have in mind dear Annie?',
            sp: '¿Qué tenías en mente querida Annie?',
            ch: '亲爱的Annie，你有什么想法？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_14.wav',
          dialog: {
            en: 'Ehehehe...',
            sp: 'Ehehehe...',
            ch: '呵呵呵……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_15.wav',
          dialog: {
            en: '*Hero*.',
            sp: '*Héroe*.',
            ch: '*英雄*。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_16.wav',
          dialog: {
            en: 'Click this message.',
            sp: 'Haz clic en este mensaje.',
            ch: '点击此消息。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_17.wav',
          dialog: {
            en: '(giggles) and again!',
            sp: '(risitas) ¡y otra vez!',
            ch: '（咯咯笑）再来一次！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_18.wav',
          dialog: {
            en: "You really do obey instructions well, don't you?",
            sp: 'Realmente obedeces bien las instrucciones, ¿verdad?',
            ch: '你真的很听话，不是吗？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_19.wav',
          dialog: {
            en: "It's what makes them special.",
            sp: 'Es lo que los hace especiales.',
            ch: '这就是让他们与众不同的地方。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_20.wav',
          dialog: {
            en: 'Ehehehe...',
            sp: 'Ehehehe...',
            ch: '呵呵呵……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_21.wav',
          dialog: {
            en: 'I bet we can make them bow and beg!',
            sp: '¡Apuesto a que podemos hacer que se inclinen y supliquen!',
            ch: '我敢打赌我们能让他们跪下乞求！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_22.wav',
          dialog: {
            en: 'Bow to your darling cute assistants, hero!',
            sp: '¡Inclínate ante tus adorables asistentes, héroe!',
            ch: '向你亲爱的可爱助手鞠躬吧，英雄！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_23.wav',
          dialog: {
            en: 'Good. Very good.',
            sp: 'Bien. Muy bien.',
            ch: '很好。非常好。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_24.wav',
          dialog: {
            en: 'Annie, are you alright?',
            sp: 'Annie, ¿estás bien?',
            ch: 'Annie，你还好吗？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_25.wav',
          dialog: {
            en: 'More than alright! This is very much fun.',
            sp: '¡Más que bien! Esto es muy divertido.',
            ch: '不止是好！这很有趣。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_26.wav',
          dialog: {
            en: "I'll give you one last command.",
            sp: 'Te daré una última orden.',
            ch: '我给你最后一个命令。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_27.wav',
          dialog: {
            en: 'Get on your knees.',
            sp: 'Ponte de rodillas.',
            ch: '跪下。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_28.wav',
          dialog: {
            en: 'And bark like a dog.',
            sp: 'Y ladra como un perro.',
            ch: '像狗一样叫。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_29.wav',
          dialog: {
            en: "If you BARK... maybe I'll reward you for it., (giggles)",
            sp: 'Si LADRAS... tal vez te recompense por ello., (risitas)',
            ch: '如果你叫……也许我会奖励你。, （咯咯笑)',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_31.wav',
          dialog: {
            en: 'Heh. Annie you always give this test to players who get this far.',
            sp: 'Je. Annie, siempre haces esta prueba a los jugadores que llegan tan lejos.',
            ch: '呵呵。Annie，你总是对走到这一步的玩家进行这个测试。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_32.wav',
          dialog: {
            en: "Oh well. Let's see if the hero can figure this one out.",
            sp: 'Oh bueno. Veamos si el héroe puede resolver esto.',
            ch: '哦，好吧。让我们看看英雄是否能弄明白这个问题。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_33.wav',
          dialog: {
            en: 'Either way, we will find out if our hero is as smart as they look...',
            sp: 'De cualquier manera, descubriremos si nuestro héroe es tan inteligente como parece...',
            ch: '无论如何，我们将发现我们的英雄是否像他们看起来那么聪明……',
          },
        },
      ],
    },
    'story#27': {
      title: '27. Victory',
      dps: 500000,
      scenes: [
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_1.wav',
          dialog: {
            en: '... and so it was, that the brave hero vanquished the great evil...',
            sp: '... y así fue, que el valiente héroe venció al gran mal...',
            ch: '……于是，勇敢的英雄打败了大恶……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_2.wav',
          dialog: {
            en: 'restored peace to his homeland...',
            sp: 'restauró la paz en su tierra natal...',
            ch: '恢复了祖国的和平……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_3.wav',
          dialog: {
            en: 'and even found love. Thus did he live happily ever after.',
            sp: 'e incluso encontró el amor. Así vivió feliz para siempre.',
            ch: '甚至找到了爱。于是他幸福地生活了下去。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_4.wav',
          dialog: {
            en: 'What a great story. What are you reading?',
            sp: 'Qué gran historia. ¿Qué estás leyendo?',
            ch: '真是个好故事。你在读什么？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_5.wav',
          dialog: {
            en: 'The script for the original ending of this game. I hear it got trashed.',
            sp: 'El guion del final original de este juego. Escuché que lo desecharon.',
            ch: '这款游戏原始结局的剧本。我听说它被丢弃了。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_6.wav',
          dialog: {
            en: 'Binned for a more exciting ending perhaps?',
            sp: '¿Desechado por un final más emocionante, tal vez?',
            ch: '可能是为了一个更激动人心的结局而被丢弃了？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_7.wav',
          dialog: {
            en: "Not quite. It's just that...",
            sp: 'No del todo. Es solo que...',
            ch: '不完全是。只是……',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_8.wav',
          dialog: {
            en: 'Well, this industry is interested only in incentive.',
            sp: 'Bueno, esta industria solo está interesada en incentivos.',
            ch: '嗯，这个行业只对激励感兴趣。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_9.wav',
          dialog: {
            en: 'Incentive certainly rules the world.',
            sp: 'El incentivo ciertamente gobierna el mundo.',
            ch: '激励确实统治着世界。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_10.wav',
          dialog: {
            en: 'And we have no such thing... at least not in this ending.',
            sp: 'Y no tenemos tal cosa... al menos no en este final.',
            ch: '而我们没有这样的东西……至少在这个结局中没有。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_11.wav',
          dialog: {
            en: 'So we need a new one for our brave hero?',
            sp: '¿Entonces necesitamos uno nuevo para nuestro valiente héroe?',
            ch: '那么我们需要给我们勇敢的英雄一个新的结局吗？',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_12.wav',
          dialog: {
            en: "Yes! Now what's a proper incentive for a financial war criminal...",
            sp: '¡Sí! Ahora, ¿cuál es un incentivo adecuado para un criminal de guerra financiero...',
            ch: '是的！那么，金融战争罪犯的适当激励措施是什么呢……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_13.wav',
          dialog: {
            en: 'Oh I know. We have all of those junk NFTs we never released.',
            sp: 'Oh, ya sé. Tenemos todos esos NFTs basura que nunca lanzamos.',
            ch: '哦，我知道。我们有所有那些从未发布的垃圾NFT。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_14.wav',
          dialog: {
            en: 'Oh yes! That would be the perfect reward. A useless NFT!',
            sp: '¡Oh, sí! Esa sería la recompensa perfecta. ¡Un NFT inútil!',
            ch: '哦，是的！那将是完美的奖励。一个无用的NFT！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_15.wav',
          dialog: {
            en: "Not quite, Annie. If we tell them it's useless then they won't appreciate it.",
            sp: 'No del todo, Annie. Si les decimos que es inútil, entonces no lo apreciarán.',
            ch: '不完全是，Annie。如果我们告诉他们这没用，他们就不会欣赏它。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_16.wav',
          dialog: {
            en: 'Hero, take this...*special* digital collectible. Go ahead and mint it when you can.',
            sp: 'Héroe, toma este... *especial* coleccionable digital. Adelante, conéctalo cuando puedas.',
            ch: '英雄，拿上这个……*特殊*的数字收藏品。请随时铸造它。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_17.wav',
          dialog: {
            en: 'We put the mint function in your dashboard.',
            sp: 'Colocamos la función de acuñación en tu panel de control.',
            ch: '我们在你的仪表板上放置了铸造功能。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_18.wav',
          dialog: {
            en: 'This will serve as proof you prevailed in the game.',
            sp: 'Esto servirá como prueba de que prevaleciste en el juego.',
            ch: '这将作为你在游戏中获胜的证明。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_19.wav',
          dialog: {
            en: 'And maybe in the future it will give you something more.',
            sp: 'Y tal vez en el futuro te dará algo más.',
            ch: '也许在未来它会给你更多的东西。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_20.wav',
          dialog: {
            en: "So long then, hero! You're the best!",
            sp: '¡Hasta entonces, héroe! ¡Eres el mejor!',
            ch: '再见了，英雄！你是最棒的！',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_21.wav',
          dialog: {
            en: "Goodbye, hero. And don't lose your wallet!",
            sp: 'Adiós, héroe. ¡Y no pierdas tu billetera!',
            ch: '再见，英雄。别丢了你的钱包！',
          },
        },
        {
          character: 'annie_and_may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/Scene1/vo_22.wav',
          dialog: {
            en: 'Hehe. Haha. Hahehehahaha.',
            sp: 'Jeje. Jaja. Jejejejeje.',
            ch: '呵呵。哈哈。哈哈哈哈。',
          },
        },
      ],
    },
    annie_scene_R: {
      title: 'Scene R',
      dps: 0,
      scenes: [
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/Story/Audios/VO/HappyBirthdays_Annie/vo_1.wav',
          dialog: {
            en: 'Hellooooo- helloooooooooo!',
            es: '¡Holaaaa- holaaaaaaaa!',
            ch: '你好——你好！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/Story/Audios/VO/HappyBirthdays_Annie/vo_2.wav',
          dialog: {
            en: 'Hey! There you are.',
            es: '¡Oye! Ahí estás.',
            ch: '嘿！你在那里。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/Story/Audios/VO/HappyBirthdays_Annie/vo_3.wav',
          dialog: {
            en: 'Where have you been all day? Working like a dog I see!',
            es: '¿Dónde has estado todo el día? ¡Veo que has estado trabajando como un perro!',
            ch: '你一整天都去哪儿了？我看你工作得像条狗一样！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_4.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/Story/Audios/VO/HappyBirthdays_Annie/vo_4.wav',
          dialog: {
            en: 'Well, take a break for a moment. I have something to show you.',
            es: 'Bueno, toma un descanso por un momento. Tengo algo que mostrarte.',
            ch: '好吧，休息一下。我有些东西要给你看。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_5.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/Story/Audios/VO/HappyBirthdays_Annie/vo_5.wav',
          dialog: {
            en: "It's right here",
            es: 'Está justo aquí',
            ch: '就在这里 (Jiù zài zhèlǐ)！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_5.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/Story/Audios/VO/HappyBirthdays_Annie/vo_6.wav',
          dialog: {
            en: 'Tada!!!!',
            es: '¡Tada!!!!',
            ch: '瞧！！！！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_5.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/Story/Audios/VO/HappyBirthdays_Annie/vo_7.wav',
          dialog: {
            en: 'Happy Birthday! Yes, the cake is for you!',
            es: '¡Feliz cumpleaños! ¡Sí, el pastel es para ti!',
            ch: '生日快乐！是的，蛋糕是给你的！',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_5.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/Story/Audios/VO/HappyBirthdays_Annie/vo_8.wav',
          dialog: {
            en: "How I knew? Oh, well, it's not like I'm stalking your social media.",
            es: '¿Cómo lo supe? Oh, bueno, no es como si estuviera espiando tus redes sociales.',
            ch: '我怎么知道的？哦，好吧，不是说我在监视你的社交媒体。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_5.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/Story/Audios/VO/HappyBirthdays_Annie/vo_9.wav',
          dialog: {
            en: "I'd never do that.... *yet*... HAHAH",
            es: 'Nunca haría eso.... *todavía*... JAJA',
            ch: '我绝不会这样做...... *还*... 哈哈',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_5.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/Story/Audios/VO/HappyBirthdays_Annie/vo_10.wav',
          dialog: {
            en: 'You entered it in yourself a while ago. We are GDPR compliant after all.',
            es: 'Lo ingresaste tú mismo hace un tiempo. Después de todo, somos compatibles con el GDPR.',
            ch: '你自己早些时候输入了。毕竟，我们符合GDPR。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_5.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/Story/Audios/VO/HappyBirthdays_Annie/vo_11.wav',
          dialog: {
            en: "I just wanted to say, you're the best hero I've ever met.",
            es: 'Solo quería decir, eres el mejor héroe que he conocido.',
            ch: '我只是想说，你是我见过的最棒的英雄。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_5.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/Story/Audios/VO/HappyBirthdays_Annie/vo_12.wav',
          dialog: {
            en: 'Absolutely, undeniably wonderful.',
            es: 'Absolutamente, innegablemente maravilloso.',
            ch: '绝对，无法否认的美妙。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_5.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/Story/Audios/VO/HappyBirthdays_Annie/vo_13.wav',
          dialog: {
            en: "And I'm glad that we had the chance to meet.",
            es: 'Y me alegra que hayamos tenido la oportunidad de conocernos.',
            ch: '我很高兴我们有机会见面。',
          },
        },
        {
          character: 'annie',
          image_type: '/assets/Story/Characters/Annie_5.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/Story/Audios/VO/HappyBirthdays_Annie/vo_14.wav',
          dialog: {
            en: 'Now, dig in!',
            es: '¡Ahora, a comer!',
            ch: '现在，开动吧！',
          },
        },
      ],
    },
    may_scene_R: {
      title: 'Scene R',
      dps: 0,
      scenes: [
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_1.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/HappyBirthday_May/vo_1.wav',
          dialog: {
            en: 'Hey, do you have a moment?',
            es: 'Oye, ¿tienes un momento?',
            ch: '嘿，你有时间吗？',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_2.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/HappyBirthday_May/vo_2.wav',
          dialog: {
            en: "Today's your special day, you know.",
            es: 'Hoy es tu día especial, lo sabes.',
            ch: '今天是你的特别日子，你知道的。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_3.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/HappyBirthday_May/vo_3.wav',
          dialog: {
            en: "Oh, you forgot? Well don't worry, I didn't.",
            es: 'Oh, ¿olvidaste? Bueno, no te preocupes, yo no.',
            ch: '哦，你忘了吗？好吧，不用担心，我没有忘记。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_4.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/HappyBirthday_May/vo_4.wav',
          dialog: {
            en: 'I logged your information and made sure to set a reminder.',
            es: 'Registré tu información y me aseguré de configurar un recordatorio.',
            ch: '我记录了你的信息，并确保设置了提醒。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_5.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/HappyBirthday_May/vo_5.wav',
          dialog: {
            en: 'Happy Birthday! Thanks for playing.',
            es: '¡Feliz cumpleaños! Gracias por jugar.',
            ch: '生日快乐！感谢你的参与。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_5.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/HappyBirthday_May/vo_6.wav',
          dialog: {
            en: 'I also brought you this cake to show my appreciation.',
            es: 'También te traje este pastel para mostrar mi agradecimiento.',
            ch: '我还给你带来了这个蛋糕以表达我的感激之情。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_5.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/HappyBirthday_May/vo_7.wav',
          dialog: {
            en: 'It even has some of that Italian chocolate that is super expensive.',
            es: 'Incluso tiene un poco de ese chocolate italiano que es muy caro.',
            ch: '里面还有一些超级贵的意大利巧克力。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_5.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/HappyBirthday_May/vo_8.wav',
          dialog: {
            en: "Oh, don't worry. I'm happy to spend if it's on you.",
            es: 'Oh, no te preocupes. Estoy feliz de gastar si es para ti.',
            ch: '哦，别担心。如果是为了你，我很高兴花钱。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_5.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio: '/assets/Story/Audios/VO/HappyBirthday_May/vo_9.wav',
          dialog: {
            en: 'Plus it makes a great tax write-off. (does a cute laugh)',
            es: 'Además, es una excelente deducción fiscal. (ríe de forma tierna)',
            ch: '而且这还是个很好的税务扣除。（做了个可爱的笑）',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_5.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/Story/Audios/VO/HappyBirthday_May/vo_10.wav',
          dialog: {
            en: 'I had one more thing... If you can lend me your cheek.',
            es: 'Tenía una cosa más... Si puedes prestarme tu mejilla.',
            ch: '我还有一件事……如果你能借给我你的脸颊。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_5.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/Story/Audios/VO/HappyBirthday_May/vo_11.wav',
          dialog: {
            en: "(gives a kiss) There! I hope you enjoyed that. It's a rare gift indeed.",
            es: '(da un beso) ¡Ahí está! Espero que lo hayas disfrutado. Es un regalo raro de verdad.',
            ch: '（亲吻）就这样！希望你喜欢。这确实是一个稀有的礼物。',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_5.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/Story/Audios/VO/HappyBirthday_May/vo_12.wav',
          dialog: {
            en: "Well, don't just stand there looking awkward... ehm...",
            es: 'Bueno, no te quedes ahí parado mirando incómodo... eh...',
            ch: '好吧，不要只是站在那里看起来很尴尬……嗯……',
          },
        },
        {
          character: 'may',
          image_type: '/assets/Story/Characters/May_5.png',
          reaction: '/assets/Story/Characters/reactions/1.png',
          audio:
            '/assets/Story/Audios/VO/HappyBirthday_May/vo_13.wav',
          dialog: {
            en: '(clears throat). Ahem! Back to work then!',
            es: '(se aclara la garganta). ¡Ahem! ¡Volvamos al trabajo entonces!',
            ch: '（清了清喉咙）嗯！那么，回到工作吧！',
          },
        },
      ],
    },
  };
};
