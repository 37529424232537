import { useEffect } from "react";
import styles from "./styled.module.css";
import { gearsData } from "../../site-data/gears";
import { useDispatch, useSelector } from "react-redux";
import { setGearsLevels } from "../../store/slices/game.slice";

export default function Gears() {
  const dispatch = useDispatch();
  const { gearsLevels } = useSelector((state) => state.game);

  const filteredGears = gearsData.filter((item, index) => {
    if (gearsLevels?.[item.id] !== undefined) {
      // Modify the item object here as needed
      item.indexValue = index;
      return true; // Keep this item in the filtered list
    }
    return false; // Exclude this item from the filtered list
  });

  const gearsLevelsSetter = (levels) => {
    dispatch(setGearsLevels(levels));
  };

  useEffect(() => {
    const gearsClickedLevels = localStorage.getItem("GameGearsClickedLevels");
    if (gearsClickedLevels) {
      const levelsValue = JSON.parse(gearsClickedLevels);
      gearsLevelsSetter(levelsValue);
    }
  }, []);

  return (
    <div className={styles.gearsContainer}>
      <div className={styles.gearsWrapper}>
        <div>
          {filteredGears.length > 0 &&
            filteredGears.map(({ id, indexValue }) => {
              const maxLevel = gearsLevels?.[id];
              return (
                <button key={id} className={styles.gear}>
                  <img
                    alt={id}
                    src={`assets/Gear/${maxLevel} ${indexValue + 1}.png`}
                  />
                </button>
              );
            })}
        </div>
      </div>
    </div>
  );
}
