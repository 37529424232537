export const nodesData = [
  {
    id: 'botting',
    type: 'Botting',
    basePrice: 5,
    baseDps: 0.25,
    dps: '0.25',
    symbol: { src: '', alt: 'Cursor' },
  },
  {
    id: 'hard_labor',
    type: 'Hard Labor',
    basePrice: 30,
    baseDps: 1.33,
    dps: '1.33',
    symbol: { src: '', alt: 'A pickaxe' },
  },
  {
    id: 'referral_scam',
    type: 'Referral Scam',
    basePrice: 300,
    baseDps: 7.2,
    dps: '7.2',
    symbol: { src: '', alt: 'A magnifying glass' },
  },
  {
    id: 'click_farm',
    type: 'Click Farm',
    basePrice: '4000',
    baseDps: 40,
    dps: '40',
    symbol: { src: '', alt: 'A bunch of smartphones' },
  },
];

export const passiveIncomeData = [
  {
    id: 'influencing',
    type: 'Influencing',
    basePrice: 45000,
    baseDps: 220,
    dps: '220',
    symbol: { src: '', alt: 'A studio microphone' },
  },
  {
    id: 'dropshipping_empire',
    type: 'Dropshipping Empire',
    basePrice: 300000,
    baseDps: 1283.6,
    dps: '1283.6',
    symbol: { src: '', alt: "Andrew Tate's head" },
  },
  {
    id: 'insider_trading',
    type: 'Insider Trading',
    basePrice: 1000000,
    baseDps: 6500,
    dps: '6500',
    symbol: { src: '', alt: 'An upwards chart in the bg' },
  },
  {
    id: 'sponsorship_deals',
    type: 'Sponsorship Deals',
    basePrice: 100000000,
    baseDps: 35714.3,
    dps: '35714.3',
    symbol: { src: '', alt: 'Shaking hands emoji' },
  },
  {
    id: 'government_subsidies',
    type: 'Government Subsidies',
    basePrice: 1600000000,
    baseDps: 19638.9,
    dps: '19638.9',
    symbol: { src: '', alt: 'A bank' },
  },
  {
    id: 'pyramid_schemes',
    type: 'Pyramid Schemes',
    basePrice: 25000000000,
    baseDps: 100000,
    dps: '100000',
    symbol: { src: '', alt: 'A pyramid' },
  },
  {
    id: 'cryptocurrency_mines',
    type: 'Cryptocurrency Mines',
    basePrice: 330000000000,
    baseDps: 555000,
    dps: '555000',
    symbol: { src: '', alt: 'A mine entrance' },
  },
  {
    id: 'cyber_warfare',
    type: 'Cyber Warfare',
    basePrice: 4000000000000,
    baseDps: 30000000,
    dps: '30000000',
    symbol: { src: '', alt: 'A computer' },
  },
  {
    id: 'asteroid_mining',
    type: 'Asteroid Mining',
    basePrice: 80000000000000,
    baseDps: 1650000000,
    dps: '1650000000',
    symbol: { src: '', alt: 'Space-X-esque rockets' },
  },
  {
    id: 'occult_alchemy',
    type: 'Occult Alchemy',
    basePrice: 1000000000000000,
    baseDps: 11550000000,
    dps: '11550000000',
    symbol: { src: '', alt: 'A purple potion' },
  },
  {
    id: 'demonic_pact',
    type: 'Demonic Pact',
    basePrice: 666666666666666666,
    baseDps: 150000010000001.5,
    dps: '150000010000001.5',
    symbol: { src: '', alt: 'A pentagram in blood' },
  },
];
