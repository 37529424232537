// src/Tooltip.js
import React from "react";
import styles from "./styled.module.css";

const TooltipContent = ({ data }) => {
  const { type, price, effect } = data;

  return (
    <div className={styles.tooltipContent}>
      <p>Name: {type}</p>
      <p>Price: {price}</p>
      {/* <p>Increase Yield by: {formatCompactNumber(Math.floor(effect))}</p> */}
      <p>{effect["increaseInValueBy"] ? "Increase in Yeild by x" + effect["increaseInValueBy"] : effect["discountOnPriceOf"] ? "Node price reduces by x" + effect["discountOnPriceOf"] : "Increase in Yield by x" + effect}</p>
    </div>
  );
};

export default TooltipContent;
