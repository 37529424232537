import React, { useState, useEffect } from "react";
import RollerText from "./roller";
import styles from "./styled.module.css";

const RollerSelect = ({
  options = [],
  fontSize = 48,
  animate = false,
  onChange,
  autoChangeTo,
  ones,
}) => {
  const [currentIndex, setCurrentIndex] = useState(ones ? 1 : 0);
  const [prevIndex, setPrevIndex] = useState(ones ? options[1] : options[0]);
  const [changeTo, setChangeTo] = useState({});
  const [isTransitioning, setIsTransitioning] = useState(false);

  const longestWord = options.reduce((a, b) => (a.length > b.length ? a : b));
  const width = (longestWord.length / 2) * fontSize;

  const handleClick = (direction) => {
    if (isTransitioning) return;
    setIsTransitioning(true);

    const nextIndex = animate
      ? parseInt(autoChangeTo)
      : direction === "up"
      ? (currentIndex - 1 + options.length) % options.length
      : (currentIndex + 1) % options.length;

    setChangeTo({ value: options[nextIndex], direction });

    setTimeout(() => {
      setChangeTo({});
      setCurrentIndex(nextIndex);
      setIsTransitioning(false);
      setPrevIndex(options[nextIndex]);
      if (onChange) onChange(options[nextIndex]);
    }, 300);
  };

  const upHandler = () => handleClick("up");
  const downHandler = () => handleClick("down");

  useEffect(() => {
    if (animate) {
      handleClick("down");
    }
  }, [animate]);

  return (
    <div className={styles.rollerSelectMain}>
      <div className={styles.buttonContainer}>
        <img
          onClick={upHandler}
          disabled={isTransitioning}
          style={{
            width: `${fontSize / 2.5}px`,
            height: `${fontSize / 2.5}px`,
          }}
          alt=""
          src="/assets/Birthday/upButton.png"
        />
        <img
          alt=""
          onClick={downHandler}
          disabled={isTransitioning}
          style={{
            width: `${fontSize / 2.5}px`,
            height: `${fontSize / 2.5}px`,
          }}
          src="/assets/Birthday/downButton.png"
        />
      </div>
      <RollerText
        text={animate ? prevIndex : options[currentIndex]}
        changeTo={animate ? autoChangeTo : changeTo.value}
        direction={changeTo.direction}
        width={width}
        fontSize={fontSize}
        color="#ffffff"
        animate={isTransitioning}
      />
    </div>
  );
};

export default RollerSelect;
