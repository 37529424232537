export const siteContentData = {
  Botting: {
    sp: "Botar",
    ch: "机器人",
  },
  "Hard Labor": {
    sp: "Trabajo duro",
    ch: "艰苦劳动",
  },
  "Referral Scam": {
    sp: "Estafa de referencia",
    ch: "推荐骗局",
  },
  "Click Farm": {
    sp: "Granja de clics",
    ch: "点击农场",
  },
  Influencing: {
    sp: "Influencia",
    ch: "影响力",
  },
  "Dropshipping Empire": {
    sp: "Imperio de dropshipping",
    ch: "直销帝国",
  },
  "Insider Trading": {
    sp: "Negociación con información privilegiada",
    ch: "内幕交易",
  },
  "Sponsorship Deals": {
    sp: "Acuerdos de patrocinio",
    ch: "赞助交易",
  },
  "Government Subsidies": {
    sp: "Subsidios gubernamentales",
    ch: "政府补贴",
  },
  "Pyramid Schemes": {
    sp: "Esquemas piramidales",
    ch: "金字塔计划",
  },
  "Cryptocurrency Mines": {
    sp: "Minas de criptomonedas",
    ch: "加密货币矿场",
  },
  "Cyber Warfare": {
    sp: "Guerra cibernética",
    ch: "网络战争",
  },
  "Asteroid Mining": {
    sp: "Minería de asteroides",
    ch: "小行星采矿",
  },
  "Occult Alchemy": {
    sp: "Alquimia oculta",
    ch: "神秘炼金术",
  },
  "Demonic Pact": {
    sp: "Pacto demoníaco",
    ch: "恶魔契约",
  },
  "Head Glasses": {
    sp: "Cabeza Gafas",
    ch: "头部 眼镜",
  },
  "Chest Suit": {
    sp: "Pecho Traje",
    ch: "胸部 套装",
  },
  "Gloves Wallet": {
    sp: "Guantes Cartera",
    ch: "手套 钱包",
  },
  "Legs Briefcase": {
    sp: "Piernas Maletín",
    ch: "腿部 公文包",
  },
  "Weapon Calculator": {
    sp: "Arma Calculadora",
    ch: "武器 计算器",
  },
  "Pantsu Hirelings": {
    sp: "Contratistas de Pantsu",
    ch: "裤子雇员",
  },
  "Fall Guy": {
    sp: "Chico que cae",
    ch: "跌倒的人",
  },
  "Corporate Karma": {
    sp: "Karma Corporativo",
    ch: "企业业力",
  },
  Chaos: {
    sp: "Caos",
    ch: "混乱",
  },
  Sensorium: {
    sp: "Sensorium",
    ch: "感知器",
  },
  "AI Cloning Program": {
    sp: "Programa de Clonación de IA",
    ch: "AI克隆程序",
  },
  "Influencer Slavedriving": {
    sp: "Esclavitud de Influenciadores",
    ch: "网红奴役",
  },
  "Social Media Hacks": {
    sp: "Hackeos de Redes Sociales",
    ch: "社交媒体黑客",
  },
  "Neural Enhancement System": {
    sp: "Sistema de Mejora Neural",
    ch: "神经增强系统",
  },
  "Faustian Pact": {
    sp: "Pacto Faustiano",
    ch: "浮士德契约",
  },
  Locked: {
    sp: "Bloqueado",
    ch: "锁定",
  },
  Mute: {
    sp: "Silenciar",
    ch: "静音",
  },
  Shop: {
    sp: "Tienda",
    ch: "商店",
  },
  Next: {
    sp: "Siguiente",
    ch: "下一个",
  },
  Previous: {
    sp: "Anterior",
    ch: "上一个",
  },

  "Damage Dealt": {
    sp: "Daño Infligido",
    ch: "造成的伤害",
  },
  "DPS (Damage Per Second)": {
    sp: "DPS (Daño Por Segundo)",
    ch: "每秒伤害 (DPS)",
  },
  "Current Yield": {
    sp: "Rendimiento Actual",
    ch: "当前收益",
  },
  Cost: {
    sp: "Costo",
    ch: "成本",
  },
  "New Yield": {
    sp: "Nuevo Rendimiento",
    ch: "新收益",
  },
  Owned: {
    sp: "Poseído",
    ch: "拥有",
  },
  New: {
    sp: "Nuevo",
    ch: "新",
  },
  Story: {
    sp: "Historia",
    ch: "故事",
  },
  Astroclock: {
    sp: "Astroclock",
    ch: "星象钟",
  },
  Name: {
    sp: "Nombre",
    ch: "名字",
  },
  Level: {
    sp: "Nivel",
    ch: "等级",
  },
  Buy: {
    sp: "Comprar",
    ch: "购买",
  },
  "Nodes Tooltip Data": {
    sp: "Datos de herramienta de nodos",
    ch: "节点提示数据",
  },
  "Buffs Tooltip Data": {
    sp: "Datos de herramienta de mejoras",
    ch: "增益提示数据",
  },
  "Gears Tooltip Data": {
    sp: "Datos de herramienta de engranajes",
    ch: "装备提示数据",
  },
  Prev: {
    sp: "Anterior",
    ch: "上一个",
  },
  "PRESS ANY KEY": {
    sp: "PRESIONA CUALQUIER TECLA",
    ch: "按任意键",
  },
};
