export const activeBuffsData = [
  {
    id: "pantsu_hirelings",
    type: "Pantsu Hirelings",
    price: "15000000",
    effect: {
      increaseInValueBy: 1.25,
      applyOn: "all",
    },
    symbol: { src: "", alt: "Anime girl" },
  },
  {
    id: "fall_guy",
    type: "Fall Guy",
    price: "100000",
    effect: {
      increaseInValueBy: 1.45,
      applyOn: [
        "insider_trading",
        "sponsorship_deals",
        "pyramid_schemes",
        "cryptocurrency_mines",
        "cyber_warfare",
      ],
    },
    symbol: { src: "", alt: "Guy in an orange jumpsuit" },
  },
  {
    id: "corporate_karma",
    type: "Corporate Karma",
    price: "777",
    effect: 1.45,
    symbol: { src: "", alt: "Yin/yang symbol" },
  },
  {
    id: "chaos",
    type: "Chaos",
    price: "888888",
    effect: 1.45,
    symbol: { src: "", alt: "Chaos star" },
  },
  {
    id: "sensorium",
    type: "Sensorium",
    price: "3600000000000",
    effect: {
      discountOnPriceOf: 0.77,
      applyOn: "all",
    },
    symbol: { src: "", alt: "Pink Pill" },
  },
  {
    id: "ai_cloning_program",
    type: "AI Cloning Program",
    price: "100000000",
    effect: {
      increaseInValueBy: 1.5,
      applyOn: "all",
    },
    symbol: { src: "", alt: "Silhouette of a head" },
  },
  {
    id: "influencer_slavedriving",
    type: "Influencer Slavedriving",
    price: "5000000",
    effect: {
      increaseInValueBy: 2.5,
      applyOn: ["influencing"],
    },
    symbol: { src: "", alt: "A Whip" },
  },
  {
    id: "social_media_hacks",
    type: "Social Media Hacks",
    price: "360000000000",
    effect: {
      increaseInValueBy: 1.75,
      applyOn: ["influencing", "sponsorship_deals"],
    },
    symbol: { src: "", alt: "Twitter Icon" },
  },
  {
    id: "neural_enhancement_system",
    type: "Neural Enhancement System",
    price: "150000",
    effect: {
      increaseInValueBy: 1.2,
      applyOn: "all",
    },
    symbol: { src: "", alt: "A brain with a plus sign in the corner" },
  },
  {
    id: "faustian_pact",
    type: "Faustian Pact",
    price: "6666666666666",
    effect: {
      discountOnPriceOf: 0.85,
      applyOn: "all",
    },
    symbol: { src: "", alt: "Downward-trending chart, or red candles" },
  },
];
