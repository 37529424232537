// src/Tooltip.js
import React from "react";
import styles from "./styled.module.css";

const TooltipContent = ({ text }) => {
  return (
    <div className={styles.tooltipContent}>
      <p>{text}</p>
    </div>
  );
};

export default TooltipContent;
