export const zodiacSigns =
    [
        {
            "birthDates": ["03-21", "04-19"],
            "star": "Aries",
            "image": "1.png"
        },
        {
            "birthDates": ["04-20", "05-20"],
            "star": "Taurus",
            "image": "2.png"
        },
        {
            "birthDates": ["05-21", "06-20"],
            "star": "Gemini",
            "image": "3.png"
        },
        {
            "birthDates": ["06-21", "07-22"],
            "star": "Cancer",
            "image": "4.png"
        },
        {
            "birthDates": ["07-23", "08-22"],
            "star": "Leo",
            "image": "5.png"
        },
        {
            "birthDates": ["08-23", "09-22"],
            "star": "Virgo",
            "image": "6.png"
        },
        {
            "birthDates": ["09-23", "10-22"],
            "star": "Libra",
            "image": "7.png"
        },
        {
            "birthDates": ["10-23", "11-21"],
            "star": "Scorpio",
            "image": "8.png"
        },
        {
            "birthDates": ["11-22", "12-21"],
            "star": "Sagittarius",
            "image": "9.png"
        },
        {
            "birthDates": ["12-22", "01-19"],
            "star": "Capricorn",
            "image": "10.png"
        },
        {
            "birthDates": ["01-20", "02-18"],
            "star": "Aquarius",
            "image": "11.png"
        },
        {
            "birthDates": ["02-19", "03-20"],
            "star": "Pisces",
            "image": "12.png"
        }
    ]


