import { useEffect, useMemo, useState } from "react";
import Modal from "../common/Modal";
import RollerSelect from "../common/RollerSelect";
import styles from "./styled.module.css";
import { languagesData } from "../../site-data/languages";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../store/slices/game.slice";

export const LanguageModal = ({ isModalOpen, closeModal }) => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.game);
  const [tempLanguage, setTempLanguage] = useState(language);

  const languageSetter = (langId) => {
    dispatch(setLanguage(langId));
  };
  const handleLangChange = (lang) => {
    setTempLanguage(lang);
  };

  const confirmBtnClickHandler = () => {
    const langToSet = languagesData.find(
      (item) => item.label.toUpperCase() === tempLanguage
    );
    localStorage.setItem("GameLanguage", langToSet.id);
    languageSetter(langToSet.id);
    closeModal();
  };

  const languages = useMemo(() => {
    if (isModalOpen) {
      const lang = localStorage.getItem("GameLanguage");
      const langObjIndex = languagesData.findIndex((item) => item.id === lang);
      console.log("langObjIndex", langObjIndex);
      return langObjIndex === -1 || !langObjIndex
        ? languagesData
        : [
            languagesData[langObjIndex],
            languagesData[0],
            languagesData[langObjIndex === 1 ? 2 : 1],
          ];
    } else {
      return [];
    }
  }, [isModalOpen]);

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      {languages.length > 0 && (
        <div className={styles.languageContainer}>
          {/* <RollerSelect
            options={languages.map((item) => item.label.toUpperCase())}
            fontSize={62}
            onChange={handleLangChange}
            ones={false}
          /> */}
          <p className={styles.changeLanguageTitle}>CHANGE LANGUAGE</p>
          <div className={styles.languageOptions}>
            {languages.map((item) => (
              <span
                key={item.id}
                className={styles.languageItem}
                style={
                  item.label.toUpperCase() == tempLanguage
                    ? { backgroundColor: "#5783c3" }
                    : {}
                }
                onClick={() => handleLangChange(item.label.toUpperCase())}
              >
                {item.label.toUpperCase()}
              </span>
            ))}
          </div>
          <button
            className={styles.confirmBtn}
            onClick={confirmBtnClickHandler}
          >
            SAVE
          </button>
        </div>
      )}
    </Modal>
  );
};
