import styles from "./styled.module.css";
import { useEffect } from "react";

const RollerText = ({
  text = "<TEXT PLACEHOLDER>",
  triggerAfter = 0,
  changeTo = "",
  ...props
}) => {

  if (typeof text !== "string" || text.length === 0) {
    throw new Error("Wrong text property");
  }

  if (typeof changeTo !== "string" || text.length === 0) {
    throw new Error("Wrong text property");
  }

  return (
    <div
      style={{
        height: `${props.height === 0 || props.height == null
          ? props.fontSize === 0 || props.fontSize == null
            ? 48
            : props.fontSize
          : props.height
          }${props.unit == null || props.unit === "" ? "px" : props.unit}`,
        // width: `${props.width == 0 || props.width == null ? props.fontSize == 0 || props.fontSize == null ? 48 * text.length : props.fontSize * text.length : props.width}${props.unit == null || props.unit == "" ? "px" : props.unit}`
        width: `${props.width === 0 || props.width == null ? "" : props.width}${props.unit == null || props.unit === "" ? "px" : props.unit
          }`,
        overflow: "hidden",
        display: "flex",
        flexDirection: `${props.direction !== "down" ? "column" : "column-reverse"
          }`,
      }}
    >
      <div
        className={styles.staggerChangeTop}
        style={{
          height: `${props.height === 0 || props.height == null
            ? props.fontSize === 0 || props.fontSize == null
              ? 48
              : props.fontSize
            : props.height
            }${props.unit == null || props.unit === "" ? "px" : props.unit}`,
          // width: `${props.width == 0 || props.width == null ? props.fontSize == 0 || props.fontSize == null ? 48 * text.length : props.fontSize * text.length : props.width}${props.unit == null || props.unit == "" ? "px" : props.unit}`
          width: `${props.width === 0 || props.width == null ? "" : props.width
            }${props.unit == null || props.unit === "" ? "px" : props.unit}`,
        }}
      >
        <span
          style={{
            transform: !!changeTo
              ? `translateY(${props.direction == null ||
                props.direction === "" ||
                props.direction !== "down"
                ? props.fontSize == null || props.fontSize === 0
                  ? -48 - 10
                  : -props.fontSize - 10
                : props.fontSize == null || props.fontSize === 0
                  ? 48 - 10
                  : props.fontSize - 10
              }${props.unit == null || props.unit === "" ? "px" : props.unit
              })`
              : `translateY(-10${props.unit == null || props.unit === "" ? "px" : props.unit
              })`,
            transitionDuration: `${props.duration == null || props.duration === 0
              ? 0.3
              : props.duration
              }s`,
            transitionTimingFunction: "ease-in-out",
            transitionProperty: !!changeTo ? "transform" : "none",
            color: `${props.color === "" || props.color == null
              ? "#282828"
              : props.color
              }`,
            fontSize: `${props.fontSize === 0 || props.fontSize == null
              ? 48
              : props.fontSize
              }${props.unit == null || props.unit === "" ? "px" : props.unit}`,
            fontWeight: `${props.color === "" || props.fontWeight == null
              ? "bold"
              : props.fontWeight
              }`,
            fontFamily: `${props.fontFamily === "" || props.fontFamily == null
              ? ""
              : props.fontFamily
              }`,
          }}
        >
          {text === "&nbsp;" ? (
            <span
              style={{
                fontSize: `${props.wordSpacing == null || props.wordSpacing === 0
                  ? props.fontSize === 0 || props.fontSize == null
                    ? 48
                    : props.fontSize
                  : props.wordSpacing
                  }${props.unit == null || props.unit === "" ? "px" : props.unit
                  }`,
              }}
            >
              &nbsp;
            </span>
          ) : (
            text
          )}
        </span>
      </div>
      <div
        className={styles.staggerChangeBottom}
        style={{
          height: `${props.height === 0 || props.height == null
            ? props.fontSize === 0 || props.fontSize == null
              ? 48
              : props.fontSize
            : props.height
            }${props.unit == null || props.unit === "" ? "px" : props.unit}`,
          // width: `${props.width == 0 || props.width == null ? props.fontSize == 0 || props.fontSize == null ? 48 * text.length : props.fontSize * text.length : props.width}${props.unit == null || props.unit == "" ? "px" : props.unit}`
          width: `${props.width === 0 || props.width == null ? "" : props.width
            }${props.unit == null || props.unit === "" ? "px" : props.unit}`,
        }}
      >
        <span
          style={{
            transform: !!changeTo
              ? `translateY(${props.direction == null ||
                props.direction === "" ||
                props.direction !== "down"
                ? props.fontSize == null || props.fontSize === 0
                  ? -48 - 10
                  : -props.fontSize - 10
                : props.fontSize == null || props.fontSize === 0
                  ? 48 - 10
                  : props.fontSize - 10
              }${props.unit == null || props.unit === "" ? "px" : props.unit
              })`
              : `translateY(${props.direction == null ||
                props.direction === "" ||
                props.direction !== "down"
                ? 0
                : props.fontSize == null || props.fontSize === 0
                  ? -48 - 10 * 2
                  : -props.fontSize - 10 * 2
              }${props.unit == null || props.unit === "" ? "px" : props.unit
              })`,
            transitionDuration: `${props.duration == null || props.duration === 0
              ? 0.3
              : props.duration
              }s`,
            transitionTimingFunction: "ease-in-out",
            transitionProperty: !!changeTo ? "transform" : "none",
            color: `${props.color === "" || props.color == null
              ? "#282828"
              : props.color
              }`,
            fontSize: `${props.fontSize === 0 || props.fontSize == null
              ? 48
              : props.fontSize
              }${props.unit == null || props.unit === "" ? "px" : props.unit}`,
            fontWeight: `${props.color === "" || props.fontWeight == null
              ? "bold"
              : props.fontWeight
              }`,
            fontFamily: `${props.fontFamily === "" || props.fontFamily == null
              ? ""
              : props.fontFamily
              }`,
          }}
        >
          {changeTo === "&nbsp;" ? (
            <span
              style={{
                fontSize: `${props.wordSpacing == null || props.wordSpacing === 0
                  ? props.fontSize === 0 || props.fontSize == null
                    ? 48
                    : props.fontSize
                  : props.wordSpacing
                  }${props.unit == null || props.unit === "" ? "px" : props.unit
                  }`,
              }}
            >
              &nbsp;
            </span>
          ) : (
            changeTo
          )}
        </span>
      </div>
    </div>
  );
};

export default RollerText;
