import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import BigNumber from 'bignumber.js';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storyData } from '../../site-data/stories';
import { setLastCheckedStory } from '../../store/slices/game.slice';
import Modal from '../common/Modal';
import Tooltip from '../common/Tooltip';
import styles from './styled.module.css';
import TooltipContent from './TooltipContent';
import ToggleSwitch from '../common/ToggleSwitch';

export const DataLogModal = ({
  isModalOpen,
  closeModal,
  onDiscClick,
}) => {
  const dispatch = useDispatch();
  const {
    numberOfClicks,
    storiesProgress,
    lastCheckedStory,
    name,
    dps: DPS,
    autoRunStory,
  } = useSelector((state) => state.game);
  const allStories = useMemo(() => storyData(name || ''), [name]);
  const [lastCheckedStoryTemp, setLastCheckedStoryTemp] = useState(0);
  const [isAutoRunStory, setIsAutoRunStory] = useState(false);
  const splideRef = useRef(null);

  const stories = Object.keys(allStories).map((item) => ({
    ...allStories[item],
    id: item,
  }));

  const storiesToDisplay = '';

  const bigNumberOfClicks = new BigNumber(numberOfClicks || '0');
  const bigNumberOfDps = new BigNumber(DPS || '0');

  const splide = splideRef.current?.splide;

  const lastCheckedStorySetter = (story) => {
    dispatch(setLastCheckedStory(story));
  };

  const discClickHandler = (story, dps, index) => {
    if (
      index > 3
        ? bigNumberOfDps.isGreaterThanOrEqualTo(dps)
        : bigNumberOfClicks.isGreaterThanOrEqualTo(dps)
    ) {
      onDiscClick(story);
      closeModal();
    }
  };

  const showPrev = () => {
    splide.go('<');
  };

  const showNext = () => {
    splide.go('>');
  };

  const initialize = () => {
    const lastStory = stories.reduce(
      (maxIndex, currentObj, currentIndex) => {
        if (
          currentObj.dps <= numberOfClicks &&
          (maxIndex === -1 || currentObj.dps > stories[maxIndex].dps)
        ) {
          return currentIndex;
        }
        return maxIndex;
      },
      -1
    );

    if (lastCheckedStory) setLastCheckedStoryTemp(lastCheckedStory);
    const lastStoryToSave = lastStory + 1;
    localStorage.setItem(
      'GameLastCheckedStory',
      lastStoryToSave.toString()
    );
    lastCheckedStorySetter(lastStoryToSave);
  };

  const onChangeAutoRunStory = () => {
    setIsAutoRunStory(!isAutoRunStory);
    localStorage.setItem('GameAutoRunStory', !isAutoRunStory);
  };

  useEffect(() => {
    if (isModalOpen) {
      initialize();

      return () => {
        setLastCheckedStoryTemp(0);
      };
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (autoRunStory) {
      setIsAutoRunStory(autoRunStory);
    }
  }, [autoRunStory]);

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      <div className={styles.shopContainer}>
        <div className={styles.itemSlider}>
          <Splide
            ref={splideRef}
            options={{
              perPage: 6,
              focus: 0,
              omitEnd: true,
              pagination: false,
              arrows: false,
            }}
            style={{
              width: '80%',
              paddingBottom: '10%',
              marginTop: '10%',
            }}
          >
            {/* {stories.filter((_, index) => index <= 15).map(({ id, dps }, index) => { */}
            {stories.map(({ id, dps, title }, index) => {
              const progressEntry = storiesProgress?.find(
                (entry) => Object.keys(entry)[0] === id
              );
              const progress = progressEntry
                ? progressEntry[id][0]
                : 0;
              const isCompleted = progressEntry
                ? progressEntry[id][1]
                : false;
              const isPreviousCompleted =
                index === 0 ||
                storiesProgress?.find((entry) => {
                  const prevStory = Object.keys(entry)[0];
                  return (
                    prevStory === stories[index - 1]?.id &&
                    entry[prevStory][1]
                  );
                });

              const greaterThanBigNumber =
                index > 3
                  ? bigNumberOfDps.isGreaterThanOrEqualTo(dps)
                  : bigNumberOfClicks.isGreaterThanOrEqualTo(dps);

              const lessThanBigNumber =
                index > 3
                  ? bigNumberOfDps.isLessThan(dps)
                  : bigNumberOfClicks.isLessThan(dps);

              return (
                <SplideSlide key={id}>
                  <Tooltip
                    content={
                      !isPreviousCompleted && greaterThanBigNumber
                        ? 'Complete previous stories first'
                        : index > 3
                        ? bigNumberOfDps.isGreaterThanOrEqualTo(
                            dps
                          ) && <TooltipContent text={title} />
                        : bigNumberOfClicks.isGreaterThanOrEqualTo(
                            dps
                          ) && <TooltipContent text={title} />
                    }
                  >
                    <button
                      className={styles.item}
                      onClick={() => discClickHandler(id, dps, index)}
                      disabled={lessThanBigNumber}
                      style={{
                        pointerEvents: `${
                          isPreviousCompleted ? 'all' : 'none'
                        }`,
                      }}
                    >
                      <div className={styles.itemContent}>
                        {greaterThanBigNumber &&
                          lastCheckedStoryTemp < index + 1 && (
                            <p className={styles.newTag}>NEW!</p>
                          )}
                        {isCompleted && (
                          <p className={styles.viewed}>Viewed!</p>
                        )}
                        <img
                          alt={id}
                          src={`assets/Data_log/${
                            greaterThanBigNumber
                              ? 'disc_active'
                              : 'disc_deactive'
                          }.png`}
                        />
                        <div
                          className={styles.progressBar}
                          style={{
                            width: `${progress}%`,
                          }}
                        ></div>
                      </div>
                    </button>
                  </Tooltip>
                </SplideSlide>
              );
            })}
          </Splide>
          <div className={`${styles.splideArrows}`}>
            <button onClick={showPrev} className={styles.arrowImg}>
              <img
                alt=""
                src={'assets/Data_log/story_left_arrow.png'}
              />
            </button>
            <button onClick={showNext} className={styles.arrowImg}>
              <img
                alt=""
                src={'assets/Data_log/story_right_arrow.png'}
              />
            </button>
          </div>
        </div>
        <div className={styles.autoRunStoryContainer}>
          <ToggleSwitch
            onChange={onChangeAutoRunStory}
            isChecked={isAutoRunStory}
          />
        </div>
      </div>
    </Modal>
  );
};
