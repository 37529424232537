import BigNumber from "bignumber.js";
import { useSelector } from "react-redux";

export const formatCompactNumber = (num) => {
  if (num < 10) {
    return num.toFixed(2);
  } else if (num < 1000) {
    return num.toString();
  } else if (num >= 1000 && num < 1_000_000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  } else if (num >= 1_000_000 && num < 1_000_000_000) {
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (num >= 1_000_000_000 && num < 1_000_000_000_000) {
    return (num / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
  } else if (num >= 1_000_000_000_000 && num < 1_000_000_000_000_000) {
    return (num / 1_000_000_000_000).toFixed(1).replace(/\.0$/, "") + "T";
  } else if (num >= 1_000_000_000_000_000 && num < 1_000_000_000_000_000_000) {
    return (num / 1_000_000_000_000_000).toFixed(1).replace(/\.0$/, "") + "Q";
  }
};

export const formatCompactNumberNew = (bigNum) => {
  if (bigNum.isLessThan(10)) {
    return bigNum.toFixed(2);
  } else if (bigNum.isLessThan(1000)) {
    return bigNum.integerValue(BigNumber.ROUND_FLOOR).toString();
  } else if (
    bigNum.isGreaterThanOrEqualTo(1000) &&
    bigNum.isLessThan(1_000_000)
  ) {
    return bigNum.dividedBy(1000).toFixed(1).replace(/\.0$/, "") + "K";
  } else if (
    bigNum.isGreaterThanOrEqualTo(1_000_000) &&
    bigNum.isLessThan(1_000_000_000)
  ) {
    return bigNum.dividedBy(1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (
    bigNum.isGreaterThanOrEqualTo(1_000_000_000) &&
    bigNum.isLessThan(1_000_000_000_000)
  ) {
    return bigNum.dividedBy(1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
  } else if (
    bigNum.isGreaterThanOrEqualTo(1_000_000_000_000) &&
    bigNum.isLessThan(1_000_000_000_000_000)
  ) {
    return (
      bigNum.dividedBy(1_000_000_000_000).toFixed(1).replace(/\.0$/, "") + "T"
    );
  } else if (
    bigNum.isGreaterThanOrEqualTo(1_000_000_000_000_000) &&
    bigNum.isLessThan(new BigNumber("1_000_000_000_000_000_000"))
  ) {
    return (
      bigNum.dividedBy(1_000_000_000_000_000).toFixed(1).replace(/\.0$/, "") +
      "Q"
    );
  }

  return bigNum.toString(); 
};