// src/Tooltip.js
import React from "react";
import styles from "./styled.module.css";
import { formatCompactNumber } from "../../functions";

const TooltipContent = ({ data }) => {
  const { type, level, effect } = data;

  return (
    <div className={styles.tooltipContent}>
      <p>Name: {type}</p>
      <p>Level: {level}</p>
      {/* <p>Increase Yield by: {formatCompactNumber(Math.floor(effect))}</p> */}
      <p>{`${
        effect["increaseValueInMultiple"] > 1
          ? `Increase in value by ${effect["increaseValueInMultiple"]}`
          : `Decrease in value by ${effect["reducesPeriodeBy"]}`
      }`}</p>
    </div>
  );
};

export default TooltipContent;
