import React, { useEffect } from 'react';
import Modal from '../common/Modal';
import styles from './styled.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '../common/DatePicker';
import { setBirthday } from '../../store/slices/game.slice';

export const BirthdayModal = ({ isModalOpen, closeModal }) => {
  const dispatch = useDispatch();
  const { birthday } = useSelector((state) => state.game);

  const birthdaySelectHandler = (date) => {
    localStorage.setItem('GameBirthday', JSON.stringify(date));
    dispatch(setBirthday(date));
    closeModal();
  };

  const initialize = () => {
    if (birthday) {
      closeModal();
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      initialize();
    }
  }, [isModalOpen]);

  return (
    <Modal isOpen={isModalOpen}>
      <div className={styles.birthdayContainer}>
        <p>Enter Your Birthday:</p>
        <DatePicker onConfirm={birthdaySelectHandler} />
      </div>
    </Modal>
  );
};
