import { createSlice } from "@reduxjs/toolkit";

export const configSlice = createSlice({
  name: "config",
  initialState: {
    audio: true,
  },
  reducers: {
    setAudio: (state) => {
      state.audio = !state.audio;
    },
  },
});

export const { setAudio } = configSlice.actions;

export default configSlice.reducer;
