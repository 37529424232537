import { useSelector } from "react-redux";
import styles from "./styled.module.css";
import { nodesData, passiveIncomeData } from "../../site-data/nodes";

export default function SelectedNodes() {
  const { bonusLevels } = useSelector((state) => state.game);

  const filteredNodesList = nodesData.reduce((acc, item, index) => {
    if (bonusLevels?.[item.id] !== undefined) {
      acc.push({
        ...item,
        level: bonusLevels[item.id],
        indexValue: index,
      });
    }
    return acc;
  }, []);

  const filteredPassiveIncomeList = passiveIncomeData.reduce(
    (acc, item, index) => {
      if (bonusLevels?.[item.id] !== undefined) {
        acc.push({
          ...item,
          level: bonusLevels[item.id],
          indexValue: index + 4,
        });
      }
      return acc;
    },
    []
  );

  const selectedNodesData = [
    ...filteredNodesList,
    ...filteredPassiveIncomeList,
  ];

  return (
    <div className={styles.selectedNodes}>
      {selectedNodesData.length > 0 &&
        selectedNodesData.map((item) => (
          <div key={item.id} className={styles.selectedNode}>
            <img alt={item.id} src={`assets/Node/${item.indexValue + 1}.png`} />
            <sup className={styles.sup}>{item.level > 1 && item.level}</sup>
            {/* {item} */}
          </div>
        ))}
      {/* <div className={styles.selectedNode}>Node 1 Icon</div>
      <div className={styles.selectedNode}>Node 2 Icon</div>
      <div className={styles.selectedNode}>Node 3 Icon</div> */}
    </div>
  );
}
