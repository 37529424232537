import { createSlice } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';

export const gameSlice = createSlice({
  name: 'game',
  initialState: {
    damageDealt: null,
    numberOfClicks: null,
    perClick: null,
    nodes: null,
    passiveIncome: null,
    bonusLevels: null,
    dps: null,
    activeBuffs: null,
    gearsLevels: null,
    storiesProgress: null,
    lastCheckedStory: null,
    birthday: null,
    name: null,
    birthdayAvailCount: null,
    language: null,
    muted: false,
    autoRunStory: false,
  },
  reducers: {
    setDamageDealt: (state, action) => {
      state.damageDealt = action.payload;
    },
    setNumberOfClicks: (state, action) => {
      state.numberOfClicks = action.payload;
    },
    setClicks: (state, action) => {
      if (state.damageDealt) {
        state.damageDealt = new BigNumber(state.damageDealt)
          .plus(new BigNumber(action.payload))
          .toString();
      }
      if (state.numberOfClicks) {
        state.numberOfClicks = new BigNumber(state.numberOfClicks)
          .plus(new BigNumber(action.payload))
          .toString();
      }
    },
    setPerClick: (state, action) => {
      state.perClick = action.payload;
    },
    increasePerClick: (state) => {
      if (state.perClick) {
        state.perClick *= 1.1;
      }
      state.birthdayAvailCount = state.birthdayAvailCount
        ? state.birthdayAvailCount + 1
        : 1;
    },
    setAutoClicker: (state, action) => {
      if (state.damageDealt) {
        state.damageDealt = new BigNumber(state.damageDealt)
          .plus(new BigNumber(action.payload))
          .toString();
      }
    },
    setNodes: (state, action) => {
      state.nodes = action.payload;
    },
    setSingleNode: (state, action) => {
      if (state.nodes) {
        const { index, value } = action.payload;
        state.nodes[index] = value;
      }
    },
    setAutoRunStory: (state, action) => {
      state.autoRunStory = action.payload;
    },
    setPassiveIncome: (state, action) => {
      state.passiveIncome = action.payload;
    },
    setSinglePassiveIncome: (state, action) => {
      if (state.passiveIncome) {
        const { index, value } = action.payload;
        state.passiveIncome[index] = value;
      }
    },
    setBonusLevels: (state, action) => {
      state.bonusLevels = action.payload;
    },
    setDPS: (state, action) => {
      state.dps = action.payload;
    },
    setActiveBuffs: (state, action) => {
      state.activeBuffs = action.payload;
    },
    setGearsLevels: (state, action) => {
      state.gearsLevels = action.payload;
    },
    setStoriesProgress: (state, action) => {
      state.storiesProgress = action.payload;
    },
    setLastCheckedStory: (state, action) => {
      state.lastCheckedStory = action.payload;
    },
    setBirthday: (state, action) => {
      state.birthday = { value: action.payload, availCount: 0 };
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setBirthdayAvailCount: (state, action) => {
      state.birthdayAvailCount = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setMuted: (state, action) => {
      state.muted = action.payload;
    },
  },
});

export const {
  setDamageDealt,
  setNumberOfClicks,
  setClicks,
  setPerClick,
  increasePerClick,
  setAutoClicker,
  setNodes,
  setSingleNode,
  setPassiveIncome,
  setSinglePassiveIncome,
  setBonusLevels,
  setActiveBuffs,
  setGearsLevels,
  setStoriesProgress,
  setLastCheckedStory,
  setBirthday,
  setBirthdayAvailCount,
  setLanguage,
  setMuted,
  setName,
  setDPS,
  setAutoRunStory,
} = gameSlice.actions;

export default gameSlice.reducer;
