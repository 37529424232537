import React, { useState, useEffect, useRef, useMemo } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { storyData } from '../../site-data/stories/index';
import styles from './styled.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setStoriesProgress } from '../../store/slices/game.slice';
import { usePlaySFX } from '../../hooks';

export const Story = ({ storyToPlay, onClose }) => {
  const dispatch = useDispatch();
  const { storiesProgress, name, autoRunStory } = useSelector(
    (state) => state.game
  );

  const { audio: audioState } = useSelector((state) => state.config);
  const [currentSceneIndex, setCurrentSceneIndex] = useState(0);
  const [text, setText] = useState('');
  const [audio, setAudio] = useState(null);
  const [isScenePlaying, setIsScenePlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [goBackAllowed, setGoBackAllowed] = useState(false);
  const [audioDuration, setAudioDuration] = useState(0);
  const [isTextCompleted, setIsTextCompleted] = useState(false);
  const [isAudioCompleted, setIsAudioCompleted] = useState(false); // New state
  const [isTyping, setIsTyping] = useState(false);
  const controls = useAnimation();
  const timeoutRef = useRef(null);
  const [annieImg, setAnnieImg] = useState(
    '/assets/Story/Characters/Annie_1.png'
  );
  const [mayImg, setMayImg] = useState(
    '/assets/Story/Characters/May_1.png'
  );
  const AnnieImageControls = useAnimation();
  const MayImageControls = useAnimation();
  const reactionControls = useAnimation();
  const allStories = useMemo(() => storyData(name || ''), [name]);
  const scenes = allStories[storyToPlay]?.scenes || [];
  const playSFX = usePlaySFX();

  const lang = localStorage.getItem('GameLanguage');

  const storiesProgressSetter = (progressData) => {
    dispatch(setStoriesProgress(progressData));
  };

  const playAudio = (newAudio) => {
    if (newAudio) {
      newAudio.play();
      newAudio.volume = !audioState ? 0 : 1;
      newAudio.onended = () => {
        setIsAudioCompleted(true); // Mark audio as completed when it finishes
        setIsScenePlaying(false);
      };
    }
  };

  const closeAudio = () => {
    if (audio) {
      audio.pause();
      audio.src = '';
    }
  };

  const getAudioDuration = (audioPath) => {
    return new Promise((resolve) => {
      const audio = new Audio(audioPath);
      const onLoadedMetadata = () => {
        resolve(audio.duration * 1000);
        audio.removeEventListener('loadedmetadata', onLoadedMetadata);
      };
      audio.addEventListener('loadedmetadata', onLoadedMetadata);
    });
  };

  const closeHandler = () => {
    playSFX('/assets/SFX/P2W_SFX_Click_close_01.wav');
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    closeAudio();
    onClose(progress);
  };

  const updateProgress = (newProgress) => {
    let updatedProgress = storiesProgress
      ? JSON.parse(JSON.stringify(storiesProgress))
      : [];

    const progressValue = newProgress >= 100;

    setProgress(newProgress);

    const storyIndex = updatedProgress.findIndex(
      (entry) => Object.keys(entry)[0] === storyToPlay
    );

    if (storyIndex !== -1) {
      updatedProgress[storyIndex][storyToPlay] = [
        newProgress,
        updatedProgress[storyIndex][storyToPlay][1] || progressValue,
      ];
    } else {
      updatedProgress.push({
        [storyToPlay]: [newProgress, progressValue],
      });
    }

    localStorage.setItem(
      'GameStoriesProgress',
      JSON.stringify(updatedProgress)
    );
    storiesProgressSetter(updatedProgress);
    setProgress(newProgress);
  };

  const resetProgress = () => {
    let updatedProgress = storiesProgress
      ? JSON.parse(JSON.stringify(storiesProgress))
      : [];

    const storyIndex = updatedProgress.findIndex(
      (entry) => Object.keys(entry)[0] === storyToPlay
    );

    if (storyIndex !== -1) {
      updatedProgress[storyIndex][storyToPlay] = [0, 'false'];
    } else {
      updatedProgress.push({ [storyToPlay]: [0, 'false'] });
    }

    localStorage.setItem(
      'storiesProgress',
      JSON.stringify(updatedProgress)
    );
    storiesProgressSetter(updatedProgress);
    setCurrentSceneIndex(0);
    setProgress(0);
  };

  const startScene = async (scene, newAudio) => {
    setIsScenePlaying(true);
    setIsAudioCompleted(false);
    const duration = await getAudioDuration(scene.audio);
    setAudioDuration(duration);
    controls.start({
      opacity: 1,
      transition: { duration: 0.5 },
    });

    playAudio(newAudio);
    startTypingAnimation(
      scene.dialog[lang] || scene.dialog.en,
      duration
    );
  };

  const startTypingAnimation = (
    dialog,
    duration,
    immediate = false
  ) => {
    let index = 0;
    setIsTyping(true);
    setIsTextCompleted(false);

    clearInterval(window.typingInterval);

    if (immediate) {
      setText(dialog);
      setIsScenePlaying(false);
      setIsTyping(false);
      setIsTextCompleted(true);
    } else {
      window.typingInterval = setInterval(() => {
        if (index < dialog.length) {
          setText(dialog.slice(0, index + 1));
          index++;
        } else {
          clearInterval(window.typingInterval);
          setIsScenePlaying(false);
          setIsTyping(false);
          setIsTextCompleted(true);
        }
      }, duration / dialog.length);
    }
  };

  const handleNextClick = () => {
    if (currentSceneIndex < scenes.length - 1) {
      if (!isTextCompleted) {
        if (audio) {
          audio.pause();
          audio.src = '';
        }
        startTypingAnimation(
          scenes[currentSceneIndex].dialog[lang || 'en'],
          1000,
          true
        );
        setIsTextCompleted(true);
        setIsAudioCompleted(true);
      } else if (isTextCompleted && isAudioCompleted) {
        setIsTextCompleted(false);
        setIsAudioCompleted(false);
        setCurrentSceneIndex((prev) => prev + 1);
      }
    }
  };

  useEffect(() => {
    if (
      autoRunStory &&
      isTextCompleted &&
      isAudioCompleted &&
      currentSceneIndex < scenes.length - 1
    ) {
      setTimeout(() => {
        setCurrentSceneIndex((prev) => prev + 1);
      }, 1000);
    }
  }, [isTextCompleted, isAudioCompleted, autoRunStory]);

  useEffect(() => {
    if (!storyToPlay || !storiesProgress) {
      setCurrentSceneIndex(0);
      return;
    }

    const storyProgress = storiesProgress.find(
      (entry) => entry[storyToPlay] !== undefined
    );

    if (!storyProgress) {
      setCurrentSceneIndex(0);
      return;
    }

    const [progress, goBackValue] = storyProgress[storyToPlay];

    if (goBackValue !== goBackAllowed) {
      setGoBackAllowed(goBackValue);
    }

    const savedIndex =
      progress < 100
        ? Math.floor((progress / 100) * (scenes.length - 1))
        : 0;

    setCurrentSceneIndex(savedIndex);
  }, [storyToPlay, scenes.length]);

  function extractStoryNumber(storyString) {
    const match = storyString.match(/story#(\d+)/);
    if (match) {
      return parseInt(match[1], 10);
    }
    return null;
  }

  useEffect(() => {
    if (
      currentSceneIndex !== null &&
      currentSceneIndex < scenes.length
    ) {
      const scene = scenes[currentSceneIndex];

      closeAudio();
      const audioPath = `assets/Story/Audios/VO/Scene${extractStoryNumber(
        storyToPlay
      )}/vo_${currentSceneIndex + 1}.wav`;

      scene.audio = extractStoryNumber(storyToPlay)
        ? audioPath
        : scene.audio;
      const newAudio = new Audio(scene.audio);

      newAudio.addEventListener('canplaythrough', () => {
        if (
          !name &&
          storyToPlay === 'story#2' &&
          currentSceneIndex == 5
        )
          return;
        startScene(scene, newAudio);
        setAudio(newAudio);
      });

      const newProgress =
        ((currentSceneIndex + 1) / scenes.length) * 100;
      updateProgress(newProgress);
    }
  }, [currentSceneIndex, scenes]);

  useEffect(() => {
    if (!isScenePlaying && progress >= 100) {
      if (!goBackAllowed) setGoBackAllowed(true);

      timeoutRef.current = setTimeout(() => {
        onClose(progress);
      }, 1000);
    }
  }, [isScenePlaying]);

  useEffect(() => {
    reactionControls.start({
      scale: [2.5, 1],
      opacity: [0, 1],
      transition: { duration: 0.2 },
    });

    if (currentSceneIndex !== null && isSpeakingCharacterAnnie) {
      setAnnieImg(image_type);

      if (image_type !== annieImg) {
        AnnieImageControls.start({
          x: [75, 0],
          opacity: [0, 1],
          transition: { duration: 0.5 },
        });
      }
    }

    if (currentSceneIndex !== null && isSpeakingCharacterMay) {
      setMayImg(image_type);

      if (image_type !== mayImg) {
        MayImageControls.start({
          x: [-75, 0],
          opacity: [0, 1],
          transition: { duration: 0.5 },
        });
      }
    }
  }, [currentSceneIndex]);

  useEffect(() => {
    if (audio) {
      audio.volume = audioState ? 1 : 0;
    }
  }, [audio, audioState]);

  if (currentSceneIndex === null) {
    return null;
  }

  const currentScene = scenes[currentSceneIndex] || {};
  const { character, image_type, reaction } = currentScene;

  const isSpeakingCharacterAnnie = character === 'annie';
  const isSpeakingCharacterMay = character === 'may';

  return (
    <div className={styles.dialogueContainer}>
      <div className={styles.upperPart}>
        <div className={styles.background}>
          <motion.div
            className={`${styles.reaction} ${
              isSpeakingCharacterAnnie
                ? styles.annieReaction
                : styles.mayReaction
            }`}
            animate={reactionControls}
          >
            {reaction !== '' ? (
              <img src={reaction} alt="Reaction" />
            ) : (
              ''
            )}
          </motion.div>
          <motion.div
            className={`${styles.character} ${styles.rightCharacter}`}
            style={{
              filter: isSpeakingCharacterMay
                ? 'grayscale(0)'
                : 'grayscale(0.75)',
            }}
            animate={MayImageControls}
          >
            <img
              src={isSpeakingCharacterMay ? image_type : mayImg}
              alt="May"
            />
          </motion.div>
          <motion.div
            className={`${styles.character} ${styles.leftCharacter}`}
            style={{
              filter: isSpeakingCharacterAnnie
                ? 'grayscale(0)'
                : 'grayscale(0.75)',
            }}
            animate={AnnieImageControls}
          >
            <img
              src={isSpeakingCharacterAnnie ? image_type : annieImg}
              alt="Annie"
            />
          </motion.div>
        </div>
      </div>
      <div
        className={`${styles.lowerPart} ${
          isSpeakingCharacterMay ? styles.mayBubble : ''
        }`}
      >
        <div className={styles.progressBarContainer}>
          <div
            className={styles.progressBar}
            style={{
              width: `${progress}%`,
              transitionDuration: `${
                audioDuration ? audioDuration : 500
              }ms`,
            }}
          ></div>
        </div>
        <h1
          className={`${styles.speaker} ${
            isSpeakingCharacterMay ? styles.speakerOffset : ''
          }`}
        >
          {isSpeakingCharacterAnnie ? 'Annie' : 'May'}
        </h1>
        <motion.div
          className={styles.dialogueBox}
          animate={controls}
          initial={{ opacity: 0 }}
        >
          <div className={styles.dialogueText}>{text}</div>
        </motion.div>
        <button className={styles.nextBtn} onClick={handleNextClick}>
          NEXT
        </button>
        <button
          className={`${styles.prevBtn} ${
            isScenePlaying || currentSceneIndex < 1
              ? styles.disabled
              : ''
          }`}
          onClick={() => {
            if (!isScenePlaying && currentSceneIndex > 0) {
              setCurrentSceneIndex((prev) => prev - 1);
            }
          }}
          disabled={isScenePlaying}
        >
          PREV
        </button>
        {goBackAllowed && (
          <button className={styles.backBtn} onClick={closeHandler}>
            <img
              src={`/assets/Story/Ui/cross/bubble ${
                isSpeakingCharacterMay ? 'may' : 'annie'
              }.png`}
            />
          </button>
        )}
      </div>
    </div>
  );
};
