import React from "react";
import styles from "./styled.module.css";
import { useTranslation } from "../../hooks";

export const Preload = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.preloadContainer}>
      <div className={styles.preloadContent}>
        <img src="/assets/preload_logo.png" alt="Preload" width={"400"} />
        <h1>[ {t("PRESS ANY KEY")} ]</h1>
      </div>
    </div>
  );
};
