import { useMemo, useState, useEffect } from 'react';
import { siteContentData } from '../site-data/content';
import { useSelector } from 'react-redux';

export const useTranslation = () => {
  const { language } = useSelector((state) => state.game);

  const lang = useMemo(
    () => localStorage.getItem('GameLanguage'),
    []
  );

  const langSaved = language || lang;

  const t = (value) => {
    return (
      (langSaved &&
        langSaved !== 'en' &&
        siteContentData[value]?.[langSaved]) ||
      value
    );
  };

  return { language: langSaved, t };
};

export const usePlaySFX = () => {
  const { audio: audioState } = useSelector((state) => state.config);

  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    const handleUserInteraction = () => {
      setHasInteracted(true);
      console.log('User interact');

      window.removeEventListener('click', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
      window.removeEventListener('touchstart', handleUserInteraction);
    };

    window.addEventListener('click', handleUserInteraction);
    window.addEventListener('keydown', handleUserInteraction);
    window.addEventListener('touchstart', handleUserInteraction);

    return () => {
      window.removeEventListener('click', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
      window.removeEventListener('touchstart', handleUserInteraction);
    };
  }, []);

  const playSFX = (audio) => {
    if (audioState) {
      const sfx = new Audio(audio);
      sfx.play();
    }
  };

  return playSFX;
};
