import React from "react";
import Modal from "../common/Modal";
import styles from "./styled.module.css";

export const SceneRModal = ({ isModalOpen, closeModal, onSelectCharacter }) => {
  return (
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      <div className={styles.characterContainer}>
        <p>Choose your partner in crime</p>
        <div className={styles.chooseNow}>
          <img
            alt="Annie"
            src="/assets/Story/Characters/Annie_1.png"
            onClick={() => onSelectCharacter("annie_scene_R")}
          />
          <img
            alt="May"
            src="/assets/Story/Characters/May_1.png"
            onClick={() => onSelectCharacter("may_scene_R")}
          />
        </div>
      </div>
    </Modal>
  );
};
