import { useState, useEffect } from "react";
import styles from "./styled.module.css";

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <div
      className={`${styles.modalMain} ${isOpen ? styles.open : ""}`}
      onClick={onClose}
    >
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        {onClose && (
          <button className={styles.closeButton} onClick={onClose}>
            X
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
