import styles from "./styled.module.css";
import MainGameArea from "../../components/MainGameArea";
import SelectedNodes from "../../components/SelectedNodes";
import LibraryArea from "../../components/LibraryArea";
import Gears from "../../components/Gears";
import { Story } from "../../components/Story";

export default function Left() {
  return (
    <div className={styles.left}>
      <MainGameArea />
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "50%",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <SelectedNodes />
          <LibraryArea />
        </div>
        <Gears />
      </div>
    </div>
  );
}
