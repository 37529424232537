import React, { useState, useEffect } from 'react';
import { zodiacSigns } from '../../site-data/zodiac';
import styles from './styled.module.css';
import { BirthdayMessageModal } from '../BirthdayMessageModal';
import {
  increasePerClick,
  setBirthdayAvailCount,
} from '../../store/slices/game.slice';
import { usePlaySFX } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';

const AstroclockEnabled = ({ birthday, playSceneR }) => {
  const dispatch = useDispatch();
  const { birthdayAvailCount } = useSelector((state) => state.game);
  const [rotation, setRotation] = useState(-4);
  const [birthdayMessage, setBirthdayMessage] = useState('');
  const [activeStar, setActiveStar] = useState(null);
  const [currentDay, setCurrentDay] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const playSFX = usePlaySFX();

  const isActive = (index) => {
    const [startMonth, startDay] = zodiacSigns[index].birthDates[0]
      .split('-')
      .map(Number);
    const [endMonth, endDay] = zodiacSigns[index].birthDates[1]
      .split('-')
      .map(Number);

    const startDate = new Date(2050, startMonth - 1, startDay);
    let endDate = new Date(2050, endMonth - 1, endDay);

    if (
      startDate < endDate
        ? startDate > birthday || birthday > endDate
        : startDate > birthday && birthday > endDate
    ) {
      return false;
    }

    const currentDate = new Date(2050, 0, 1 + currentDay);

    if (endDate < startDate) {
      endDate.setFullYear(endDate.getFullYear() + 1);
    }

    if (
      currentDate < startDate &&
      endDate.getFullYear() > currentDate.getFullYear()
    ) {
      endDate.setFullYear(endDate.getFullYear() - 1);
    }

    const value = currentDate.getTime() === startDate.getTime();

    if (value) {
      setActiveStar(zodiacSigns[index].star);
    }

    return value;
  };

  const starClickHandler = () => {
    if (activeStar) {
      playSFX('/assets/SFX/P2W_SFX_Astroclock_Click_02.wav');
      localStorage.setItem(
        'GameBirthdayAvailCount',
        ((birthdayAvailCount || 0) + 1).toString()
      );
      dispatch(increasePerClick());

      const condition = Math.random() < 0.3;
      if (condition) playSceneR();
      else setBirthdayMessage("It's your birth month!");

      setActiveStar(null);
    } else {
      playSFX('/assets/SFX/P2W_SFX_Astroclock_Click_01.wav');
    }
  };

  const birthdayMsgModalCloseHandler = () => {
    setBirthdayMessage('');
  };

  useEffect(() => {
    const storedDay = Number(localStorage.getItem('inGameDay')) || 0;
    const storedYear =
      Number(localStorage.getItem('inGameYear')) || 2050;
    setCurrentDay(storedDay);
    setCurrentYear(storedYear);

    const initialRotation = storedDay * (360 / 365);
    setRotation(initialRotation);

    const dayInterval = setInterval(() => {
      const updatedDay = currentDay + 1;
      const updatedYear =
        updatedDay >= 365 ? currentYear + 1 : currentYear;
      localStorage.setItem('inGameDay', updatedDay % 365);
      localStorage.setItem('inGameYear', updatedYear);

      setCurrentDay(updatedDay % 365);
      setCurrentYear(updatedYear);
      setRotation(updatedDay * (360 / 365));
    }, 40);

    const yearInterval = setInterval(() => {
      const updatedDay = currentDay + 365;
      const updatedYear = currentYear + 1;
      localStorage.setItem('inGameDay', updatedDay % 365);
      localStorage.setItem('inGameYear', updatedYear);
      setCurrentDay(updatedDay % 365);
      setCurrentYear(updatedYear);
      setRotation(updatedDay * (360 / 365));
    }, 1800000);

    return () => {
      clearInterval(dayInterval);
      clearInterval(yearInterval);
    };
  }, [currentDay, currentYear]);

  useEffect(() => {
    playSFX('/assets/SFX/P2W_SFX_Astroclock_Activate.wav');
    const birthdayAvailCountValue = localStorage.getItem(
      'GameBirthdayAvailCount'
    );
    if (birthdayAvailCountValue)
      dispatch(
        setBirthdayAvailCount(parseInt(birthdayAvailCountValue))
      );
  }, []);

  if (
    typeof currentDay !== 'number' ||
    typeof currentYear !== 'number'
  )
    return null;

  return (
    <>
      <div className={styles.astroclock}>
        <img
          className={styles.needle}
          src={'/assets/Clock/needle 1.png'}
          alt="Clock Needle"
        />
        <div
          className={styles.clockFace}
          style={{ transform: `rotate(${-rotation}deg)` }}
        >
          <div className={styles.indicator}>
            {zodiacSigns.map((sign, i) => {
              const startDate = new Date(
                `2050-${sign.birthDates[0]}`
              );
              const startDay = Math.floor(
                (startDate - new Date(2050, 0, 1)) /
                  (1000 * 60 * 60 * 24)
              );
              const signRotation = startDay * (360 / 365);

              return (
                <img
                  key={i}
                  src={`/assets/Clock sign/1 ${sign.image}`}
                  className={`${styles.image} ${
                    activeStar === sign.star || isActive(i)
                      ? styles.active
                      : ''
                  }`}
                  style={{
                    transform: `rotate(${signRotation}deg) rotateY(180deg) translate(0, 500%)`,
                  }}
                  alt={sign.star}
                  onClick={starClickHandler}
                />
              );
            })}
          </div>
        </div>
      </div>
      <BirthdayMessageModal
        message={birthdayMessage}
        isModalOpen={!!birthdayMessage}
        closeModal={birthdayMsgModalCloseHandler}
      />
    </>
  );
};

export const AstroclockDisabled = () => {
  return (
    <div className={styles.astroclockDisabled}>
      <img
        className={styles.needle}
        src={'/assets/Clock/needle 2.png'}
        alt="Clock Needle"
      />
      <div className={styles.clockFaceDisabled}></div>
    </div>
  );
};

export const AstroclockAvaialble = () => {
  const playSFX = usePlaySFX();

  useEffect(() => {
    playSFX('/assets/SFX/P2W_SFX_Astroclock_Activate.wav');
  }, []);

  return (
    <div className={styles.astroclockAvailable}>
      <img
        className={styles.needle}
        src={'/assets/Clock/needle 1.png'}
        alt="Clock Needle"
      />
      <div className={styles.clockFaceAvailable}></div>
    </div>
  );
};

const Astroclock = ({ disabled, birthday, playSceneR }) => {
  return disabled && !birthday ? (
    <AstroclockDisabled />
  ) : disabled && birthday ? (
    <AstroclockAvaialble />
  ) : (
    birthday && (
      <AstroclockEnabled
        birthday={birthday}
        playSceneR={playSceneR}
      />
    )
  );
};

export default Astroclock;
