import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './styled.module.css';
import { DataLogModal } from '../DataLogModal';
import { Story } from '../Story';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAutoRunStory,
  setBirthday,
  setDPS,
  setLastCheckedStory,
  setName,
  setStoriesProgress,
} from '../../store/slices/game.slice';
import { storyData } from '../../site-data/stories';
import Astroclock from '../Astroclock';
import { BirthdayModal } from '../BirthdayModal';
import { AstroclockDisabled } from '../Astroclock';
import { useTranslation } from '../../hooks';
import BigNumber from 'bignumber.js';
import { SceneRModal } from '../SceneRModal';
import { usePlaySFX } from '../../hooks';
import { NameModal } from '../NameModal';

export default function LibraryArea() {
  const dispatch = useDispatch();
  const {
    storiesProgress,
    lastCheckedStory,
    numberOfClicks,
    dps: DPS,
    birthday,
    name,
  } = useSelector((state) => state.game);
  const [storyModalOpen, setStoryModalOpen] = useState(false);
  const [birthdayModalOpen, setBirthdayModalOpen] = useState(false);
  const [nameModalOpen, setNameModalOpen] = useState(false);
  const [sceneRModalOpen, setSceneRModalOpen] = useState(false);
  const [playStory, setPlayStory] = useState('');
  const [playedStories, setPlayedStories] = useState(new Set());
  const playSFX = usePlaySFX();
  const allStories = useMemo(() => storyData(name || ''), [name]);
  const { t } = useTranslation();

  const storyDisabledCondition =
    !lastCheckedStory &&
    new BigNumber(
      lastCheckedStory > 4 ? DPS : numberOfClicks || '0'
    ).isLessThan(
      allStories[`story#${(lastCheckedStory || 0) + 1}`]?.dps
    );

  const storiesProgressSetter = (progress) => {
    dispatch(setStoriesProgress(progress));
  };

  const lastCheckedStorySetter = (story) => {
    dispatch(setLastCheckedStory(story));
  };

  const birthdaySetter = (date) => {
    dispatch(setBirthday(date));
  };

  const onDiscClick = (story) => {
    setPlayStory(story);
  };

  const playSceneR = (e) => {
    setSceneRModalOpen(true);
  };

  const onSelectCharacter = (story) => {
    setPlayStory(story);
    setSceneRModalOpen(false);
  };

  const onClose = (progress) => {
    setPlayStory('');
    if (playStory === 'story#1' && progress >= 100 && !birthday)
      setBirthdayModalOpen(true);
  };

  const storyModalOpenHandler = () => {
    if (!storyDisabledCondition) {
      setStoryModalOpen(true);
    }
  };

  const storyModalCloseHandler = () => {
    setStoryModalOpen(false);
  };

  const birthdayModalCloseHandler = () => {
    setBirthdayModalOpen(false);
  };

  const nameModalCloseHandler = () => {
    setNameModalOpen(false);
  };

  // const checkStory7Exists = useCallback(() => {
  //   const story = storiesProgress?.find(
  //     (item) => item['story#7'] !== undefined
  //   );
  //   return story?.['story#7'][1];
  // }, [storiesProgress]);

  const checkStory7Exists = useCallback(() => {
    const story7Dps = allStories['story#7'].dps;
    const available = new BigNumber(DPS).isGreaterThanOrEqualTo(
      story7Dps
    );
    return available;
  }, [DPS]);

  useEffect(() => {
    const savedProgress = localStorage.getItem('GameStoriesProgress');
    if (savedProgress) {
      const storiesProgressToSet = JSON.parse(savedProgress);
      storiesProgressSetter(storiesProgressToSet);
    }

    const lastCheckedStoryValue = localStorage.getItem(
      'GameLastCheckedStory'
    );
    const lastCheckedStoryToSet = parseFloat(lastCheckedStoryValue);
    lastCheckedStorySetter(lastCheckedStoryToSet || 0);

    const birthdayValue = localStorage.getItem('GameBirthday');
    if (birthdayValue) {
      const birthdayValueToSet = JSON.parse(birthdayValue);
      birthdaySetter(birthdayValueToSet);
    }

    if (!birthdayValue) {
      setBirthdayModalOpen(true);
    }

    const nameValue = localStorage.getItem('Name');
    if (nameValue) {
      dispatch(setName(nameValue));
    }

    const dpsValue = localStorage.getItem('GameDPS');

    if (dpsValue) {
      dispatch(setDPS(dpsValue));
    }
  }, []);

  const autoRunStoryValue = localStorage.getItem('GameAutoRunStory');
  useEffect(() => {
    if (autoRunStoryValue) {
      dispatch(setAutoRunStory(JSON.parse(autoRunStoryValue)));
    }
  }, [autoRunStoryValue]);

  useEffect(() => {
    const story = storiesProgress?.find(
      (item) => item['story#2'] !== undefined
    );

    const story2Progress = story?.['story#2'][0];
    const progressOn6Line = (6 / 13) * 100;

    const isOpen =
      story2Progress == progressOn6Line &&
      !name &&
      playStory === 'story#2';

    setNameModalOpen(isOpen);
  }, [storiesProgress]);

  useEffect(() => {
    const availableStories = Object.keys(allStories).filter((key) => {
      const storyIndex = parseInt(key.replace('story#', ''));
      const storyDPS = allStories[key]?.dps;
      return new BigNumber(numberOfClicks).isGreaterThanOrEqualTo(
        storyDPS
      );
    });

    availableStories.forEach((key) => {
      const storyIndex = parseInt(key.replace('story#', ''));
      if (!playedStories.has(storyIndex)) {
        playSFX('/assets/SFX/P2W_SFX_StoryAvailable.wav');
        setPlayedStories((prevPlayedStories) =>
          new Set(prevPlayedStories).add(storyIndex)
        );
      }
    });
  }, [numberOfClicks, DPS, playedStories, playSFX]);

  return (
    <div className={styles.libraryAreaContainer}>
      <div className={styles.libraryAreaWrapper}>
        <div className={styles.buttonsWrapper}>
          <button>
            <span>{t('Astroclock')}</span>
          </button>
          <button
            className={
              new BigNumber(
                lastCheckedStory > 4 ? DPS : numberOfClicks
              ).isGreaterThanOrEqualTo(
                allStories[`story#${(lastCheckedStory || 0) + 1}`]
                  ?.dps
              )
                ? styles.newAvailable
                : !lastCheckedStory
                ? styles.disable
                : ''
            }
            onClick={storyModalOpenHandler}
            disabled={storyDisabledCondition}
          >
            <span>{t('Story')}</span>
          </button>
        </div>
        <div className={styles.libraryArea}>
          {/* <h1>Library/ Astroclock/etc</h1> */}
          {/* {birthday ? ( */}
          <Astroclock
            disabled={!checkStory7Exists()}
            birthday={
              birthday &&
              new Date(`${birthday.day}, ${birthday.month}, 2050`)
            }
            playSceneR={playSceneR}
          />
          {/* ) : (
            <AstroclockDisabled />
          )} */}
        </div>
      </div>
      <DataLogModal
        isModalOpen={storyModalOpen}
        closeModal={storyModalCloseHandler}
        onDiscClick={onDiscClick}
      />
      <BirthdayModal
        isModalOpen={birthdayModalOpen}
        closeModal={birthdayModalCloseHandler}
      />
      <NameModal
        isModalOpen={nameModalOpen}
        closeModal={nameModalCloseHandler}
      />
      <SceneRModal
        isModalOpen={sceneRModalOpen}
        // closeModal={() => setSceneRModalOpen(false)}
        onSelectCharacter={onSelectCharacter}
      />
      {playStory && (
        <Story storyToPlay={playStory} onClose={onClose} />
      )}
    </div>
  );
}
