import './App.css';
import Left from './containers/Left';
import Right from './containers/Right';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { nodesData, passiveIncomeData } from './site-data/nodes';
import {
  setNodes,
  setPassiveIncome,
  setPerClick,
} from './store/slices/game.slice';
import { Preload } from './components/Preload';
import { motion, AnimatePresence } from 'framer-motion';

export default function Home() {
  const dispatch = useDispatch();
  const { perClick, nodes, passiveIncome } = useSelector(
    (state) => state.game
  );
  const [preload, setPreload] = useState(true);

  useEffect(() => {
    const handleKeyPress = () => {
      setPreload(false);
    };

    window.addEventListener('keydown', handleKeyPress);
    window.addEventListener('click', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      window.removeEventListener('click', handleKeyPress);
    };
  }, []);

  const perClickSetter = (clicks) => {
    dispatch(setPerClick(clicks));
  };

  const nodesSetter = (nodes) => {
    dispatch(setNodes(nodes));
  };

  const passiveIncomeSetter = (passiveIncome) => {
    dispatch(setPassiveIncome(passiveIncome));
  };

  useEffect(() => {
    if (nodes && passiveIncome && perClick) {
      const dataToSet = { perClick, nodes, passiveIncome };
      localStorage.setItem(
        'GameNodesPassiveIncomeAndPerClick',
        JSON.stringify(dataToSet)
      );
    }
  }, [nodes, passiveIncome, perClick]);

  useEffect(() => {
    const nodesPassiveIncomeAndPerClick = localStorage.getItem(
      'GameNodesPassiveIncomeAndPerClick'
    );
    if (nodesPassiveIncomeAndPerClick) {
      const nodesPassiveIncomeAndPerClickValue = JSON.parse(
        nodesPassiveIncomeAndPerClick
      );
      perClickSetter(nodesPassiveIncomeAndPerClickValue.perClick);
      nodesSetter(nodesPassiveIncomeAndPerClickValue.nodes);
      passiveIncomeSetter(
        nodesPassiveIncomeAndPerClickValue.passiveIncome
      );
    } else {
      perClickSetter('1');
      nodesSetter(nodesData);
      passiveIncomeSetter(passiveIncomeData);
    }
  }, []);

  return (
    <>
      <AnimatePresence>
        {preload ? (
          <motion.div
            key="preload"
            initial={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 1 } }}
          >
            <Preload />
          </motion.div>
        ) : (
          <motion.div
            key="mainApp"
            className="App"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 4 } }}
          >
            <div className="main">
              <Left />
              <Right />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
