export const GEARS_ENUM = Object.freeze({
  BOTTING_AND_PERCLICK: Symbol("BOTTING_AND_PERCLICK"),
  BOTTING: Symbol("BOTTING"),
});

export const gearsData = [
  {
    id: "head_glasses",
    type: "Head Glasses",
    effect: {
      increaseValueInMultiple: 1.5,
      applyOn: GEARS_ENUM.BOTTING_AND_PERCLICK,
    },
  },
  {
    id: "chest_suit",
    type: "Chest Suit",
    effect: {
      increaseValueInMultiple: 1.3,
      applyOn: GEARS_ENUM.BOTTING_AND_PERCLICK,
    },
  },
  {
    id: "gloves_wallet",
    type: "Gloves Wallet",
    effect: {
      reducesPeriodeBy: 0.8,
      applyOn: GEARS_ENUM.BOTTING,
    },
  },
  {
    id: "legs_briefcase",
    type: "Legs Briefcase",
    effect: {
      reducesPeriodeBy: 0.9,
      increaseValueInMultiple: 1.2,
      applyOn: GEARS_ENUM.BOTTING_AND_PERCLICK,
    },
  },
  {
    id: "weapon_calculator",
    type: "Weapon Calculator",
    effect: {
      increaseInValueBy: 1,
      applyOn: GEARS_ENUM.BOTTING_AND_PERCLICK,
    },
  },
];
