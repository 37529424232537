import { configureStore } from "@reduxjs/toolkit";
import gameReducer from "./slices/game.slice";
import configReducer from "./slices/config.slice";

const store = configureStore({
  reducer: {
    game: gameReducer,
    config: configReducer,
  },
});

export default store;
