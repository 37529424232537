import React, { useEffect, useState } from 'react';
import Modal from '../common/Modal';
import styles from './styled.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '../common/DatePicker';
import { setBirthday, setName } from '../../store/slices/game.slice';
import { usePlaySFX } from '../../hooks';

export const NameModal = ({ isModalOpen, closeModal }) => {
  const dispatch = useDispatch();
  const { name } = useSelector((state) => state.game);
  const [currentName, setCurrentName] = useState('');
  const playSFX = usePlaySFX();

  const nameSetHandler = () => {
    playSFX('/assets/SFX/P2W_SFX_Click_confirm_01.wav');
    if (currentName.length < 2) {
      return;
    }
    localStorage.setItem('Name', currentName);
    dispatch(setName(currentName));
    setCurrentName('');
    closeModal();
  };

  const initialize = () => {
    if (name) {
      closeModal();
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      initialize();
    }
  }, [isModalOpen]);

  return (
    <Modal isOpen={isModalOpen}>
      <div className={styles.nameContainer}>
        <p>Enter Your Name:</p>
        {/* <DatePicker onConfirm={nameSetHandler} /> */}
        <input
          className={styles.nameInput}
          type="text"
          id="name"
          name="name"
          onChange={(e) => {
            setCurrentName(e.target.value);
          }}
        />
        <div>
          <button
            className={styles.confirmBtn}
            onClick={nameSetHandler}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};
