import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styled.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setClicks,
  setDamageDealt,
  setNumberOfClicks,
} from "../../store/slices/game.slice";
import { click } from "@testing-library/user-event/dist/click";
import { usePlaySFX } from "../../hooks";
import BigNumber from "bignumber.js";

export default function MainGameArea() {
  const dispatch = useDispatch();
  const playSFX = usePlaySFX();
  const { damageDealt, numberOfClicks, perClick } = useSelector(
    (state) => state.game
  );
  const [initialized, setInitialized] = useState(false);
  const [active, setAtive] = useState();
  const [dieing, setDieing] = useState(false);
  const damageDealtRef = useRef(damageDealt);
  const clickRef = useRef(numberOfClicks);
  const characterRef = useRef(null);
  const cursorRef = useRef(null);
  const container = useRef(null);

  const array = ["a1", "b1", "c1", "d1", "e1"];

  const dieTimeInMs = 200;
  const createTimeInMs = 1000;

  const damageDealtSetter = (clicks) => {
    dispatch(setDamageDealt(clicks));
  };

  const numberOfClicksSetter = (clicks) => {
    dispatch(setNumberOfClicks(clicks));
  };

  const clickSetter = (clicks) => {
    dispatch(setClicks(clicks));
  };

  const wait = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const dieingAnimation = async () => {
    setDieing(true);
    await wait(dieTimeInMs);
  };

  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const positionUpdater = (elem) => {
    const containerWidth = elem.parentElement.clientWidth;
    const containerHeight = elem.parentElement.clientHeight;
    const divWidth = elem.clientWidth;
    const divHeight = elem.clientHeight;

    const marginTop = containerHeight * 0.0905;
    const marginLeft = containerWidth * 0.0335;
    const marginRight = marginLeft;
    const marginBottom = marginTop;

    const randomLeft = getRandomInt(
      marginLeft,
      containerWidth - divWidth - marginRight
    );
    const randomTop = getRandomInt(
      marginTop,
      containerHeight - divHeight - marginBottom
    );
    elem.style.left = `${randomLeft}px`;
    elem.style.top = `${randomTop}px`;
  };

  const handleMouseMove = useCallback((e) => {
    if (cursorRef.current && container.current) {
      const cursor = cursorRef.current;
      const containerElem = container.current;
      const containerRect = containerElem.getBoundingClientRect();
      const cursorWidth = cursor.offsetWidth;
      const cursorHeight = cursor.offsetHeight;

      const mouseX = e.clientX - containerRect.left;
      const mouseY = e.clientY - containerRect.top;

      const cursorX = Math.max(
        0,
        Math.min(
          mouseX - cursorWidth / 2,
          containerElem.clientWidth - cursorWidth
        )
      );
      const cursorY = Math.max(
        0,
        Math.min(
          mouseY - cursorHeight / 2,
          containerElem.clientHeight - cursorHeight
        )
      );

      cursor.style.left = `${cursorX}px`;
      cursor.style.top = `${cursorY}px`;
    }
  }, []);

  const handleMouseEnter = () => {
    if (cursorRef.current) {
      cursorRef.current.classList.add(styles.visible);
    }
  };

  const handleMouseLeave = () => {
    if (cursorRef.current) {
      cursorRef.current.classList.remove(styles.visible);
    }
  };

  const handleClick = () => {
    if (cursorRef.current) {
      cursorRef.current.classList.add(styles.shrink);
      setTimeout(() => {
        cursorRef.current.classList.remove(styles.shrink);
      }, 200);
    }
  };

  const clickHandler = async (event) => {
    handleClick();
    if (!dieing) {
      clickSetter(perClick || "1");
      playSFX("/assets/SFX/P2W_SFX_Click_damage_01.wav")

      await dieingAnimation(event.target);

      if (active === 4) {
        setAtive(0);
      } else {
        setAtive(active + 1);
      }
      positionUpdater(event.target);
      setDieing(false);
    }
  };

  useEffect(() => {
    damageDealtRef.current = damageDealt;
    clickRef.current = numberOfClicks;
    if (!initialized && new BigNumber(damageDealt || "0").isGreaterThan(0))
      setInitialized(true);
  }, [damageDealt]);

  useEffect(() => {
    const damageDealtValue = localStorage.getItem("GameDamageDealt");
    damageDealtSetter(damageDealtValue || "0");

    const clicked = localStorage.getItem("GameNumberOfClicks");
    numberOfClicksSetter(clicked || "0");

    if (characterRef.current) {
      positionUpdater(characterRef.current);
      setAtive(0);
    }
  }, []);

  useEffect(() => {
    const transitionTimingChanger = async () => {
      if (!dieing) {
        characterRef.current.style.transitionDuration = `${createTimeInMs / 1000
          }s`;
        await wait(createTimeInMs);
        characterRef.current.style.transitionDuration = `${dieTimeInMs / 1000
          }s`;
      }
    };

    transitionTimingChanger();
  }, [dieing]);

  useEffect(() => {
    if (initialized) {
      const interval = setInterval(() => {
        // save data to db
        const damageDealtToSave = damageDealtRef.current;
        localStorage.setItem("GameDamageDealt", damageDealtToSave);

        const clicksToSave = clickRef.current;
        localStorage.setItem("GameNumberOfClicks", clicksToSave);
      }, 60000);

      return () => clearInterval(interval);
    }
  }, [initialized]);

  return (
    <div className={styles.mainGameArea}>
      {/* <h1>Main Game Area Scenes</h1> */}
      <div className={styles.bgImage}>
        <div
          className={styles.clickAreaScreen}
          onMouseMove={handleMouseMove}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={container}
        >
          <div className={styles.customCursor} ref={cursorRef}></div>

          <button
            ref={characterRef}
            onClick={clickHandler}
            disabled={dieing}
            className={`${styles.clickArea} ${dieing
              ? `${styles[`${array[active]}-dieing`]} ${styles.dieing}`
              : typeof active === "number"
                ? styles[array[active]]
                : ""
              }`}
          ></button>
        </div>
      </div>
    </div>
  );
}
