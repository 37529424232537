import React, { useRef, useState } from 'react';
import Modal from '../common/Modal';
import styles from './styled.module.css';
import { GEARS_ENUM, gearsData } from '../../site-data/gears';
import {
  setDamageDealt,
  setGearsLevels,
  setPerClick,
  setSingleNode,
} from '../../store/slices/game.slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  formatCompactNumber,
  formatCompactNumberNew,
} from '../../functions';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import Tooltip from '../common/Tooltip';
import TooltipContent from './TooltipContent';
import { useTranslation, usePlaySFX } from '../../hooks';
import BigNumber from 'bignumber.js';

export const ShopModal = ({ isModalOpen, closeModal }) => {
  const dispatch = useDispatch();
  const {
    damageDealt,
    numberOfClicks,
    perClick,
    nodes,
    gearsLevels,
  } = useSelector((state) => state.game);
  const [selectedItem, setSelectedItem] = useState(null);
  const [level, setLevel] = useState(null);
  const splideRef = useRef(null);
  const { t } = useTranslation();
  const playSFX = usePlaySFX();

  const numberOfGears = gearsLevels
    ? Object.values(gearsLevels).reduce(
        (total, value) => total + value,
        0
      )
    : 0;

  const limitToCheck = new BigNumber(1000 * 2 ** numberOfGears);

  const bigDamageDealt = new BigNumber(damageDealt || '0');

  const splide = splideRef.current?.splide;

  const gearsLevelsSetter = (levels) => {
    dispatch(setGearsLevels(levels));
  };

  const damageDealtSetter = (clicks) => {
    dispatch(setDamageDealt(clicks));
  };

  const singleNodeSetter = (index, nodeValue) => {
    dispatch(setSingleNode({ index, value: nodeValue }));
  };

  const perClickSetter = (clicks) => {
    dispatch(setPerClick(clicks));
  };

  const perClickAndBottingUpdater = (effect) => {
    let nodeToSet = { ...nodes[0] };
    let perClickToSet = perClick;

    if (effect.increaseValueInMultiple) {
      if (effect.applyOn === GEARS_ENUM.BOTTING_AND_PERCLICK) {
        perClickToSet = new BigNumber(perClickToSet || '1')
          .times(effect.increaseValueInMultiple)
          .toString();
      }

      nodeToSet = {
        ...nodeToSet,
        dps: new BigNumber(nodeToSet.dps)
          .times(effect.increaseValueInMultiple)
          .toString(),
      };
    } else if (effect.increaseInValueBy) {
      if (effect.applyOn === GEARS_ENUM.BOTTING_AND_PERCLICK) {
        perClickToSet = new BigNumber(perClickToSet || '1')
          .plus(effect.increaseInValueBy)
          .toString();
      }

      nodeToSet = {
        ...nodeToSet,
        dps: new BigNumber(nodeToSet.dps)
          .plus(effect.increaseInValueBy)
          .toString(),
      };
    }

    if (effect.reducesPeriodeBy) {
      nodeToSet = {
        ...nodeToSet,
        dps: new BigNumber(nodeToSet.dps)
          .dividedBy(effect.reducesPeriodeBy)
          .toString(),
      };
    }

    if (effect.applyOn === GEARS_ENUM.BOTTING_AND_PERCLICK)
      perClickSetter(perClickToSet);
    singleNodeSetter(0, nodeToSet);
  };

  const buyHandler = () => {
    if (selectedItem) {
      playSFX('/assets/SFX/P2W_SFX_Purchase_01.wav');
      const { id: gearId, effect } = selectedItem;
      if (level > 1) {
        const valToUpdate = bigDamageDealt
          .minus(limitToCheck)
          .toString();
        damageDealtSetter(valToUpdate);
        localStorage.setItem('GameDamageDealt', valToUpdate);
      }
      localStorage.setItem('GameNumberOfClicks', numberOfClicks);
      const dataToSet = {
        ...(gearsLevels ? gearsLevels : {}),
        [gearId]: (gearsLevels?.[gearId] || 0) + 1,
      };
      localStorage.setItem(
        'GameGearsClickedLevels',
        JSON.stringify(dataToSet)
      );
      gearsLevelsSetter(dataToSet);
      perClickAndBottingUpdater(effect);
      setSelectedItem(null);
    }
  };

  const handleItemClick = (item) => {
    const { id, level } = item;
    playSFX('/assets/SFX/P2W_SFX_MenuSelection_01.wav');
    if (
      (gearsLevels?.[id] || 0) === level - 1 &&
      level > 1 &&
      bigDamageDealt.isGreaterThanOrEqualTo(limitToCheck)
    ) {
      setSelectedItem(item);
      setLevel(level);
    } else if (
      (gearsLevels?.[id] || 0) === level - 1 &&
      level === 1
    ) {
      setSelectedItem(item);
      setLevel(level);
    }
  };

  const clickHandler = () => {
    // setSelectedItem(null);
  };

  const showPrev = () => {
    splide.go('<');
  };

  const showNext = () => {
    splide.go('>');
  };

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      <div className={styles.shopContainer} onClick={clickHandler}>
        <div className={styles.itemSlider}>
          <div className={styles.shopText}>{t('Shop')}</div>
          <Splide
            ref={splideRef}
            options={{
              perPage: 5,
              focus: 0,
              omitEnd: true,
              pagination: false,
              arrows: false,
            }}
            style={{
              width: '80%',
            }}
            className={styles.deco}
          >
            {[...Array(4)].map((_, index) =>
              gearsData.map(({ id, type, effect }, i) => {
                const level = index + 1;
                const disabled =
                  numberOfClicks > 30 && level > 1
                    ? (gearsLevels?.[id] || 0) === level - 1
                      ? bigDamageDealt.isLessThan(limitToCheck)
                      : (gearsLevels?.[id] || 0) < level - 1
                    : numberOfClicks > 30 && level == 1
                    ? false
                    : true;

                return (
                  <SplideSlide
                    key={`${id}-${index}`}
                    className={styles.splide_track}
                  >
                    <Tooltip
                      content={
                        !disabled && (
                          <TooltipContent
                            data={{ type, level, effect }}
                          />
                        )
                      }
                    >
                      <button
                        className={`${styles.item} ${
                          !disabled &&
                          (gearsLevels?.[id] || 0) === level - 1
                            ? styles.readyToBuy
                            : ''
                        } ${
                          selectedItem?.id === id &&
                          selectedItem?.level === level
                            ? styles.selected
                            : ''
                        } ${disabled ? styles.disabled : ''}`}
                        onClick={() =>
                          handleItemClick({ id, level, effect })
                        }
                        // disabled={disabled}
                      >
                        {(gearsLevels?.[id] || 0) === level && (
                          <p className={styles.boughtTag}>OWNED</p>
                        )}
                        <img
                          alt={`${id}-${index}`}
                          src={`assets/Gear/${level} ${i + 1}.png`}
                        />
                      </button>
                    </Tooltip>
                  </SplideSlide>
                );
              })
            )}
          </Splide>
          <div className={`${styles.splideArrows}`}>
            <button onClick={showPrev} className={styles.arrowImg}>
              <img
                alt="left-arrow"
                src={'assets/Data_log/story_left_arrow.png'}
              />
            </button>
            <button onClick={showNext} className={styles.arrowImg}>
              <img
                alt="right-arrow"
                src={'assets/Data_log/story_right_arrow.png'}
              />
            </button>
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.cost}>
            {selectedItem &&
              `${t('Cost')}: ${
                numberOfClicks >= 30 && level == 1
                  ? t('Free')
                  : numberOfClicks > 30 &&
                    level > 1 &&
                    formatCompactNumberNew(limitToCheck)
              }
               ${numberOfClicks > 30 && level > 1 ? t('Damage') : ''}
               `}
          </div>
          <button
            className={styles.buyButton}
            onClick={buyHandler}
            disabled={!selectedItem}
          >
            {t('Buy')}
          </button>
        </div>
      </div>
    </Modal>
  );
};
