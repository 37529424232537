// src/Tooltip.js
import React, { useState, useRef } from "react";
import styles from "./styled.module.css";

const Tooltip = ({ children, content }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    const containerRect = e.currentTarget.getBoundingClientRect();
    const tooltipX = clientX - containerRect.left;
    const tooltipY = clientY - containerRect.top;
    setMousePosition({ x: tooltipX, y: tooltipY });
  };

  return (
    <div className={styles.tooltipContainer} onMouseMove={handleMouseMove}>
      {children}
      {content && (
        <div
          className={styles.tooltipBox}
          style={{
            position: "absolute",
            top: mousePosition.y + 10,
            left: mousePosition.x + 10,
            transform: "translate(5%, 10%)",
            pointerEvents: "none",
          }}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
