import { useEffect, useState, useRef } from 'react';
import styles from './styled.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAutoClicker,
  setDamageDealt,
  setBonusLevels,
  setSingleNode,
  setSinglePassiveIncome,
} from '../../store/slices/game.slice';
import { nodesData, passiveIncomeData } from '../../site-data/nodes';
import { formatCompactNumber } from '../../functions';
import Tooltip from '../common/Tooltip';
import TooltipContent from './TooltipContent';
import { useTranslation } from '../../hooks';
import BigNumber from 'bignumber.js';

export default function Nodes() {
  const dispatch = useDispatch();
  const {
    damageDealt,
    numberOfClicks,
    nodes,
    passiveIncome,
    bonusLevels,
  } = useSelector((state) => state.game);
  const [activatedNodes, setActivatedNodes] = useState(new Set());
  const intervalRef = useRef(null);

  const textContainer = useRef(null);
  const text = useRef(null);

  const { t } = useTranslation();

  const bigDamageDealt = new BigNumber(damageDealt || '0');

  const autoClickSetter = (clicks) => {
    dispatch(setAutoClicker(clicks));
  };

  const damageDealtSetter = (clicks) => {
    dispatch(setDamageDealt(clicks));
  };

  const bonusLevelsSetter = (levels) => {
    dispatch(setBonusLevels(levels));
  };

  const singleNodeSetter = (index, nodeValue) => {
    dispatch(setSingleNode({ index, value: nodeValue }));
  };

  const singlePassiveIncomeSetter = (index, passiveIncomeValue) => {
    dispatch(
      setSinglePassiveIncome({ index, value: passiveIncomeValue })
    );
  };

  const nodeAndPassiveIncomeUpdater = (id) => {
    let index = nodes.findIndex((item) => item.id === id);

    if (index >= 0) {
      const nodeValue = {
        ...nodes[index],
        basePrice: new BigNumber(nodes[index].basePrice).times(1.15),
        dps: new BigNumber(nodes[index].dps).plus(
          nodes[index].baseDps
        ),
      };
      singleNodeSetter(index, nodeValue);
    } else {
      index = passiveIncome.findIndex((item) => item.id === id);
      if (index >= 0) {
        const passiveIncomeValue = {
          ...passiveIncome[index],
          basePrice: new BigNumber(
            passiveIncome[index].basePrice
          ).times(1.15),
          dps: new BigNumber(passiveIncome[index].dps).plus(
            passiveIncome[index].baseDps
          ),
        };
        singlePassiveIncomeSetter(index, passiveIncomeValue);
      }
    }
  };

  const runBonus = (levels) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    let bonusIncrementalValue = '0';

    for (let id of Object.keys(levels)) {
      let data = nodesData.find((item) => item.id === id);

      if (!data) {
        data = passiveIncomeData.find((item) => item.id === id);
      }

      const dps = new BigNumber(data.dps);
      bonusIncrementalValue = new BigNumber(bonusIncrementalValue)
        .plus(dps)
        .toString();
    }

    intervalRef.current = setInterval(() => {
      autoClickSetter(bonusIncrementalValue);
    }, 1000);
  };

  const autoBonusClickHandler = (price, nodeId) => {
    const bigPrice = new BigNumber(price);
    if (bigDamageDealt.isGreaterThanOrEqualTo(bigPrice)) {
      const valToUpdate = bigDamageDealt.minus(bigPrice).toString();
      damageDealtSetter(valToUpdate);
      localStorage.setItem('GameDamageDealt', valToUpdate);
      localStorage.setItem('GameNumberOfClicks', numberOfClicks);
      const levelToUpdate = (bonusLevels?.[nodeId] || 0) + 1;
      const dataToSet = {
        ...(bonusLevels ? bonusLevels : {}),
        [nodeId]: levelToUpdate,
      };
      localStorage.setItem(
        'GameBonusClickedLevels',
        JSON.stringify(dataToSet)
      );
      bonusLevelsSetter(dataToSet);
      nodeAndPassiveIncomeUpdater(nodeId);
      runBonus(dataToSet);
    }
  };

  const handleClick = (basePrice, id) => {
    autoBonusClickHandler(basePrice, id);
  };

  useEffect(() => {
    const bonusClickedLevels = localStorage.getItem(
      'GameBonusClickedLevels'
    );
    if (bonusClickedLevels && numberOfClicks >= 50) {
      const levelsValue = JSON.parse(bonusClickedLevels);

      bonusLevelsSetter(levelsValue);
      runBonus(levelsValue);
      setActivatedNodes(new Set(Object.keys(levelsValue)));
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [numberOfClicks]);

  useEffect(() => {
    if (!nodes || !passiveIncome || numberOfClicks < 50) return;

    const updatedActivatedNodes = new Set(activatedNodes);

    if (bonusLevels) {
      Object.keys(bonusLevels).forEach((id) => {
        updatedActivatedNodes.add(id);
      });
    }

    nodes.forEach(({ id, basePrice }) => {
      if (
        bigDamageDealt.isGreaterThanOrEqualTo(
          new BigNumber(basePrice)
        )
      ) {
        updatedActivatedNodes.add(id);
      }
    });

    passiveIncome.forEach(({ id, basePrice }) => {
      if (
        bigDamageDealt.isGreaterThanOrEqualTo(
          new BigNumber(basePrice)
        )
      ) {
        updatedActivatedNodes.add(id);
      }
    });

    setActivatedNodes(updatedActivatedNodes);
  }, [
    damageDealt,
    nodes,
    passiveIncome,
    bonusLevels,
    numberOfClicks,
  ]);

  console.log('nodes new', nodes, activatedNodes);

  return (
    <div className={styles.nodes_container}>
      {nodes &&
        nodes.map(({ id, type, basePrice, dps, baseDps }, i) => {
          const disabled = bigDamageDealt.isLessThan(
            new BigNumber(basePrice)
          );
          const locked =
            numberOfClicks < 50
              ? true
              : disabled && !activatedNodes.has(id);

          if (id == 'referral_scam')
            console.log(
              'numberOfClicks',
              disabled && !activatedNodes.has(id)
            );

          return (
            <Tooltip
              key={id}
              content={
                !locked && (
                  <TooltipContent
                    data={{ basePrice, dps, baseDps }}
                  />
                )
              }
            >
              <button
                className={`${styles.node} ${
                  locked ? styles.locked : ''
                }`}
                onClick={() => handleClick(basePrice, id)}
                disabled={disabled}
              >
                <div className={styles.imageContainer}>
                  <img alt={id} src={`assets/Node/${i + 1}.png`} />
                </div>
                <div className={styles.idContainer}>
                  <h1>{t(type)}</h1>
                </div>
              </button>
            </Tooltip>
          );
        })}
      {passiveIncome &&
        passiveIncome.map(
          ({ id, type, basePrice, dps, baseDps }, i) => {
            const disabled = bigDamageDealt.isLessThan(
              new BigNumber(basePrice)
            );
            const locked =
              numberOfClicks < 50
                ? true
                : disabled && !activatedNodes.has(id);

            return (
              <Tooltip
                key={id}
                content={
                  !locked && (
                    <TooltipContent
                      data={{ basePrice, dps, baseDps }}
                    />
                  )
                }
              >
                <button
                  className={`${styles.node} ${
                    locked ? styles.locked : ''
                  }`}
                  onClick={() => handleClick(basePrice, id)}
                  disabled={disabled}
                  ref={textContainer}
                >
                  <div className={styles.imageContainer}>
                    <img alt={id} src={`assets/Node/${i + 5}.png`} />
                  </div>
                  <div className={styles.idContainer}>
                    <h1 ref={text}>{t(type)}</h1>
                  </div>
                </button>
              </Tooltip>
            );
          }
        )}
    </div>
  );
}
